import apiClient from "../libs/http-common";

export const fetchCourses = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post(
    "/Courses/get",
    { id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addCourse = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Courses/add", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteCourse = async (id) => {
  const token = window.localStorage.getItem("token");
  return apiClient({
    url: "/Courses/delete",
    method: "DELETE",
    data: { id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCourse = async (data) => {
  const token = window.localStorage.getItem("token");
  apiClient.put("/Courses/update", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const fetchSingleCourse = async (id) => {
  const token = window.localStorage.getItem("token");
  return apiClient.post("/Courses/getSingle", id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const addUserToACourse = async (data) => {
  const token = window.localStorage.getItem("token");
  return apiClient.post("/Courses/addUserToCourse", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
