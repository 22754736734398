import moment from "moment";
import React from "react";
import { FaNotesMedical } from "react-icons/fa";

function NoteCard({ note }) {
  return (
    <div className="my-[1.4rem] min-w-[18.1rem] h-[27.6rem] bg-[#FFFFFF] rounded-[1.2rem] border border-[#E4E4E4] p-[1.6rem]">
      <h6 className="text-[#103240] text-[1.6rem] mb-[.8rem] font-semibold">{note?.title}</h6>
      <p className="text-[#103240B2] text-[1.4rem] font-medium h-[80%]">{note?.text}</p>
      <p className="text-[#10324080] text-[1.2rem] font-medium mt-2">{moment(note?.dateAdded).format("D MMM [at] h:mma")}</p>
    </div>
  );
}

export default NoteCard;
