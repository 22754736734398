import React, { useEffect } from "react";
import SingleIdea from "../../Components/Ideas/SingleIdea/SingleIdea";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";

function SingleIdeaPage({src}) {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!userProfile?.token) return navigate("/login");
  // }, [userProfile]);
  return (
    <>
      <SingleIdea src={src} />
    </>
  );
}

export default SingleIdeaPage;
