import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const useCategoriesStore = create(
  persist(
  devtools((set) => ({
    Categories: [],
    saveCategories: (Categories) => set({ Categories }),
  })),
  {
    name: 'CategoriesStore',
  }
)
);

export default useCategoriesStore;