import apiClient from "../libs/http-common";
export const inviteUser = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Invites/add", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchInvites = async () => {
  const token = window.localStorage.getItem("token");
  return await apiClient.get("/Invites/get", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const pullInvites = async (inviteId, expiry, email) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post(
    `/Invites/pullInvite`,
    {
      inviteId,
      email,
      expiry,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
