import React from "react";
import Table from "../../../CustomUIElements/Table/Table";

function IdeasTable({data, loading}) {
  return (
    <>
      <Table
        header={[
          { name: "Name", identifier: "firstName" },
          { name: "Description", identifier: "lastName" },
        ]}
        data={data}
        options={{ variant: "primary", tableStyle: "", allowSorting: false }}
        loading={loading}
      />
    </>
  );
}

export default IdeasTable;
