function Mission() {
  return (
    <section className="relative top-[5.6rem]" id="mission">
      <div className="app-container">
        <div className="flex flex-col items-center justify-center text-center mb-[4.8rem]">
          <div>
            <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[3.6rem]">
              Our Mission is INNOVATION and CREATIVITY
            </p>
            <h3 className="text-[#103240] text-[4.7rem] md:text-[9.6rem] max-w-[102rem] font-bold leading-[7rem] md:leading-[10.6rem] mb-[2.4rem]">
              Fostering Creative Innovations
            </h3>
            <p className="text-[#103240] font-medium text-[2rem] max-w-[76.8rem] mx-auto mb-[6.4rem] leading-[3rem]">
            Hydreate's mission is to democratize innovation by providing a platform that nurtures and empowers ideas at every stage of development. We believe that everyone, regardless of background or resources, deserves the opportunity to turn their dreams into reality. By leveraging the latest technologies and fostering a culture of creativity, we aim to break down barriers and unlock the full potential of human ingenuity.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Mission;
