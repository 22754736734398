import React, { useState } from 'react'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons'
import MyIdeasTable from '../../Components/AdminDashboard/ideas/MyIdeasTable'
import { HiOutlineSearch } from 'react-icons/hi';

function ManageMyIdea() {
    const [openModal, setOpenModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
            <div className='min-h-[85vh]'>
                <div className="flex flex-col lg:flex-row justify-between items-center  mb-[3rem] gap-y-[20px] lg:gap-y-0">
                    <div className="flex flex-col lg:flex-row items-center gap-4">
                        <h1 className="text-[20px] font-semibold">Manage My Ideas</h1>
                        <div className="relative">
                            <input
                                className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <span className="absolute top-[14px] left-[15px] text-[20px]">
                                <HiOutlineSearch />
                            </span>
                        </div>
                    </div>
                    <div className="w-fit">
                        <Buttons
                            type="button"
                            text="Add New Idea"
                            variant="primary"
                            handleClick={() => setOpenModal({ modalName: "add", showModal: true })}
                        />
                    </div>
                </div>
                <div className='mb-8'>
                    <MyIdeasTable openModal={openModal} setOpenModal={setOpenModal} searchQuery={searchQuery} />
                </div>
            </div>
        </div>
    )
}

export default ManageMyIdea