import React from "react";
import Summary from "../../Components/AdminDashboard/DashboardSumarry/Summary";

function AdminDashboardHome() {
  return (
    <div className="relative lg:top-[8%] top-[60px] md:top-[70px] lg:px-16 px-8 py-8 bg-[#f1f1f1]">
      <Summary />
    </div>
  );
}

export default AdminDashboardHome;
