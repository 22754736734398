import React from 'react'
import DashboardSummary from './DashbaordSummary'

function LecturerHome() {
    return (
        <div className="relative top-[8%] px-8 md:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            < DashboardSummary />
        </div>
    )
}

export default LecturerHome