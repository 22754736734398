import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const ideaStore = (set) => ({
  categories: [],
  saveCategories: (categories) => set({ categories }),
  ideas: [],
  saveIdeas: (ideas) => set({ ideas }),
  removeIdeas: () => set({ ideas: [] }),
  singleIdea: null,
  saveSingleIdea: (idea) => set({ singleIdea: idea }),
  notes: null,
  saveNotes: (note) => set({ notes: note }),
});

const useIdeaStore = create(
  persist(
    devtools((set) => ({
      ...ideaStore(set),
      saveSingleIdea: (idea) => set({ singleIdea: idea }), // Exclude singleIdea from persistence
      saveNotes: (note) => set({ notes: note }), // Exclude notes from persistence
    })),
    {
      name: "ideaStore",
    }
  )
);

export default useIdeaStore;
