import React, { useEffect, useState } from 'react'
import Inputs from '../CustomUIElements/Inputs/Inputs'
import { Buttons } from '../CustomUIElements/Buttons/Buttons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { createUserAccount } from '../utils/userAccountFn'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAuthStore from '../store/authStore'
import { pullInvites } from '../utils/inviteUser'
import Scale from '../CustomUIElements/Loaders/ScaleLoader'

function Invitation() {
    const navigate = useNavigate();
    const { saveUserProfile } = useAuthStore();
    const [formValues, setFormValues] = useState({})
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const inviteId = searchParams.get("inviteId");
    const expiry = searchParams.get("expiry");
    const [userInfo, setUserInfo] = useState({})
    const [hasExpired, setHasExpired] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    // const [loading, setLoading] = useState(true)
    const { mutate, isLoading } = useMutation(createUserAccount, {
        onSuccess: ({ data }) => {
            saveUserProfile(data.data);
            navigate("/login");
            toast.success(`${data.status}`);
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const { isLoading: loading } = useQuery(
        "fetchSingleIdea",
        () => pullInvites(inviteId, expiry, email?.replaceAll(" ", "+")),
        {
            onSuccess: ({ data }) => {
                setUserInfo(data.data);
            },
            onError: ({ response }) => {
                setHasExpired(true)
                toast.error(`${response?.data?.status}`)
                setErrMsg(`${response?.data?.status}`)
            },
            enabled: true,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            retryDelay: false,
            retryOnMount: false
        }
    );

    // const fetchInviteDetails = async () => {
    //     setLoading(true)
    //     await pullInvites(inviteId, expiry, email?.replaceAll(" ", "+"))
    //         .then((res) => {
    //             setUserInfo(res?.data?.data)
    //         })
    //         .catch((err) => toast.error(`${err?.response?.data?.status}`))
    //         .finally(() => setLoading(false))
    // }
    // useEffect(() => {
    //     if (expiry && inviteId && email) {
    //         fetchInviteDetails()
    //     }
    // }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const { firstName, lastName, emailAddress, courseId, learningInstitutionId, userRole, learningInstitutionViewModel } = userInfo;
        const { name } = learningInstitutionViewModel
        mutate({
            firstName,
            lastName,
            emailAddress,
            learningInstitutionId,
            userRoleEnum: userRole,
            subscriptionId: 0,
            mobileNumber: formValues?.mobileNumber,
            password: formValues?.password,
            confirmPassword: formValues?.confirmPassword,
            organisationName: name,
            courseId: courseId
        });
    };

    return (
        <>
            <div className="app-container  md:w-[46rem] mx-auto flex flex-col mb-[5rem] ">
                <p className="mb-[3.2rem] mt-[0rem] text-center text-[2.4rem] text-[#103240]">
                    Please Provide your details below
                </p>
                <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] max-w-[36.2rem] mx-auto">
                    Complete your registration
                </h3>
                {errMsg?.length > 0 && <p className='text-center mb-4 text-[14px] text-red-600
                '>{errMsg}</p>}
                {loading ? <center><Scale /></center> :
                    <form action="" className='space-y-4' onSubmit={handleSubmit}>
                        <Inputs
                            type={"text"}
                            label={"Phone Number"}
                            name={"mobileNumber"}
                            isRequired={true}
                            value={formValues["Phone Number"]}
                            handleChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    [e.target.name]: e.target.value,
                                })
                            }
                        />
                        <Inputs
                            type={"password"}
                            label={"Password"}
                            name={"password"}
                            isRequired={true}
                            value={formValues["password"]}
                            handleChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    [e.target.name]: e.target.value,
                                })
                            }
                        />
                        <Inputs
                            type={"password"}
                            label={"Confirm Password"}
                            name={"confirmPassword"}
                            isRequired={true}
                            value={formValues["confirmPassword"]}
                            handleChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    [e.target.name]: e.target.value,
                                })
                            }
                        />
                        <div className="flex gap-[1.2rem] text-[1.3rem] text-[#455065]">
                            <input
                                type="checkbox"
                                className="w-[1.8rem] h-[1.8rem]"
                                checked={formValues?.acceptTnC}
                                onChange={() =>
                                    setFormValues({
                                        ...formValues,
                                        acceptTnC: formValues?.acceptTnC ? !formValues.acceptTnC : true,
                                    })
                                }
                            />
                            <p className=" font-medium">
                                By creating an account, I agree to Hydreate{" "}
                                <Link to="/privacy-policy" className="text-[#008FD2]">
                                    Privacy Policy
                                </Link>{" "}
                                and{" "}
                                <Link to="/terms-and-conditions" className="text-[#008FD2]">
                                    Terms
                                </Link>
                            </p>
                        </div>
                        <div className="!mt-[5rem]">
                            <Buttons
                                variant={
                                    formValues?.mobileNumber &&
                                        formValues?.password &&
                                        formValues?.confirmPassword &&
                                        formValues?.password === formValues?.confirmPassword &&
                                        formValues?.acceptTnC &&
                                        !isLoading && !hasExpired
                                        ? "primary"
                                        : "disabled"
                                }
                                type="submit"
                                text={isLoading ? "Submitting..." : "Get Started"}
                            />
                        </div>
                    </form>
                }
            </div>
        </>
    )
}

export default Invitation
