import React, { useState } from 'react'
import SummaryCards from '../../CustomUIElements/Cards/SummaryCards'
import { GiTeacher } from 'react-icons/gi'
import { PiStudentBold } from 'react-icons/pi'
import { FcInvite } from "react-icons/fc";
import { TbMailCancel } from "react-icons/tb";
import UsersTable from '../AdminDashboard/DashboardSumarry/UsersTable';
import useAuthStore from '../../store/authStore';
import { useQuery } from 'react-query';
import { fetchStudents } from '../../utils/students';

function DashboardSummary() {
    const { userProfile , removeUser} = useAuthStore()
    const [students, setStudents] = useState([])
    const { isLoading, isRefetching, refetch } = useQuery("fetchStudents", fetchStudents, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            console.log(data.data)
            setStudents(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })
    return (
        <div>
            <h1 className="text-[20px] font-semibold my-[3rem]">My Summary</h1>
            <div className="col-span-5 grid md:grid-cols-2 gap-8">
                <SummaryCards
                    icon={<GiTeacher size={20} color="white" />}
                    iconBg="navy"
                    title="My Course"
                    count={userProfile?.user?.lecturerView?.courseName || 0}
                    iconBoxBg="#d4d4ff"
                    link=""
                />
                <SummaryCards
                    icon={<PiStudentBold size={20} color="white" />}
                    iconBg="purple"
                    title="My Students"
                    count={userProfile?.user?.lecturerView?.totalStudents || 0}
                    iconBoxBg="#ede6fc"
                    link="dashboard/my-students"
                />
                <SummaryCards
                    icon={<FcInvite size={20} color="white" />}
                    iconBg="green"
                    title="Invites Sent"
                    count={userProfile?.user?.lecturerView?.invitesSent || 0}
                    iconBoxBg="#9aff9a"
                    link="dashboard/my-invites"
                />
                <SummaryCards
                    icon={<TbMailCancel size={20} color="white" />}
                    iconBg="orange"
                    title="Invites Rejected"
                    count={userProfile?.user?.lecturerView?.inviteRejected || 0}
                    iconBoxBg="#ffd88f"
                    link="dashboard/my-invites"
                />
            </div>
            <div className="mt-8 grid grid-cols-8">
                <div className="col-span-8 bg-white rounded-xl">
                    <div className="flex justify-between items-center py-[2rem] border-b-2 px-8">
                        <h3 className="text-[1.8rem] ">Newest Students</h3>
                        <a href="dashboard/my-students" className="text-[1.2rem] bg-slate-300 rounded-md p-2 text-slate-[#eee]">
                            View All
                        </a>
                    </div>
                    <UsersTable data={[...students]} src="lect" loading={isLoading} />
                </div>
            </div>
        </div>
    )
}

export default DashboardSummary