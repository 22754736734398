
import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { useTheme } from "../../context/ThemeContext";
import useAuthStore from "../../store/authStore";
import { AdminLinks, InvestorLink, LectLink, LiLink, StuLink } from "./SidebarList";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMenuOutline } from "react-icons/io5";

function Sidebar({ openMenu, setOpenMenu }) {
  const { theme } = useTheme();
  const location = useLocation();
  const { userProfile, removeUser } = useAuthStore()
  const navigate = useNavigate();

  // console.log(location.pathname)



  const activeStyle = {
    backgroundColor: "#fff",
    color: "#008FD2",
  };

  return (
    <div
      className={`${openMenu ? "fixed top-0 bottom-0 overflow-y-scroll scrollbar-hide" : "fixed hidden"}  md:block w-8/12 lg:w-2/12 md:w-3/12`}
      style={{ ...theme, minHeight: "100vh" }}
    >
      <div className=" flex items-center justify-between w-full p-[20px] px-[15px] mb-3">
        {openMenu ? <LiaTimesSolid className={`block md:hidden`} size={24} onClick={() => setOpenMenu(false)} /> :
          <IoMenuOutline className={`block md:hidden`} size={24} onClick={() => setOpenMenu(true)} />
        }
        <a href="/" className="hidden md:flex justify-center py-1 md:border-b md:pb-3 md:mb-4 w-[200px] md:w-full">
          <img
            src="/assets/logo-white.png"
            alt=""
            className="w-full h-full scale-50 mt-1"
          />
        </a>
      </div>
      <ul className="">
        {(userProfile?.user?.userRole === "Administrator" ? AdminLinks : userProfile?.user?.userRole === "Investor" ? InvestorLink : userProfile?.user?.userRole === "LearningInstitution" ? LiLink : userProfile?.user?.userRole === "Lecturer" ? LectLink : StuLink)?.map((link) => (
          <NavLink to={link?.url} activeStyle={activeStyle} key={link?.name}>
            <li onClick={() => setOpenMenu(false)} className={`lg:mx-4 mx-2 p-4 lg:text-[1.4rem] text-[1.4rem] hover:bg-white hover:bg-opacity-40 rounded-lg cursor-pointer flex gap-x-2 items-center mb-3 ${location.pathname === link?.url && 'bg-white bg-opacity-40'} '}`} >
              {link?.icon}
              <p>
                {link?.name}
              </p>
            </li>
          </NavLink>
        ))}
      </ul>
      <div className="relative lg:absolute lg:bottom-[20px] w-full mt-4">
        <span className={`lg:mx-8 p-4 text-[1.4rem] hover:bg-white hover:bg-opacity-40 rounded-lg cursor-pointer flex gap-2 items-center`} onClick={() => {
          // window.localStorage.clear();
          removeUser();
          navigate("/login")
        }}>
          <CiLogout size={20} />
          Logout
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
