import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BsCheckCircle } from "react-icons/bs"
import apiClient from "../libs/http-common";

function SuccessPage() {
    const [searchParams] = useSearchParams()
    const transactionReference = searchParams.get('transactionReference')
    const [msg, setMsg] = useState("")
    useEffect(() => {
        const token = window.localStorage.getItem("token");
        if (transactionReference) {
            apiClient.post("Payments/success", { reference: transactionReference }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                console.log(res)
                setMsg(res?.data?.status)
            }).catch((err) => {
                console.log(err)
                if (err?.response?.status == 400) {
                    setMsg(err.response?.data?.status)
                }
            })
        }
    }, [transactionReference])
    return (
        <div className="h-[80vh] flex justify-center items-center">
            <div className="bg-slate-300 w-[60%] mx-auto p-8 flex flex-col items-center min-h-[30rem]">
                <div className="mb-8 w-[20rem] mx-auto flex justify-center">
                    <BsCheckCircle className="" size={100} color="green" />
                </div>
                <h3 className="text-[1.7rem] font-medium">
                    {msg || "Transaction Successful"}
                </h3>
                <p className="text-[1.4rem] mt-2">
                    Here is your transaction reference number
                </p>
                <p className="text-[1.3rem]">{transactionReference}</p>
                <p className="text-[1.2rem] mt-[.5rem]">
                    Please keep a copy of this transaction reference number
                </p>
                <a
                    href="/dashboard"
                    className="mt-8"
                >
                    <button
                        className="bg-[#008FD2] text-white p-2 px-4 rounded-md text-[1.4rem] mt-4"
                        type="button"
                    // onClick={() => router.back()}
                    >
                        Go back to dashboard
                    </button>
                </a>
            </div>
        </div>
    )
}

export default SuccessPage