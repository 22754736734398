import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const useDashboardStore = create(
  persist(
  devtools((set) => ({
    dashboard: [],
    saveDashboard: (dashboard) => set({ dashboard }),
  })),
  {
    name: 'DashboardStore',
  }
)
);

export default useDashboardStore;