import React, { useState } from "react";
import { HiOutlineUsers } from "react-icons/hi2";
import { TbBulb, TbCategory } from "react-icons/tb";
import { GoPerson } from "react-icons/go";
import { MdBusinessCenter } from "react-icons/md";
import { RiAdvertisementFill } from "react-icons/ri";
import { SiAffinitydesigner } from "react-icons/si";
import { TbBuildingCarousel } from "react-icons/tb";
import UsersTable from "./UsersTable";
import InvestorsTable from "./InvestorsTable";
import { useQuery } from "react-query";
import { fetchDashboard } from "../../../utils/fetchdashboard";
import useDashboardStore from "../../../store/dashboardStore";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import { fetchUsers } from "../../../utils/fetchUsers";
import { fetchInvestors } from "../../../utils/fetchInvestors";
import { fetchMyIdeas } from "../../../utils/fetchMyIdeas";
import IdeasTable from "./IdeasTable";
import { BiCategory } from "react-icons/bi";
import SummaryCards from "../../../CustomUIElements/Cards/SummaryCards";

function Summary() {
  const { dashboard, saveDashboard } = useDashboardStore();
  const navigate = useNavigate()
  const { removeUser, userProfile } = useAuthStore()
  const [users, setUsers] = useState([])
  const [investors, setInvestors] = useState([])
  const [ideas, setIdeas] = useState([])

  const { isLoading } = useQuery(
    "fetchDashboard",
    fetchDashboard,
    {
      enabled: true,
      onSuccess: ({ data }) => {
        saveDashboard(data.data);
      },
      onError: ({ response }) => {
        console.log('error', response);
        if (response?.status === 401) {
          removeUser()
          navigate("/login")
        }
      }
    }
  );

  const { isLoading: loadingUsers } = useQuery(
    "fetchUsers",
    userProfile?.user?.userRole === "Administrator" && fetchUsers,
    {
      enabled: true,
      onSuccess: ({ data }) => {
        setUsers(data?.data?.splice(0, 5));
      },
      onError: ({ response }) => {
        console.log('response', response);
        if (response?.status === 401) {
          removeUser()
          navigate("/login")
        }
      }
    }
  );

  const { isLoading: loadingInvestors } = useQuery("fetchInvestors",
    userProfile?.user?.userRole === "Administrator" && fetchInvestors, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      const newData = []
      data?.data?.forEach((elm, ind) => {
        let newObj = { ...elm, name: elm?.firstName + " " + elm?.lastName }
        newData.push(newObj)
      })
      console.log('new', newData);
      setInvestors(newData?.splice(0, 5))
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })

  const { isLoading: loadingIdeas, mutate: fetchIdeas, refetch } = useQuery("fetchMyIdeas", fetchMyIdeas, {
    enabled: true,
    onSuccess: ({ data }) => {
      setIdeas(data?.data || []);
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser();
        navigate("/login")
      }
    },
  });

  // console.log(dashboard);

  return (
    <div className="h-fit">
      <h1 className="text-[20px] font-semibold mb-[3rem] ">Dashboard Home</h1>
      <div className="grid grid-cols-9 gap-8">
        {userProfile?.user?.userRole !== "Administrator" ?
          <div className="col-span-9 lg:col-span-5 grid md:grid-cols-2 gap-8">
            <SummaryCards
              icon={<TbBulb size={20} color="white" />}
              iconBg="navy"
              title="My Ideas"
              count={dashboard?.investorDashboardView?.totalNoOfIdeas || 0}
              iconBoxBg="#d4d4ff"
              link="/manage-my-ideas"
            />
            <SummaryCards
              icon={<HiOutlineUsers size={20} color="white" />}
              iconBg="purple"
              title="My Interests"
              count={dashboard?.investorDashboardView?.noOfIdeasInterestesIn || 0}
              iconBoxBg="#ede6fc"
            />
            <SummaryCards
              icon={<TbCategory size={20} color="white" />}
              iconBg="green"
              title="My Categories"
              count={dashboard?.investorDashboardView?.investorCategories || 0}
              iconBoxBg="#9aff9a"
            />
            <SummaryCards
              icon={<GoPerson size={20} color="white" />}
              iconBg="orange"
              title="Idea Categories"
              count={dashboard?.investorDashboardView?.ideaCategories || 0}
              iconBoxBg="#ffd88f"
            />
          </div>
          :
          <div className="lg:col-span-5 col-span-9 grid  md:grid-cols-2 gap-8">
            <SummaryCards
              icon={<HiOutlineUsers size={20} color="white" />}
              iconBg="purple"
              title="Total Users"
              count={dashboard?.totalUsers}
              iconBoxBg="#ede6fc"
              link="/manage-users"
            />
            <SummaryCards
              icon={<TbBulb size={20} color="white" />}
              iconBg="navy"
              title="Total Ideas"
              count={dashboard?.totalIdeas}
              iconBoxBg="#d4d4ff"
              link="/manage-ideas"
            />
            <SummaryCards
              icon={<TbCategory size={20} color="white" />}
              iconBg="green"
              title="Total Categories"
              count={dashboard?.totalCategories}
              iconBoxBg="#9aff9a"
              link="/manage-categories"
            />
            <SummaryCards
              icon={<GoPerson size={20} color="white" />}
              iconBg="orange"
              title="Total Investors"
              count={dashboard?.totalInvestors}
              iconBoxBg="#ffd88f"
              link="/manage-investors"
            />
          </div>
        }
        <div className="lg:col-span-4 col-span-9 bg-white rounded-xl shadow-md py-8">
          <div className="flex justify-between items-center pb-[2rem] border-b-2 px-8">
            <h3 className="text-[1.8rem] ">Popular Categories</h3>
            {userProfile?.user?.userRole === "Administrator" &&
              <a  href="/manage-categories" className="text-[1.2rem] bg-slate-300 rounded-md p-2 text-slate-[#eee]">
                View All
              </a>
            }
          </div>
          <PopularCategoriesCard Categories={dashboard?.categoryAndNoOfIdeas} />
        </div>
      </div>
      {userProfile?.user?.userRole === "Administrator" &&
        <div className="mt-8 grid grid-cols-9 gap-8">
          <div className="lg:col-span-5 col-span-9 bg-white rounded-xl">
            <div className="flex justify-between items-center py-[2rem] border-b-2 px-8">
              <h3 className="text-[1.8rem] ">Newest Users</h3>
              <a href="/manage-users" className="text-[1.2rem] bg-slate-300 rounded-md p-2 text-slate-[#eee]">
                View All
              </a>
            </div>
            <UsersTable data={users} loading={loadingUsers} />
          </div>
          <div className="lg:col-span-4 col-span-9 bg-white rounded-xl">
            <div className="flex justify-between items-center py-[2rem] border-b-2 px-8">
              <h3 className="text-[1.8rem] ">Newest Investors</h3>
              <a href="/manage-investors" className="text-[1.2rem] bg-slate-300 rounded-md p-2 text-slate-[#eee]">
                View All
              </a>
            </div>
            <InvestorsTable data={investors} loading={loadingInvestors} />
          </div>
        </div>
      }
      {
        userProfile?.user?.userRole !== "Administrator" &&
        <div className="mt-8 grid grid-cols-9 gap-8">
          <div className="col-span-9 bg-white rounded-xl">
            <div className="flex justify-between items-center py-[2rem] border-b-2 px-8">
              <h3 className="text-[1.8rem] ">Newest Ideas</h3>
              <a href="/manage-my-ideas" className="text-[1.2rem] bg-slate-300 rounded-md p-2 text-slate-[#eee]">
                View All
              </a>
            </div>
            <IdeasTable data={ideas} loading={loadingIdeas} />
          </div>
        </div>
      }
    </div>
  );
}

export default Summary;



const PopularCategoriesCard = ({ Categories }) => {
  const { userProfile } = useAuthStore()
  return (
    <div className="grid md:grid-cols-4 gap-8 md:gap-2 my-[2rem] px-8">
      {Categories?.slice(0, 4)?.map((cat) => (
        <div key={cat?.id} className="flex flex-col justify-start items-center  border hover:bg-slate-300 p-4 rounded-xl">
          <BiCategory size={40} color={cat?.colour} className="mb-2" />
          <p className="text-[1.6rem] mb-1 text-center break-all">{cat?.categoryName}</p>
          {userProfile?.user?.userRole === "Administrator" &&
            <p className="text-[1.3rem] text-gray-400">{cat?.noOfIdeas} Ideas</p>
          }
        </div>
      ))}
      {/* <div className="flex flex-col justify-center items-center border hover:bg-slate-300 p-4 rounded-xl">
        <TbBuildingCarousel size={40} color="orange" className="mb-2" />
        <p className="text-[1.6rem] mb-1">Technology</p>
        {userProfile?.user?.userRole === "Administrator" &&
          <p className="text-[1.3rem] text-gray-400">14 Ideas</p>
        }
      </div>
      <div className="flex flex-col justify-center items-center border hover:bg-slate-300 p-4 rounded-xl">
        <SiAffinitydesigner size={40} color="maroon" className="mb-2" />
        <p className="text-[1.6rem] mb-1">Design</p>
        {userProfile?.user?.userRole === "Administrator" &&
          <p className="text-[1.3rem] text-gray-400">11 Ideas</p>
        }
      </div>
      <div className="flex flex-col justify-center items-center border hover:bg-slate-300 p-4 rounded-xl">
        <RiAdvertisementFill size={40} color="violet" className="mb-2" />
        <p className="text-[1.6rem] mb-1">Marketing</p>
        {userProfile?.user?.userRole === "Administrator" &&
          <p className="text-[1.3rem] text-gray-400">10 Ideas</p>
        }
      </div> */}
    </div>
  );
};
