import React from "react";
import FunnelsHome from "../../Components/AdminDashboard/funnels/Home";

function ManageFunnels() {
    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            < FunnelsHome />
        </div>
    );
}

export default ManageFunnels;
