import React, { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import ModalContainer from "../Modal/ModalContainer";
import CategoryModal from "../Modal/CategoryModal";

function BorderedSelect({ label, addIdea, setAddIdea }) {
  const [openModal, setOpenModal] = useState(false);
  // const [selectedCategory, setSelectedCategory] = useState("");
  return (
    <>
      <div className="w-full flex flex-col">
        <label className="text-[2.4rem] text-[#10324099]">{label}</label>
        <div
          className="border-b relative pr-4 pt-8 cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <p className="text-[#103240] text-[1.8rem]">
            {addIdea?.categoryName && addIdea?.categoryName}
          </p>
          <span className="absolute right-0 top-0 bottom-0">
            <AiFillCaretDown />
          </span>
        </div>
      </div>
      {openModal && (
        <ModalContainer>
          <CategoryModal
            setOpenModal={setOpenModal}
            setAddIdea={setAddIdea}
            addIdea={addIdea}
          />
        </ModalContainer>
      )}
    </>
  );
}

export default BorderedSelect;
