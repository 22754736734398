import React from "react";

function MainContent({ children }) {
  return (
    <div className="relative top-[8%] h-auto px-16 py-8 bg-[#f1f1f1]">
      {children}
    </div>
  );
}

export default MainContent;
