import React from "react";

import Select from "react-select";

const SelectUI = ({ isMulti, name, customStyle, options, defaultValue , placeholder, handleChange}) => {
  return (
    <Select
      defaultValue={defaultValue}
      isMulti={isMulti}
      name={name}
      options={options}
      className={`${customStyle}`}
      classNamePrefix="select"
      placeholder={placeholder}
      onChange={handleChange}
      required
    />
  );
};

export default SelectUI;
