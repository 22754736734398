import React, { useState } from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import Inputs from "../../CustomUIElements/Inputs/Inputs";

function UserType({ formValues, setFormValues, setNextStep }) {
  const [userType, setUserType] = useState("");
  return (
    <div>
      <p className="mb-[3.2rem] mt-[1.8rem] text-center text-[2.4rem] text-[#103240]">
        Choose user type
      </p>
      <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] max-w-[26.2rem] mx-auto">
        Individual or Organization?
      </h3>
      <form className="space-y-[1.6rem] relative">
        <div
          className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer relative"
          onClick={() =>
            setFormValues({ ...formValues, userType: "Individual" })
          }
        >
          <input
            type="radio"
            name="accountType"
            value="Individual"
            checked={formValues?.userType === "Individual"}
            id=""
            className="w-[2.4rem] h-[2.4rem]"
            required
          />
          <label className="cursor-pointer">I am an Individual</label>
        </div>
        <div
          className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer"
          onClick={() =>
            setFormValues({ ...formValues, userType: "Organisation" })
          }
        >
          <input
            type="radio"
            name="accountType"
            value="Organisation"
            checked={formValues?.userType === "Organisation"}
            id=""
            className="w-[2.4rem] h-[2.4rem]"
            required
          />
          <label className="cursor-pointer">I belong to an Organisation</label>
        </div>
        {formValues?.userType === "Organisation" && (
          <div>
            <Inputs
              name="organisationName"
              label="Organisation Name"
              isRequired={true}
              value={formValues?.organisationName}
              type="text"
              handleChange={(e) => {
                const inputValue = e.target.value;
                // Check if the input value matches the pattern or if it's an empty string
                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                  setFormValues({
                    ...formValues,
                    organisationName: inputValue,
                  });
                }
              }}
              minLength={1}
            />
          </div>
        )}
        <div className="!mt-20">
          <Buttons
            variant={
              formValues?.organisationName ||
              formValues?.userType === "Individual"
                ? "primary"
                : "disabled"
            }
            type="submit"
            text="Proceed"
            handleClick={(e) => {
              e.preventDefault();
              if (formValues?.userType === "Individual") {
                setFormValues({ ...formValues, organisationName: "" });
              }
              setNextStep("create");
            }}
          />
        </div>
      </form>
    </div>
  );
}

export default UserType;
