import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const authStore = (set) => ({
  userProfile: null,
  saveUserProfile: (user) => set({ userProfile: user }),
  userNotifications: [],
  saveUserNotifications: (userNotifications) => set({ userNotifications }),
  userNotificationsCount: null,
  saveUserNotificationsCount: (userNotificationsCount) =>
    set({ userNotificationsCount: userNotificationsCount }),

  removeUser: () => {
    localStorage.removeItem("userProfile");
    localStorage.removeItem("ideaStore");
    localStorage.removeItem("DashboardStore");
    localStorage.removeItem("token");
    localStorage.removeItem("CategoriesStore");
    set({
      userProfile: null,
      userNotifications: [],
      userNotificationsCount: null,
    });
  },
});

const useAuthStore = create(
  persist(devtools(authStore), {
    name: "userProfile",
  })
);

export default useAuthStore;
