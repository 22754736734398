import React, { useEffect } from 'react'
import useAuthStore from '../store/authStore';
import { useQuery } from 'react-query';
import { fetchNotifications } from '../utils/fetchNotifications';
import Scale from '../CustomUIElements/Loaders/ScaleLoader';
import parse from 'html-react-parser';
import moment from 'moment';

function Notifications({ src }) {
    const { userNotifications, saveUserNotifications, saveUserNotificationsCount } = useAuthStore();
    const { isLoading, isRefetching, refetch } = useQuery(
        "fetchNotifications",
        fetchNotifications,
        {
            enabled: true, 
            onSuccess: ({ data }) => {
                saveUserNotifications(data.data);
                saveUserNotificationsCount(0);
            },
        }
    );

    useEffect(() => {
        saveUserNotificationsCount(0);
    }, []);
    function formatTimeAgo(dateAdded) {
        const now = moment();
        const then = moment(dateAdded);
        const duration = moment.duration(now.diff(then));

        if (duration.asMinutes() < 60) {
            return Math.floor(duration.asMinutes()) + ' min';
        } else if (duration.asHours() < 24) {
            return Math.floor(duration.asHours()) + 'h';
        } else if (duration.asDays() < 2) {
            return '1d';
        } else {
            return Math.floor(duration.asDays()) + 'd';
        }
    }

    return (
        <div className={`!my-[4.4rem] app-container ${src && "!mt-[100px]"}`}>
            <div className={`${src ? "w-[90%]" : "w-[92rem]"} mx-auto`}>
                {isLoading ? (
                    <center>
                        <Scale />
                    </center>
                ) :
                    <>
                        <h1 className='font-semibold text-[18px] mb-[2rem]'>Notifications</h1>
                        {userNotifications?.length > 0 ? userNotifications?.map((notification, i) => {
                            return (
                                <div className='border-b pb-[1rem] mb-[2rem]'>
                                    <div className='flex justify-between items-center text-[15px] mb-2 font-semibold'>
                                        <p className='block '>{notification?.subject}</p>
                                        <p className='font-normal text-slate-500'>{formatTimeAgo(notification?.dateAdded)}</p>
                                    </div>
                                    <div className='text-[13px] custom-link'>
                                        {parse(notification?.message)}
                                    </div>
                                </div>
                            )
                        }) : <div>No notifications found</div>}
                    </>
                }
            </div>
        </div>
    )
}

export default Notifications