// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const addSetting = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Settings/add", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const addGrade = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Grades/add", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getLIGrades = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Grades/get", id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateGrade = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.put("/Grades/update", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteGrade = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/Grades/delete",
    method: "delete",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};