import React from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";

function AccountType({ formValues, setFormValues, setNextStep }) {
  return (
    <div>
      <p className="mb-[3.2rem] mt-[1.8rem] text-center text-[2.4rem] text-[#103240]">
        Choose account type
      </p>
      <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] max-w-[36.2rem] mx-auto">
        Idea Owner, Investor or Learning Institution?
      </h3>
      <form className="space-y-[1.6rem] relative">
        <div
          className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer relative"
          onClick={() => setFormValues({ accountType: "Idea Owner" })}
        >
          <input
            type="radio"
            name="accountType"
            value="Idea Owner"
            checked={formValues?.accountType === "Idea Owner"}
            id=""
            className="w-[2.4rem] h-[2.4rem]"
            required
          />
          <label className="cursor-pointer">I am an Idea/Co-idea Owner</label>
        </div>
        {/* <div
          className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer"
          onClick={() => setFormValues({ accountType: "Investor" })}
        >
          <input
            type="radio"
            name="accountType"
            value="Investor"
            checked={formValues?.accountType === "Investor"}
            id=""
            className="w-[2.4rem] h-[2.4rem]"
            required
          />
          <label className="cursor-pointer">I am an Investor</label>
        </div>
        <div
          className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer"
          onClick={() => setFormValues({ accountType: "Learning Institution" })}
        >
          <input
            type="radio"
            name="accountType"
            value="Learning Institution"
            checked={formValues?.accountType === "Learning Institution"}
            id=""
            className="w-[2.4rem] h-[2.4rem]"
            required
          />
          <label className="cursor-pointer">I am a Learning Institution</label>
        </div> */}
        <div className="!mt-20">
          <Buttons
            variant={formValues?.accountType ? "primary" : "disabled"}
            type="submit"
            text="Proceed"
            handleClick={(e) => {
              e.preventDefault();
              if (formValues?.accountType === "Idea Owner") {
                setNextStep("user-type");
              } else if(formValues?.accountType === "Investor") {
                setNextStep("investor-type");
              } else if(formValues?.accountType === "Learning Institution"){
                setNextStep("li-type")
              }
            }}
          />
        </div>
      </form>
    </div>
  );
}

export default AccountType;
