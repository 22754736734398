import React, { useEffect } from "react";
import Footer from "../Components/HeroComponent/Footer";
import PrivacyPolicy from "../Components/Privacy/privacy";

function Privacypage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PrivacyPolicy />
      <Footer />
    </>
  );
}

export default Privacypage;
