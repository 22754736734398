function Works() {
  return (
    <section className="app-container" id="works">
      <div className="mt-[11.6rem] max-w-[102rem] mx-auto flex flex-col justify-center">
        <div className="text-center mb-[11.6rem]">
          <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[3.6rem]">
            How It Works
          </p>
          <h3 className="text-[5.7rem] md:text-[8rem] leading-[7rem] md:leading-[10.6rem] font-bold text-[#103240]">
            Three{" "}
            <span className="bg-[#EDF4FC] text-[#008FD2] px-[1.5rem] py-[0.4rem] rounded-full">
              easy
            </span>{" "}
            <br /> steps
          </h3>
        </div>
        {howItWorksData.map((data) => {
          return (
            <div
              key={data.id}
              className="flex flex-col lg:flex-row gap-[3.2rem] w-full lg:items-center justify-between md:mb-[2.4rem] mb-[3.5rem]"
            >
              <div className="w-full md:w-[47%]">
                <h3 className="font-bold text-[8rem] md:text-[11.2rem] leading-[5rem] md:leading-[10.6rem] text-[#008FD2]">
                  {data.id}.
                </h3>
                <p className="text-[3rem] md:text-[5rem] text-[#103240] leading-[3.7rem] md:leading-[6.2rem] font-bold mt-4">
                  {data.text}
                </p>
              </div>
              <div
                className={`w-full lg:w-[49.4rem] h-full py-[10.6rem] px-[1.2rem] rounded-[5rem] container${data.id}`}
              >
                <picture className="">
                  <img className="w-full" src={data.imgSrc} alt="" />
                </picture>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
export default Works;

const howItWorksData = [
  {
    id: 1,
    text: "Give us a brief summary of your idea",
    imgSrc: "/assets/light.png",
    bgColor: "#EDF4FC",
  },
  {
    id: 2,
    text: "Choose the category your idea falls into",
    imgSrc: "/assets/light1.png",
    bgColor: "#FFFCF5",
  },
  {
    id: 3,
    text: "Hydreate works in the background running with your idea and offering recommendations",
    imgSrc: "/assets/light3.png",
    bgColor: "#F7F7F7",
  },
  // {
  //   id: 4,
  //   text: "Timestamp your idea and sign to confirm",
  //   imgSrc: "/assets/light2.png",
  //   bgColor: "#F6F7F7",
  // },
  
];