import { useState } from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

function LogoNavbar() {
  return (
    <header className="fixed left-0 right-0 md:sticky top-0 bg-white z-50">
      <div className="app-container">
        <div className="mx-auto max-h-auto  md:max-h-[8rem] flex justify-between items-center w-full uppercase py-[1.4rem] md:py-[2.4rem]">
          <a href="/#home">
            <img
              src="/assets/logo.svg"
              alt="HYDREATE"
              className="md:ml-6 lg:ml-0"
            />
          </a>
        </div>
      </div>
    </header>
  );
}

export default LogoNavbar;
