import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
// import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { deleteSubscription } from "../../../utils/deleteSubscription";
import { updateSubscription } from "../../../utils/updateSubscription";
import ReactQuill from "react-quill";
import parse from "html-react-parser"
import { ChromePicker } from "react-color";

function SubscriptionTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
    const [rowData, setRowData] = useState(null);
    const [formValues, setFormValues] = useState({
        topic: "",
        colourCode: "",
        price: 0,
        expiryInMonths: ""
    });
    const [desc, setDesc] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState({
        modalName: "",
        showModal: false,
    });
    const { removeUser } = useAuthStore();
    const navigate = useNavigate()

    const { mutate, isLoading: deleting } = useMutation(() => deleteSubscription(rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Operation successful")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })

    const { mutate: updateFunnelFn, isLoading: isUpdating } = useMutation(updateSubscription, {
        onSuccess: ({ data }) => {
            // saveUserProfile(data.data);
            setOpenModal()
            toast.success(`${data.status}`)
            setFormValues({})
            refetch()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Subscriptions", rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Operation successful")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        updateFunnelFn({
            topic: formValues?.topic,
            description: desc,
            id: rowData?.id,
            colourCode: formValues?.colourCode,
            price: parseInt(formValues?.price),
            expiryInMonths: formValues?.expiryInMonths,
            noOfIdeas: formValues?.noOfIdeas
        });
    };

    useEffect(() => {
        if (rowData) {
            setFormValues({
                topic: rowData.topic || "",
                colourCode: rowData.colourCode || "",
                price: rowData.price || 0,
                expiryInMonths: rowData.expiryInMonths || "",
                noOfIdeas: rowData.noOfIdeas || ""
            });
            setDesc(rowData.description || "");
        }
    }, [rowData]);

    const handleColorChange = (color) => {
        setFormValues({ ...formValues, colourCode: color.hex })
    }

    return (
        <>
            <Table
                header={[
                    { name: "Subscription Type", identifier: "topic" },
                    { name: "Number of Ideas", identifier: "noOfIdeas" },
                    { name: "Color", identifier: "colourCode" },
                    { name: "Price", identifier: "price" },
                ]}
                data={data}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    actions: {
                        edit: true,
                        delete: false,
                        view: true,
                    },
                    showPagination: true,
                }}
                openModal={openModal}
                setOpenModal={setOpenModal}
                rowData={rowData}
                setRowData={setRowData}
                loading={loading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                searchQuery={searchQuery}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this subscription?"
                onConfirm={mutate}
                loading={deleting}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "enable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this subscription?"
                onConfirm={activateUser}
                loading={activatingUser}
            />
            {openModal?.modalName === "edit" && openModal?.showModal && (
                <ModalContainer height="500px">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Edit Subscription</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({ modalName: "", showModal: false })
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form className="space-y-[1.6rem]"
                            onSubmit={handleSubmit}
                        >
                            <Inputs
                                key={"topic"}
                                type={"text"}
                                label={"Topic"}
                                name={"topic"}
                                isRequired={true}
                                value={formValues["topic"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <ReactQuill
                                theme="snow"
                                value={desc}
                                onChange={setDesc}
                                className="peer !text-[1.6rem] h-[22rem] !relative !mb-[1.6rem]"
                                placeholder="Description"
                            />
                            <Inputs
                                key={"colourCode"}
                                type={"text"}
                                label={"Colour Code"}
                                name={"colourCode"}
                                isRequired={true}
                                value={formValues["colourCode"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                readOnly={true}
                            />
                            <center>
                                <ChromePicker color={formValues?.colourCode} onChange={(color) => handleColorChange(color)} disableAlpha={true} />
                            </center>
                            <Inputs
                                key={"noOfIdeas"}
                                type={"text"}
                                label={"Number of Ideas"}
                                name={"noOfIdeas"}
                                isRequired={true}
                                value={formValues["noOfIdeas"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"price"}
                                type={"text"}
                                label={"Price"}
                                name={"price"}
                                isRequired={true}
                                value={formValues["price"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"expiryInMonths"}
                                type={"text"}
                                label={"Expiry In Months"}
                                name={"expiryInMonths"}
                                isRequired={true}
                                value={formValues["expiryInMonths"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <div className="!mt-[3rem]">
                                <Buttons
                                    variant={
                                        formValues?.topic &&
                                            formValues?.colourCode &&
                                            formValues.price !== "" &&
                                            formValues?.expiryInMonths &&
                                            !isLoading
                                            ? "primary"
                                            : "disabled"
                                    }
                                    type="submit"
                                    text={isUpdating ? "Submitting..." : "Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            )}

            {openModal?.modalName === "view" && openModal?.showModal && (
                <ModalContainer height="500px">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">View Subscription Information</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({ modalName: "", showModal: false })
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form className="space-y-[1.6rem]"
                            onSubmit={handleSubmit}
                        >
                            <Inputs
                                key={"topic"}
                                type={"text"}
                                label={"Topic"}
                                name={"topic"}
                                isRequired={true}
                                value={formValues["topic"] || rowData["topic"]}
                                readOnly
                            />
                            <ul className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem] list-disc">
                                {parse(desc)}
                            </ul>
                            <Inputs
                                key={"colourCode"}
                                type={"text"}
                                label={"Colour Code"}
                                name={"colourCode"}
                                isRequired={true}
                                value={formValues["colourCode"] || rowData["colourCode"]}
                                readOnly
                            />
                            <Inputs
                                key={"price"}
                                type={"text"}
                                label={"Price"}
                                name={"price"}
                                isRequired={true}
                                value={formValues["price"] || rowData["price"]}
                                readOnly
                            />
                            <Inputs
                                key={"expiryInMonths"}
                                type={"text"}
                                label={"Expiry"}
                                name={"expiryInMonths"}
                                isRequired={true}
                                value={formValues["expiryInMonths"] || rowData["expiryInMonths"]}
                                readOnly
                            />
                        </form>
                    </div>
                </ModalContainer>
            )}
        </>
    );
}

export default SubscriptionTable;
