import { FaRegUserCircle } from "react-icons/fa";
import { useTheme } from "../../context/ThemeContext";
import useAuthStore from "../../store/authStore";
import { FiBell } from "react-icons/fi";
import Badge from "../../CustomUIElements/Badge/Badge";
import { Link } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { LiaTimesSolid } from "react-icons/lia";
function Navbar({ openMenu, setOpenMenu }) {
  const { logo } = useTheme();


  const { userProfile, userNotifications, userNotificationsCount, notificationsCount } = useAuthStore();
  // console.log(userNotifications);

  return (
    <div
      className="fixed top-0 lg:h-[9%] md:h-[65px] lg:w-10/12 w-full md:w-9/12 z-20 flex items-center lg:px-16 px-4 shadow-md bg-white"
    // style={{ ...theme }}
    >
      <div className="relative flex justify-between items-center w-full">
        <div className="flex gap-[20px] items-center">
          {openMenu ? <LiaTimesSolid className={`block md:hidden`} size={24} onClick={() => setOpenMenu(false)} /> :
            <IoMenuOutline className={`block md:hidden`} size={24} onClick={() => setOpenMenu(true)} />
          }
          <div className="w-[108px] h-[60px]">
            {/* For uni logo */}
            {/* {logo &&
              <img src={logo} className="w-full h-full object-cover" alt="Uni Logo" />
            } */}
            <Link to={"/"}>
              <img src="/assets/logo.svg" className="w-full h-full object-fit" alt="Uni Logo" />
            </Link>
          </div>
        </div>
        <div className="flex items-center gap-8">
          <Link to="notifications" className="relative">
            <FiBell size={20} color="gray" />
            {
              userNotificationsCount > 0 && (
                <Badge count={userNotificationsCount} />
              )
            }
          </Link>
          <div className="flex gap-2 items-center w-fit md:w-auto">
            <FaRegUserCircle size={28} color="gray" className="hidden md:block" />
            <span className="">
              <p className="text-[1.2rem] md:text-[1.4rem] font-semibold">{userProfile?.user?.firstName + " " + userProfile?.user?.lastName} </p>
              <p className="text-[1.2rem]">{userProfile?.user?.userRole}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
