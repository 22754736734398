function Goals() {
  return (
    <section className="app-container" id="goals">
      <div className=" max-w-[102rem] mx-auto flex flex-col justify-center">
        <div className="text-center mb-[5.6rem]">
          <h3 className="text-[5.7rem] md:text-[8rem] leading-[7rem] md:leading-[10.6rem] font-bold text-[#103240]">
            Our goals
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-[32px] place-items-start">
          {howItWorksData.map((data) => {
            return (
              <div
                key={data.id}
                className="flex flex-col lg:flex-row w-full lg:items-center justify-between md:mb-[2.4rem] mb-[3.5rem] col-span-2 lg:col-span-1"
              >
                <div className="w-full ">
                  <h3 className="font-bold text-[8rem] md:text-[5rem] text-[#008FD2]">
                    {data.id}.
                  </h3>
                  <p className="text-[3rem] text-[#103240] font-medium mt-4">
                    {data.text}
                  </p>
                  <p className="text-[1.6rem] mt-2">
                    {data.subText}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default Goals;

const howItWorksData = [
  {
    id: 1,
    text: "Assistive Technologies",
    subText: "Hydreate utilizes assistive technologies such as Natural Language Processing and recommendation engines to help users nurture their ideas. By leveraging technology, Hydreate promotes efficiency and reduces the need for manual intervention, thus contributing to sustainability efforts."
  },
  {
    id: 2,
    text: "Remote Work and Collaboration",
    subText: "The platform facilitates collaboration and idea development remotely, reducing the need for physical travel and minimizing carbon emissions associated with commuting."
  },
  {
    id: 3,
    text: "Promotion of Innovation and Creativity",
    subText: "By fostering creativity and innovation, Hydreate encourages the development of sustainable solutions to various challenges. This emphasis on innovation aligns with sustainability goals aimed at finding environmentally friendly solutions."
  },
  {
    id: 4,
    text: "Cloud-Based Infrastructure",
    subText: "Hydreate plans to leverage cloud computing services provided by AWS, which promotes energy efficiency and reduces the carbon footprint associated with traditional on-premises data centers."
  },
  {
    id: 5,
    text: "Virtual Assistance",
    subText: "Through its virtual assistant feature, Hydreate provides guidance and support to users, helping them navigate the complexities of idea development. By offering virtual assistance, Hydreate promotes efficiency and reduces the need for additional human resources, thus contributing to sustainability efforts."
  },
  {
    id: 6,
    text: "Data Analysis for Growth",
    subText: "Hydreate gathers and analyzes data from users to improve its services and better meet their needs. By leveraging data analytics, Hydreate can optimize its operations, reduce waste, and make informed decisions that support sustainability goals."
  },
  {
    id: 7,
    text: "Education and Training",
    subText: "Hydreate aims to work with educational institutions to promote creativity and innovation among students. By providing training and support, Hydreate empowers future generations to develop sustainable solutions to global challenges."
  },
  {
    id: 8,
    text: "Partnerships for Growth",
    subText: "Hydreate plans to collaborate with various stakeholders, including educational bodies, councils, and growth promoters, to expand its reach and impact. By forming partnerships, Hydreate can leverage existing resources and infrastructure, minimizing duplication of efforts and promoting sustainability."
  },
  {
    id: 9,
    text: "User-Centered Design",
    subText: "Hydreate prioritizes user experience and design, ensuring that its platform is intuitive, user-friendly, and accessible to all. By focusing on user needs and preferences, Hydreate promotes inclusivity and sustainability in its design approach."
  },
];
