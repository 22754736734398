import React, { useState } from "react";

function Inputs({ type, label, isRequired, handleChange, name, value, minLength, maxLength, pattern, readOnly}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="relative">
      <input
        type={showPassword && type === "password" ? "text" : type}
        name={name}
        placeholder={label}
        required={isRequired}
        className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]"
        autoComplete="new-password"
        aria-autocomplete="none"
        onChange={handleChange}
        value={value}
        readOnly={readOnly}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
      />
      {type === "password" && (
        <p
          className="absolute top-[2rem] right-[1.5rem] font-medium text-[1.2rem] text-[#008FD2] cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? "HIDE" : "SHOW"}
        </p>
      )}
    </div>
  );
}

export default Inputs;
