import React, { useEffect } from "react";
import DashBoardHomeOnRegister from "../../Components/Dashboard/DashBoardHomeOnRegister";
import { useNavigate } from "react-router-dom";
import useIdeaStore from "../../store/ideaStore";

function DashboardPage() {
  const { ideas } = useIdeaStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (ideas?.data?.length > 0) {
      return navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <DashBoardHomeOnRegister />
    </>
  );
}

export default DashboardPage;
