// LayoutWrapper.js
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../store/authStore";
import DashboardLayout from "./DashboardLayout";
import AdminDashboardLayout from "./AdminDashboardLayout";

const USER_ROLES = {
    ADMIN: "admin",
    USER: "user",
    LI: "learning_institution",
    STU: "student",
    LECT: "lecturer"
};

const LayoutWrapper = ({ role }) => {
    const { userProfile } = useAuthStore();
    const navigate = useNavigate()

    useEffect(() => {
        if (!userProfile?.user) {
            navigate("/login")
        }
    }, [userProfile])

    const getUserRole = () => {
        if (userProfile?.user?.userRole === "Administrator" || userProfile?.user?.userRole === "Investor") {
            return USER_ROLES.ADMIN;
        } else if (userProfile?.user?.userRole === "LearningInstitution") {
            return USER_ROLES.LI
        } else if (userProfile?.user?.userRole === "Student") {
            return USER_ROLES.STU;
        } else if (userProfile?.user?.userRole === "Lecturer") {
            return USER_ROLES.LECT;
        } else {
            return USER_ROLES.USER;
        }
    };

    const userRole = getUserRole();

    if (userRole === USER_ROLES.ADMIN && role === USER_ROLES.ADMIN) {
        return <AdminDashboardLayout><Outlet /></AdminDashboardLayout>;
    } else if (userRole === USER_ROLES.USER && role === USER_ROLES.USER) {
        return <DashboardLayout><Outlet /></DashboardLayout>;
    } else if (userRole === USER_ROLES.LI && role === USER_ROLES.LI) {
        return <AdminDashboardLayout><Outlet /></AdminDashboardLayout>;
    } else if (userRole === USER_ROLES.STU && role === USER_ROLES.STU) {
        return <AdminDashboardLayout><Outlet /></AdminDashboardLayout>;
    } else if (userRole === USER_ROLES.LECT && role === USER_ROLES.LECT) {
        return <AdminDashboardLayout><Outlet /></AdminDashboardLayout>;
    } else {
        return null; // Handle unauthorized access or return a fallback component
    }
};

export default LayoutWrapper;
