import React, { useState } from "react";
import { MdModeEditOutline, MdRemoveRedEye } from "react-icons/md";
import CreateIdeaContent from "../../CreateIdea/CreateIdeaContent";
import CreateIdeaHeader from "../../CreateIdea/CreateIdeaHeader";
import { Buttons } from "../../../../CustomUIElements/Buttons/Buttons";
import BorderedTextArea from "../../../../CustomUIElements/Inputs/BorderTextArea";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useAuthStore from "../../../../store/authStore";
import { saveAnswer } from "../../../../utils/saveAnswer";
import { updateAnswer } from "../../../../utils/updateAnswer";
import { fetchQuestions } from "../../../../utils/fetchQuestions";
import { BsCheckCircle } from "react-icons/bs";
import { fetchIdeaScore } from "../../../../utils/fetchIdeaScore";
import useIdeaStore from "../../../../store/ideaStore";

function Question({ question, setQuestions, singleIdea, setSingleIdea, src }) {
  const { id } = useParams();
  const { userProfile } = useAuthStore();
  const [openQuestion, setOpenQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState(
    question?.ideaAnswerViewModel?.answer || ""
  );
  const navigate = useNavigate();
  const { removeUser } = useAuthStore();
  const { refetch: fetchIdeaQuestions } = useQuery(
    "fetchQuestions",
    () => fetchQuestions({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setQuestions(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  const { refetch: fetchIdeaProgressScore } = useQuery(
    "fetchIdeaScore",
    () => fetchIdeaScore({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        let updatedData = { ...singleIdea };
        updatedData["progressScore"] = Math.round(data.data);
        console.log("updatedData", updatedData);
        setSingleIdea(updatedData);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  const { mutate: saveIdeaAnswer, isLoading: savingIdea } = useMutation(
    saveAnswer,
    {
      onSuccess: ({ data }) => {
        toast.success(`${data.status}`);
        setOpenQuestion(false);
        fetchIdeaQuestions();
        fetchIdeaProgressScore();
      },
      onError: ({ response }) => {
        console.log(response);
        toast.error(`${response?.statusText}`);
        if (response.status === 401) {
          removeUser();
          navigate("/login");
        }
      },
    }
  );
  const { mutate: updateIdeaAnswer, isLoading: updatingIdea } = useMutation(
    updateAnswer,
    {
      onSuccess: ({ data }) => {
        toast.success(`${data.status}`);
        setOpenQuestion(false);
        fetchIdeaQuestions();
      },
      onError: ({ response }) => {
        console.log(response);
        toast.error(`${response?.statusText}`);
        if (response.status === 401) {
          removeUser();
          navigate("/login");
        }
      },
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (question?.ideaAnswerViewModel?.answer) {
      updateIdeaAnswer({
        id: parseInt(question?.ideaAnswerViewModel?.id),
        ideaId: parseInt(id),
        questionId: question?.id,
        answer,
        userId: userProfile?.user?.id,
      });
    } else {
      saveIdeaAnswer({
        ideaId: parseInt(id),
        questionId: question?.id,
        answer,
      });
    }
  };
  return (
    <div className="p-[1.6rem] rounded-[1.2rem] border border-[#E4E4E4] flex justify-between items-center mb-[1.6rem]">
      <div className="flex items-center gap-[1.2rem]">
        {question?.isAnswered && <img src="/assets/check.svg" alt="" />}
        <span className="text-[#103240] text-[1.6rem] font-semibold">
          <p>{question?.name}</p>
        </span>
      </div>
      <span className="text-[#103240]">
        {src === "lecturer" ? <MdRemoveRedEye size={20}
          className="cursor-pointer"
          onClick={() => {
            setOpenQuestion(true);
            setSelectedQuestion(question?.id);
          }} /> :
          <MdModeEditOutline
            size={20}
            className="cursor-pointer"
            onClick={() => {
              setOpenQuestion(true);
              setSelectedQuestion(question?.id);
            }}
          />
        }
      </span>
      {openQuestion && selectedQuestion === question?.id && (
        <div className="fixed inset-0 z-50 h-screen bg-white">
          <CreateIdeaHeader setOpenCreateModal={setOpenQuestion} action={src === "lecturer" ? "View Question" : "Answer a Question"} />
          <div className="w-full md:w-[65rem] mx-auto mt-[4.8rem] px-[20px] md:p-0">
            <CreateIdeaContent>
              <div>
                <h4 className="text-[#008FD2] text-[1.6rem] font-medium mb-[.5rem]">
                  Question
                </h4>
                <p className="text-[#103240] font-medium text-[1.8rem] mb-[4.8rem]">
                  {question?.name}
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <BorderedTextArea
                  label="Your response"
                  value={answer}
                  handleChange={(e) => setAnswer(e.target.value)}
                  isRequired={true}
                  readOnly={src === "lecturer"}
                />
                {src !== "lecturer" &&
                  <div className="flex justify-end ml-auto mt-4 ">
                    <div className="flex gap-[1.6rem]">
                      <Buttons
                        text="Cancel"
                        variant="cancel"
                        handleClick={() => {
                          setAnswer(
                            question?.ideaAnswerViewModel?.answer
                              ? question?.ideaAnswerViewModel?.answer
                              : ""
                          );
                          setOpenQuestion(false);
                        }}
                        type="button"
                      />
                      <Buttons
                        text="Save"
                        variant={
                          savingIdea || updatingIdea ? "disabled" : "primary"
                        }
                        type="submit"
                      />
                    </div>
                  </div>
                }
              </form>
            </CreateIdeaContent>
          </div>
        </div>
      )}
    </div>
  );
}

export default Question;
