// Layout.js
import React, { useState } from "react";
import { useTheme } from "../context/ThemeContext";
import Sidebar from "../Components/AdminDashboard/Sidebar";
import Navbar from "../Components/AdminDashboard/Navbar";
import MainContent from "../Components/AdminDashboard/MainContent";
import { Outlet } from "react-router-dom";

const AdminDashboardLayout = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <div style={{ minHeight: "100vh" }} className="grid grid-cols-12">
      <div className={`${openMenu ? "block absolute w-10/12 z-[1000]" : "hidden"} md:block lg:col-span-2 md:col-span-3`} style={{ minHeight: "100vh" }}>
        <Sidebar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      </div>
      <div className="col-span-12 lg:col-span-10 md:col-span-9">
        <Navbar openMenu={openMenu} setOpenMenu={setOpenMenu} />
        {/* <MainContent /> */}
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
