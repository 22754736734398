import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

function FloatingAdd({ icon, handleClick }) {
  return (
    <div
      className="fixed right-[20.5%] bottom-[5%] bg-[#008FD2] p-4 rounded-full cursor-pointer"
      onClick={handleClick}
    >
      {icon}
    </div>
  );
}

export default FloatingAdd;
