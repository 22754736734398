import { db } from "../firebase-config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  orderBy,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

export const createGroupChat = async (groupInfo, creatorInfo, courseInfo) => {
  try {
    // If a group ID is provided, check if the group already exists
    let existingGroupId = null;
    if (groupInfo?.id) {
      const querySnapshot = await getDocs(
        query(collection(db, "groups"), where("groupId", "==", groupInfo.id))
      );

      // If the group exists, return the existing group ID
      if (!querySnapshot.empty) {
        existingGroupId = querySnapshot.docs[0].data().groupId;
        return existingGroupId;
      }
    }

    // If the group does not exist, create a new group
    const chatDocRef = doc(collection(db, "groups"));
    const newGroupData = {
      lecturerInfo: creatorInfo,
      groupInfo: {
        groupId: chatDocRef.id,
        groupName: groupInfo?.name,
        description: groupInfo?.description,
        learningInstitutionId: groupInfo?.learningInstitutionId,
      },
      courseInfo,
      members: [
        {
          id: creatorInfo?.id,
          firstName: creatorInfo?.firstName,
          lastName: creatorInfo?.lastName,
          color: "#008fd2",
        },
      ],
      membersId: [creatorInfo.id],
      createdAt: serverTimestamp(),
      isActive: true,
    };

    await setDoc(chatDocRef, newGroupData);

    return chatDocRef.id;
  } catch (error) {
    console.error("Error creating group chat:", error);
    throw error; // Re-throw the error to handle it upstream if needed
  }
};

export const fetchGroupId = async (gameId) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "groups"), where("gameId", "==", gameId))
    );

    if (!querySnapshot.empty) {
      const existingGroup = querySnapshot.docs[0].data();

      // Return the existing chatId or handle it as needed
      return existingGroup;
    }

    return false;
  } catch (error) {
    console.error("Error at fetching chat:", error);
    return false;
  }
};

export const sendGroupTextMessage = async (groupId, data) => {
  try {
    const {
      senderId,
      messageId,
      messageText,
      senderFirstName,
      senderLastName,
    } = data;

    const messageData = {
      id: messageId,
      message: messageText,
      createdAt: serverTimestamp(),
      senderId,
      senderFirstName,
      senderLastName,
    };

    // Add the new message to the group's messages collection
    const docRef = await addDoc(
      collection(db, "groups", groupId, "messages"),
      messageData
    );

    // Retrieve the newly saved message data
    const savedData = (await getDoc(docRef)).data();

    // Reference to the group chat document
    const chatDocRef = doc(db, "groups", groupId);
    const chatSnapshot = await getDoc(chatDocRef);

    // Check if the chat document exists
    if (!chatSnapshot.exists()) {
      console.error("Chat not found with the given chatId.");
      return false;
    }

    const chatData = chatSnapshot.data();

    // Check if the sender is part of the chat
    if (chatData?.sender?.id === senderId) {
      const updateData = {
        updatedAt: serverTimestamp(),
      };

      // Update the chat document
      await updateDoc(chatDocRef, updateData);
    }

    return savedData;
  } catch (error) {
    console.error("Error sending group text message:", error);
    return false;
  }
};

export const addUserToGroupChat = async (groupId, userInfo) => {
  console.log({ groupId, userInfo });
  const colors = ["#008fd2", "#8E44AD", "#2980B9", "#27AE60", "#C0392B"];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  try {
    const chatDocRef = collection(db, "groups");
    const chatSnapshot = await getDocs(
      query(chatDocRef, where("groupInfo.groupId", "==", groupId))
    );

    // Check if the chat document exists
    const chatDoc = chatSnapshot.docs[0];
    if (!chatDoc?.exists()) {
      console.error("Chat not found with the given chatId.");
      return false;
    }

    const chatData = chatDoc.data();

    const groupChatDocRef = doc(db, "groups", chatData.groupInfo.groupId);

    // Check if the user is already a member of the chat
    if (chatData.membersId.includes(userInfo?.id)) {
      return "User is already a member of the group";
    } else {
      const newMemberData = {
        id: userInfo.id,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        color: randomColor,
      };

      const updateData = {
        members: arrayUnion(newMemberData),
        membersId: arrayUnion(userInfo.id),
        updatedAt: serverTimestamp(),
      };

      await updateDoc(groupChatDocRef, updateData);
    }

    return true;
  } catch (error) {
    console.error("Error adding user to group chat:", error);
    return false;
  }
};

export const updateUnreadMessageCount = async (isRead, groupId, userId) => {
  try {
    const chatDocRef = doc(db, "groups", groupId);
    const chatSnapshot = await getDoc(chatDocRef);

    // Check if the chat document exists
    if (!chatSnapshot.exists()) {
      console.error("Chat not found with the given groupId.");
      return false;
    }

    const chatData = chatSnapshot.data();

    // Update badgeCount for each member except the sender
    const updatedMembers = chatData.members.map((member) => {
      if (member.id !== userId) {
        return {
          ...member,
          badgeCount: isRead ? member.badgeCount + 1 : 0,
        };
      } else {
        return member;
      }
    });

    // Update the updatedAt timestamp
    const updatedAt = serverTimestamp();

    // Update the entire groups document with the updated members array and updatedAt timestamp

    await updateDoc(chatDocRef, { members: updatedMembers, updatedAt });

    return true;
  } catch (error) {
    return false;
  }
};

export const removeUserFromGroup = async (groupId, userId) => {
  try {
    const chatDocRef = collection(db, "groups");
    const chatSnapshot = await getDocs(
      query(chatDocRef, where("groupInfo.groupId", "==", groupId))
    );

    // Check if the chat document exists
    if (!chatSnapshot.docs[0]?.data()) {
      return false;
    }

    const chatData = chatSnapshot.docs[0].data();

    // Check if the user is a member of the group chat
    if (!chatData.membersId.includes(userId)) {
      return false;
    }

    const updatedMembers = chatData.members.filter(
      (member) => member?.id !== userId
    );
    const updatedMembersId = chatData.membersId.filter((id) => id !== userId);

    // Update the chat document to mark the user as left the group
    const groupChatDocRef = doc(db, "groups", groupId);
    await updateDoc(groupChatDocRef, {
      members: updatedMembers,
      membersId: updatedMembersId,
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const deleteGroupChat = async (groupId) => {
  try {
    const groupDocRef = doc(db, "groups", groupId);
    await updateDoc(groupDocRef, { isActive: false });
    return true;
  } catch (error) {
    return false;
  }
};
export const updateGroupDetails = async (groupInfo) => {
  try {
    const groupDocRef = doc(db, "groups", groupInfo?.groupId);
    await updateDoc(groupDocRef, {
      groupInfo: {
        groupId: groupInfo?.groupId,
        groupName: groupInfo?.name,
        groupDescription: groupInfo?.description,
        learningInstitutionId: groupInfo?.learningInstitutionId,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchGroupChats = (userId) => {
  // console.log(userId)
  const chatsRef = collection(db, "groups");
  // console.log(chatsRef)
  const q = query(
    chatsRef,
    where("membersId", "array-contains", userId),
    where("isActive", "==", true)
  );
  return q;
};

export const fetchGroupMessages = (groupId) => {
  const messagesRef = collection(db, "groups", groupId, "messages");
  const q = query(messagesRef, orderBy("createdAt", "asc"));
  return q;
};
