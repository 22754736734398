import React, { useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { deleteInvestor } from "../../../utils/deleteInvestor";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import useWindowSize from "../../../hooks/useWindowSize";

function AllInvestorsTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const { removeUser } = useAuthStore();
  const {width} = useWindowSize()
  const navigate = useNavigate()
  const { mutate, isLoading: deletingUser } = useMutation(() => deleteInvestor(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Users", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  return (
    <>
      <Table
        header={[
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          { name: "Email", identifier: "email" },
          { name: "Phone Number", identifier: "phoneNumber" },
          // { name: "Number of Ideas", identifier: "numberOfIdeas" },
          // { name: "Investor Type", identifier: "investorType" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "disable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this investor?"
        onConfirm={mutate}
        loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "enable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this investor?"
        onConfirm={activateUser}
        loading={activatingUser}
      />
      {openModal.modalName === "edit" && openModal.showModal && (
        <ModalContainer height={"auto"}>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit User Form</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <AddInvestorForm formValues={formValues} setFormValues={setFormValues} isLoading={isLoading} rowData={rowData} setOpenModal={() =>
              setOpenModal({ modalName: "", showModal: false })} refetch={refetch}    searchQuery={searchQuery} />
          </div>
        </ModalContainer>
      )}
      {openModal.modalName === "view" && openModal.showModal && (
        <ModalContainer height={"auto"}>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View User Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <AddInvestorForm formValues={formValues} setFormValues={setFormValues} isLoading={isLoading} rowData={rowData} setOpenModal={() =>
              setOpenModal({ modalName: "", showModal: false })} refetch={refetch} mode="view" />
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default AllInvestorsTable;
