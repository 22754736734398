import React from "react";

function CreateIdeaContent({ children, stepTitle, stepImage, stepDesc }) {
  return (
    <div className="flex justify-center flex-col overflow-y-auto">
      <h3 className="text-center font-bold text-[3.7rem] text-[#103240] mb-[2.8rem]">
        {stepTitle}
      </h3>
      <div className="w-[25rem] mx-auto flex flex-col">
        {stepImage}
        <p className="mt-[1.4rem] font-medium text-[#103240B2] text-[1.3rem] text-center mb-[2.8rem]">{stepDesc}</p>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default CreateIdeaContent;
