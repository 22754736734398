import React from "react";

function BorderedTextArea({
  type,
  label,
  isRequired,
  handleChange,
  name,
  value,
  maxWordCount,
  readOnly
}) {
  return (
    <div className="relative">
      <textarea
        type={type}
        name={name}
        placeholder={label}
        required={isRequired}
        className="relative rounded outline-none border-b border-[#F7F7F7] w-full text-[16px] leading-[2.4rem] h-fit resize-none"
        onChange={handleChange}
        value={value}
        maxLength={maxWordCount && 150}
        readOnly={readOnly}
      ></textarea>
    </div>
  );
}

export default BorderedTextArea;
