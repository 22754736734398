const SummaryCards = ({ icon, iconBg, title, count, iconBoxBg, link }) => {
    return (
        <a href={link} className="bg-white p-8 rounded-xl shadow-md flex items-center gap-8 cursor-pointer">
            <div
                className="bg-slate-300 p-6 flex justify-center items-center w-fit h-fit rounded-xl"
                style={{ backgroundColor: iconBoxBg }}
            >
                <div
                    className="w-[4rem] h-[4rem] items-center flex justify-center rounded-2xl"
                    style={{ backgroundColor: iconBg }}
                >
                    {icon}
                </div>
            </div>
            <div>
                <p className="text-[1.6rem]">{title}</p>
                <p className="text-[3rem] font-semibold">{count}</p>
            </div>
        </a>
    );
};

export default SummaryCards