import React, { useEffect, useRef, useState } from 'react'
import { BsSend } from "react-icons/bs";
import { fetchGroupChats, fetchGroupMessages, sendGroupTextMessage } from '../../utils/chatFunctions';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import useAuthStore from '../../store/authStore';
import { PiCaretLeft } from "react-icons/pi";
import { v4 as uuid } from "uuid"
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase-config';
import moment from 'moment';
import Scale from '../../CustomUIElements/Loaders/ScaleLoader';
import { useTheme } from '../../context/ThemeContext';

function ChatPopup() {
    const { userProfile } = useAuthStore();
    const [selectedChat, setSelectedChat] = useState(null);
    const [messageText, setMessageText] = useState("");
    const [lastMessages, setLastMessages] = useState({});
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [error, setError] = useState(null);
    const messageContainerRef = useRef(null);
    const {theme, themeStyles} = useTheme()

    const [showProfile, setShowProfile] = useState(false);

    const r = fetchGroupChats(userProfile?.user?.id);
    const [allGroups, allGroupsLoading, allGroupsError] = useCollectionData(r, { idField: 'id' });

    useEffect(() => {
        if (allGroupsError) {
            setError('Error fetching groups');
            setLoadingGroups(false);
        }
        if (allGroupsLoading) {
            setLoadingGroups(true);
        } else if (allGroups) {
            setLoadingGroups(false);
            fetchLastMessages(allGroups);
        }
    }, [allGroups, allGroupsLoading, allGroupsError]);

    const fetchLastMessages = async (groups) => {
        setLoadingMessages(true);
        try {
            const messages = {};
            for (const group of groups) {
                if (group?.groupInfo?.groupId) {
                    const messagesRef = collection(db, 'groups', group?.groupInfo?.groupId, 'messages');
                    const q = query(messagesRef, orderBy('createdAt', 'desc'));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        const lastMessage = querySnapshot.docs[0].data();
                        messages[group?.groupInfo?.groupId] = lastMessage;
                    }
                }
            }
            setLastMessages(messages);
        } catch (error) {
            setError('Error fetching messages');
        } finally {
            setLoadingMessages(false);
        }
    };

    // useEffect(() => {
    //     if (userProfile?.user?.userRole === "Student" && allGroups) {
    //         setSelectedChat(allGroups[0]);
    //     }
    // }, [allGroups]);

    console.log("all groups", allGroups)

    const handleSendMessage = async () => {
        if (!messageText.trim()) return;
        const data = {
            senderId: userProfile?.user?.id,
            senderFirstName: userProfile?.user?.firstName,
            senderLastName: userProfile?.user?.lastName,
            messageText: messageText.trim(),
            messageId: uuid()
        };
        setMessageText("");
        await sendGroupTextMessage(selectedChat?.groupInfo?.groupId, data);
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='w-[85vw] md:w-[400px] min-h-[500px] max-h-[500px] h-[500px] bg-white shadow-xl rounded-[12px]'>
            {selectedChat || showProfile ? (
                <div className='border-b p-[15px] py-[10px] font-semibold text-[17px] flex gap-x-[20px] items-center'>
                    {/* {(showProfile) && ( */}
                    <PiCaretLeft size={20} className='cursor-pointer' onClick={() => showProfile ? setShowProfile(false) : setSelectedChat(null)} />

                    {showProfile ? <p>Profile</p> :
                        <div className='w-[35px] h-[35px] rounded-full flex justify-center items-center text-white text-[20px] font-bold shrink-0' style={{ backgroundColor: theme.backgroundColor }}>
                            <p>{selectedChat?.groupInfo?.groupName?.charAt(0)}</p>
                        </div>
                    }
                    <p onClick={() => setShowProfile(true)} className='cursor-pointer'>{!showProfile && selectedChat?.groupInfo?.groupName} {" "}{selectedChat?.courseInfo && !showProfile && `(${(selectedChat?.courseInfo?.courseCode)})`}</p>
                </div>
            ) : (
                <p className='border-b p-[15px] font-semibold text-[17px]'>{"Messages"}</p>
            )}
            <div className={`${selectedChat ? "min-h-[380px] h-[380px]" : "min-h-[390px] h-[390px]"} p-[15px] overflow-y-auto`} ref={messageContainerRef}>
                {(loadingGroups) && <center><Scale /></center>
                }
                {showProfile ? <ProfileView selectedChat={selectedChat} /> : selectedChat ? (
                    <MessageView selectedChat={selectedChat} messageContainerRef={messageContainerRef} allGroups={allGroups} />
                ) : (
                    allGroups?.length < 1 ? <p>Messages will appear here</p> : allGroups?.map((group, index) => (
                        <ChatView key={index} groupInfo={group} setSelectedChat={setSelectedChat} lastMessages={lastMessages} />
                    ))
                )}
            </div>
            {!showProfile && selectedChat && (
                <div className='flex gap-[15px] px-[15px] items-center'>
                    <input className='border rounded-[12px] p-[10px] w-[80%] h-[50px] shrink-0 outline-none border-gray-700 text-[14px]' placeholder='Type your message here...' value={messageText} onChange={(e) => setMessageText(e.target.value)} />
                    <button className=' text-white px-[10px] py-[5px] rounded-[12px] w-[60px] h-[50px] flex justify-center items-center shrink-0' onClick={handleSendMessage} style={{ backgroundColor: theme.backgroundColor }}><BsSend color='white' size={20} /></button>
                </div>
            )}
        </div>
    );
}

export default ChatPopup;



const ChatView = ({ groupInfo, setSelectedChat, lastMessages }) => {
    const {theme, themeStyles} = useTheme()
    const timestampObject = lastMessages[groupInfo?.groupInfo?.groupId]?.createdAt

    // Convert timestamp object to Date
    const date = new Date(timestampObject?.seconds * 1000); // Convert seconds to milliseconds

    // Format using Moment.js
    const formattedDate = moment(date).format('hh:mm A');
    return (
        <div className='flex gap-[15px] mb-[15px]'>
            <div className='w-[40px] h-[40px] rounded-full flex justify-center items-center text-white text-[20px] font-bold shrink-0' style={{ backgroundColor: theme.backgroundColor }}>
                <p>
                    {groupInfo?.groupInfo?.groupName?.charAt(0)}
                </p>
            </div>
            <div className='w-full border-b pb-[5px] cursor-pointer' onClick={() => setSelectedChat(groupInfo)}>
                <div className='flex justify-between'>
                    <p className='max-w-[300px] font-semibold text-[14px]'> {groupInfo?.groupInfo?.groupName}{" "}{groupInfo?.courseInfo && `(${(groupInfo?.courseInfo?.courseCode)})`}</p>
                    <p className='text-[12px] font-extralight shrink-0'>{formattedDate === "Invalid date" ? "" : formattedDate || "00:00 AM"}</p>
                </div>
                <div className='flex justify-between'>
                    <i className='max-w-[300px] text-slate-500 text-[14px]'>{lastMessages[groupInfo?.groupInfo?.groupId]?.message || "Last messages will appear here"}</i>
                    {/* <div className='bg-[#008FD2] w-[15px] h-[15px] rounded-full text-[10px] leading-[10px] pt-[2px] shrink-0 text-white flex justify-center items-center font-semibold'>1</div> */}
                </div>
            </div>
        </div>
    )
}

const MessageView = ({ messageContainerRef, selectedChat }) => {
    const { userProfile } = useAuthStore()
    const groupMessagesQuery = fetchGroupMessages(selectedChat?.groupInfo?.groupId);
    const [messages] = useCollectionData(groupMessagesQuery, { idField: 'id' });
    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTo(0, messageContainerRef.current.scrollHeight);
        }
    }, [messages]);
    return (
        <div>
            {messages?.length > 0 ? messages?.map((message, i) => {
                if (message?.senderId === userProfile?.user?.id) {
                    return <UserMessage message={message} key={i} />
                } else {
                    return <OthersMessage message={message} key={i} selectedChat={selectedChat} />
                }
            }) : ""}
        </div>
    )
}

const UserMessage = ({ message }) => {
    const {theme, themeStyles} = useTheme()
    return (
        <div className='flex justify-end items-end gap-[8px] my-[10px]'>
            <p className='max-w-[80%] h-fit  p-[5px] rounded-[10px] text-white text-[14px]' style={{ backgroundColor: theme.backgroundColor }}>{message?.message}</p>
        </div>
    );
};

const OthersMessage = ({ selectedChat, message }) => {
    const colors = selectedChat?.members?.find((m) => m?.id === message?.senderId);
    let randomColor = colors?.color;

    return (
        <div className='flex items-end gap-[8px] my-[10px]'>
            <div style={{ background: randomColor }} className='text-white w-[25px] h-[25px] rounded-full flex justify-center items-center'>
                {message?.senderFirstName?.charAt(0)}
            </div>
            <div className='max-w-[80%] h-fit bg-[#353333] p-[5px] px-[8px] rounded-[10px] text-white '>
                <p className='text-[12px] font-semibold' style={{ color: randomColor }}>{message?.senderFirstName} {message?.senderLastName}</p>
                <p className='text-[14px]'>{message?.message}</p>
            </div>
        </div>
    );
}

const ProfileView = ({ selectedChat }) => {
    const {theme, themeStyles} = useTheme()
    return (
        <div className='text-[17px]'>
            <div className='w-[60px] h-[60px] mx-auto rounded-full flex justify-center items-center text-white text-[30px] font-semibold mb-3' style={{ backgroundColor: theme.backgroundColor }}>{selectedChat?.groupInfo?.groupName?.charAt(0)}</div>
            <p className='w-fit text-center mx-auto mb-2'>{selectedChat?.groupInfo?.groupName}</p>
            <p className='max-w-[250px] mx-auto text-[13px] text-center'>{selectedChat?.groupInfo?.groupDescription}</p>
            <div className='mt-8'>
                <p className='font-semibold mb-4'>Group Members</p>
                {selectedChat?.members?.map((member) => (
                    <p key={member?.id} className='mb-2'>{member?.firstName}{" "}{member?.lastName}</p>
                ))}
            </div>
        </div>
    )
}