import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
// import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { deleteSubscription } from "../../../utils/deleteSubscription";
import { updateSubscription } from "../../../utils/updateSubscription";
import ReactQuill from "react-quill";
import parse from "html-react-parser"

function InterestTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
    const [rowData, setRowData] = useState(null);
    const [formValues, setFormValues] = useState({
        topic: "",
        colourCode: "",
        price: 0,
        expiryInMonths: ""
    });
    const [desc, setDesc] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState({
        modalName: "",
        showModal: false,
    });
    const { removeUser } = useAuthStore();
    const navigate = useNavigate()

    const { mutate, isLoading: deleting } = useMutation(() => deleteSubscription(rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Operation successful")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })

    const { mutate: updateFunnelFn, isLoading: isUpdating } = useMutation(updateSubscription, {
        onSuccess: ({ data }) => {
            // saveUserProfile(data.data);
            setOpenModal()
            toast.success(`${data.status}`)
            setFormValues({})
            refetch()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Subscriptions", rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Operation successful")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        updateFunnelFn({
            topic: formValues?.topic,
            description: desc,
            id: rowData?.id,
            colourCode: formValues?.colourCode,
            price: parseInt(formValues?.price),
            expiryInMonths: formValues?.expiryInMonths,
            noOfIdeas: formValues?.noOfIdeas
        });
    };

    useEffect(() => {
        if (rowData) {
            setFormValues({
                topic: rowData.topic || "",
                colourCode: rowData.colourCode || "",
                price: rowData.price || 0,
                expiryInMonths: rowData.expiryInMonths || "",
                noOfIdeas: rowData.noOfIdeas || ""
            });
            setDesc(rowData.description || "");
        }
    }, [rowData]);

    return (
        <>
            <Table
                header={[
                    { name: "Owner", identifier: "ideaOwner" },
                    { name: "Title", identifier: "name" },
                    { name: "Description", identifier: "description" },
                ]}
                data={data}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    actions: {
                        view: true,
                    },
                    showPagination: true,
                }}
                openModal={openModal}
                setOpenModal={setOpenModal}
                rowData={rowData}
                setRowData={setRowData}
                loading={loading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                searchQuery={searchQuery}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this interest?"
                onConfirm={mutate}
                loading={deleting}
            />
            <ConfirmationModal
                showModal={openModal?.modalName === "enable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this interest?"
                onConfirm={activateUser}
                loading={activatingUser}
            />
        </>
    );
}

export default InterestTable;
