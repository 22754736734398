import React from 'react'
import InterestHome from '../../Components/AdminDashboard/interests/Home'

function ManageInterests() {
  return (
    <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
      <InterestHome />
    </div>
  )
}

export default ManageInterests