import React, { useState } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../../CustomUIElements/Inputs/Inputs';
import { toast } from 'react-toastify';
import { fetchSubscriptions } from '../../../utils/fetchSubscriptions';
import { addSubscription } from '../../../utils/addSubscription';
import ReactQuill from "react-quill";
import parse from "html-react-parser"
import { HiOutlineSearch } from 'react-icons/hi';
import TemplateTable from './TemplateTable';
import { fetchEmailTemplates } from '../../../utils/fetchEmailTemplates';
import { addEmailTemplate } from '../../../utils/addEmailTemplate';
import { activateEntityStatus } from '../../../utils/activateEntityStatus';
import { deleteTemplate } from '../../../utils/deleteTemplate';
import ConfirmationModal from '../../../CustomUIElements/Modal/ConfirmationModal';

function EmailTemplateHome() {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { removeUser } = useAuthStore()
  // const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [desc, setDesc] = useState("")
  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading: loadingFunnels, isRefetching, refetch } = useQuery("fetchTemplates", fetchEmailTemplates, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      setTemplates(data?.data)
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })

  const { mutate, isLoading } = useMutation(addEmailTemplate, {
    onSuccess: ({ data }) => {
      setOpenModal(false)
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      code: formValues?.code,
      message: desc,
      subject: formValues?.subject
    });
  };

  const mutation = useMutation(deleteTemplate);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "EmailTemplates", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };

  return (
    <div>
      <div className="flex flex-col lg:flex-row justify-between items-center  mb-[2rem] gap-y-[20px] lg:gap-y-0">
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <h1 className="text-[20px] font-semibold">Email Templates</h1>
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
        <div className="w-fit">
          <Buttons
            type="button"
            text="Add New Template"
            variant="primary"
            handleClick={() => setOpenModal(true)}
          />
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      <div className="mt-4 mb-8">
        <TemplateTable data={templates} loading={loadingFunnels || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
      </div>
      {openModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem]  mb-4">
              <h3 className="">Add New Template</h3>
              <LiaTimesSolid
                onClick={() => { setOpenModal(false); setFormValues({}) }}
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"code"}
                type={"text"}
                label={"Code"}
                name={"code"}
                isRequired={true}
                value={formValues["code"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value?.replaceAll(" ", "_"),
                  })
                }
              />
              <Inputs
                key={"subject"}
                type={"text"}
                label={"Subject"}
                name={"subject"}
                isRequired={true}
                value={formValues["subject"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <ReactQuill
                theme="snow"
                value={desc}
                onChange={setDesc}
                className="peer !text-[1.6rem] h-[15rem] !relative !mb-[1.6rem]"
                placeholder="Message"
              />
              <div className="!mt-[10rem] lg:!mt-[5rem]">
                <Buttons
                  variant={
                    formValues?.code &&
                      desc &&
                      formValues?.subject &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
      <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these templates?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these templates?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>

  )
}

export default EmailTemplateHome