import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="py-[12rem] bg-black relative">
      <div className="app-container">
        <div className="w-[15rem]">
          <picture>
            <img src="/assets/logo-white.png" alt="" />
          </picture>
        </div>
        <p className="max-w-[42.6rem] mt-[3.2rem] mb-[6.3rem] text-white text-[2rem] font-semibold leading-[3rem]">
          Every idea needs a starting point. Start yours with Hydreate.
        </p>
        <div className="flex flex-col md:flex-row justify-between md:items-center gap-[2rem]">
          <div className="flex gap-[1.6rem]">
            <picture>
              <img src="/assets/instagram-logo.svg" alt="" />
            </picture>
            <picture>
              <img src="/assets/facebook-logo.svg" alt="" />
            </picture>
            <picture>
              <img src="/assets/twitter-logo.svg" alt="" />
            </picture>
          </div>
          <div className="text-white flex flex-col md:flex-row md:items-center gap-[2rem] text-[1.6rem] font-medium ">
            <p>© Hydreate Inc, all rights reserved.</p>
            <ul className="flex gap-[2rem] list-disc text-[#FFFFFFB3] ml-8 md:ml-0">
              <li className="cursor-pointer">
                <Link to="/privacy-policy">Privacy policy</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/terms-and-conditions">Terms and conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
