import React, { useState } from 'react'
import useIdeaStore from '../store/ideaStore';
import useAuthStore from '../store/authStore';
import { useQuery } from 'react-query';
import { AiOutlinePlus } from 'react-icons/ai';
import EmptyIdea from '../Components/Ideas/IdeasPage/EmptyIdea';
import IdeasCard from '../Components/Dashboard/IdeasCard';
import { getGroupIdeas } from '../utils/students';
import { MdOutlineMessage, MdClose } from "react-icons/md";
import ChatPopup from '../Components/ChatBox/ChatPopup';
import CreateIdea from '../Components/Ideas/CreateGroupIdea/CreateIdea';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchCategories } from '../utils/fetchCategories';
import Scale from '../CustomUIElements/Loaders/ScaleLoader';

function GroupIdeaPage({ src }) {
  const { ideas, saveIdeas, categories, saveCategories } = useIdeaStore();
  const { removeUser, userProfile } = useAuthStore()
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { courseId, groupId } = useParams()

  const [openChat, setOpenChat] = useState(false);
  const { isError } = useQuery("fetchCategories", fetchCategories, {
    onSuccess: ({ data }) => {
      saveCategories(data.data);
    },
    onError: ({ response }) => {
      console.log(`${response?.data?.status}`);
    },
  });

  const { isLoading, refetch } = useQuery(
    "getGroupIdeas",
    () =>
      getGroupIdeas({
        groupID: parseInt(groupId),
        learningInstitutionID: parseInt(userProfile?.user?.learningInstitutionId),
        courseId: parseInt(courseId)
      }),
    {
      onSuccess: (data) => {
        saveIdeas(data); // Use the data directly
      },
      onError: (error) => {
        const { response } = error;
        if (response?.status === 401) {
          removeUser();
        } else {
          console.error("An error occurred:", error);
        }
      },
    }
  );
  return (
    <div className="relative top-[8%] md:text-[15px] px-8 md:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
      <div className="pb-[1.7rem] mb-[1rem] flex justify-between items-center border-b my-[3rem]">
        <h1 className="text-[20px] font-semibold">Group Ideas</h1>
        {src !== "lecturer" &&
          <AiOutlinePlus size={24} onClick={() => setOpenCreateModal(true)} className="cursor-pointer" />
        }
      </div>
      <div className="w-[100%] mx-auto">

        {isLoading ? <center><Scale /></center> : ideas?.data?.length < 1 ? (
          <EmptyIdea />
        ) : (
          ideas?.data?.map(
            ({ id, name, categoryId, dateAdded, progressScore }) => {
              return (
                <IdeasCard
                  key={name}
                  name={name}
                  category={categoryId}
                  dateAdded={dateAdded}
                  id={id}
                  progressScore={progressScore}
                  src
                />
              );
            }
          )
        )}
      </div>
      {openCreateModal && (
        <CreateIdea setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} refetch={refetch} />
      )}
      {openChat && <div className='fixed bottom-[90px] right-[30px]'>
        <ChatPopup />
      </div>}
      <div className='fixed bottom-[30px] right-[30px]'>
        <div className='bg-[#008FD2] w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer' onClick={() => setOpenChat((prev) => !prev)}>
          {openChat ? <MdClose color='white' size={20} /> : <MdOutlineMessage color='white' size={20} />}
        </div>
      </div>
    </div>
  )
}

export default GroupIdeaPage