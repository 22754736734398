import React from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { FormInputFields } from "./FormInputFields";
import Inputs from "../../CustomUIElements/Inputs/Inputs";
import { Link } from "react-router-dom";
import { AiFillCamera } from "react-icons/ai";

function InvestorType({
  formValues,
  setFormValues,
  investorTypeStep,
  setInvestorTypeStep,
}) {
  return (
    <div>
      {investorTypeStep === 1 && (
        <>
          <p className="mb-[3.2rem] mt-[1.8rem] text-center text-[2.4rem] text-[#103240]">
            Choose Investor type
          </p>
          <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] max-w-[26.2rem] mx-auto">
            What kind of Investor?
          </h3>
          <form className="space-y-[1.6rem] relative">
            <div
              className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer relative"
              onClick={() =>
                setFormValues({
                  ...formValues,
                  investorType: "Venture Capital",
                })
              }
            >
              <input
                type="radio"
                name="investorType"
                value="Venture Capital"
                checked={formValues?.investorType === "Venture Capital"}
                id=""
                className="w-[2.4rem] h-[2.4rem]"
                required
              />
              <label className="cursor-pointer">Venture Capital</label>
            </div>
            <div
              className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer"
              onClick={() =>
                setFormValues({
                  ...formValues,
                  investorType: "Business Angel",
                })
              }
            >
              <input
                type="radio"
                name="investorType"
                value="Business Angel"
                checked={formValues?.investorType === "Business Angel"}
                id=""
                className="w-[2.4rem] h-[2.4rem]"
                required
              />
              <label className="cursor-pointer">Business Angel</label>
            </div>
            <div
              className="flex gap-[1.6rem] items-center text-[1.6rem] text-[#103240] border rounded-[1.5rem] p-[1.6rem] border-[#D5D5D5] cursor-pointer peer"
              onClick={() =>
                setFormValues({
                  ...formValues,
                  investorType: "Private Investor",
                })
              }
            >
              <input
                type="radio"
                name="investorType"
                value="Private Investor"
                checked={formValues?.investorType === "Private Investor"}
                id=""
                className="w-[2.4rem] h-[2.4rem]"
                required
              />
              <label className="cursor-pointer">Private Investor</label>
            </div>
            <div className="!mt-20">
              <Buttons
                variant={formValues?.investorType ? "primary" : "disabled"}
                type="submit"
                text="Next"
                handleClick={(e) => {
                  e.preventDefault();
                  setInvestorTypeStep(2);
                }}
              />
            </div>
          </form>
        </>
      )}
      {investorTypeStep === 2 && (
        <>
          <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[21.2rem] mx-auto">
            Create your account
          </h3>
          <form className="space-y-[1.6rem]">
            {FormInputFields.map(({ label, name, type, isRequired }) => (
              <Inputs
                key={name}
                type={type}
                label={label}
                name={name}
                isRequired={isRequired}
                value={formValues[name]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            ))}
            <div className="flex gap-[1.2rem] text-[1.3rem] text-[#455065]">
              <input
                type="checkbox"
                className="w-[1.8rem] h-[1.8rem]"
                checked={formValues?.acceptTnC}
                onChange={() =>
                  setFormValues({
                    ...formValues,
                    acceptTnC: formValues?.acceptTnC
                      ? !formValues.acceptTnC
                      : true,
                  })
                }
              />
              <p className=" font-medium">
                By creating an account, I agree to Hydreate{" "}
                <Link to="/privacy-policy" className="text-[#008FD2]">
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link to="/terms-and-conditions" className="text-[#008FD2]">
                  Terms
                </Link>
              </p>
            </div>
            <div className="!mt-[5rem]">
              <Buttons
                variant={
                  formValues?.firstName &&
                  formValues?.lastName &&
                  formValues?.email &&
                  formValues?.phoneNumber &&
                  formValues?.password &&
                  formValues?.acceptTnC
                    ? "primary"
                    : "disabled"
                }
                type="submit"
                text="Next"
                handleClick={(e) => {
                  e.preventDefault();
                  setInvestorTypeStep(3);
                }}
              />
            </div>
          </form>
        </>
      )}
      {investorTypeStep === 3 && (
        <>
          <h3 className="mt-[1.8rem] text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[26.2rem] mx-auto">
            Tell us about Your company
          </h3>
          <form className="space-y-[1.6rem]">
            <Inputs
              type="text"
              label="Company Name"
              isRequired={true}
              name="companyName"
              value={formValues?.companyName}
              handleChange={(e) =>
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <Inputs
              type="text"
              label="Location of the company"
              isRequired={true}
              name="companyLocation"
              value={formValues?.companyLocation}
              handleChange={(e) =>
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <div className="!mt-[5rem]">
              <Buttons
                variant={
                  formValues?.companyName && formValues?.companyLocation
                    ? "primary"
                    : "disabled"
                }
                type="submit"
                text="Next"
                handleClick={(e) => {
                  e.preventDefault();
                  setInvestorTypeStep(4);
                }}
              />
            </div>
          </form>
        </>
      )}
      {investorTypeStep === 4 && (
        <>
          <h3 className="mt-[1.8rem] text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[3.2rem] w-[26.2rem] mx-auto">
            Pick a profile picture
          </h3>
          <p className="text-[#103240] text-[1.4rem] text-center mb-[3.2rem] font-medium">
            Have a favorite picture? Upload it now
          </p>
          <div className="flex justify-center relative">
            <div className="border-dashed rounded-xl border-4 border-[#008FD2] flex flex-col justify-center items-center outline-none mt-10 w-[20.2rem] h-[20.2rem] p-10 cursor-pointer hover:border-[#008FD2] hover:bg-gray-100">
              <label className="cursor-pointer">
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="flex flex-col items-center justify-center gap-[2.1rem]">
                    <p className="font-bold text-xl">
                      <AiFillCamera className="text-[#008FD2]" size={72} />
                    </p>
                    <p className="text-[#008FD2] text-[2.4rem] font-medium">
                      Upload
                    </p>
                  </div>
                </div>
                <input
                  type="file"
                  name="upload-video"
                  className="w-0 h-0"
                  // onChange={uploadVideo}
                />
              </label>
            </div>
          </div>
          <div className="!mt-[5rem] w-[30.2rem] mx-auto">
            <Buttons
              variant="primary"
              type="submit"
              text="Next"
              handleClick={(e) => {
                e.preventDefault();
                setInvestorTypeStep(4);
              }}
            />
            <p className="text-[1.6rem] font-bold text-[#008FD2] text-center cursor-pointer mt-[2.7rem]">
              Skip for now
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default InvestorType;
