import { Link } from "react-router-dom";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";

function PreFooter() {
  return (
    <section className="mt-[11.6rem] bg-[#EDF4FC] py-[4rem] md:py-[9.6rem] ">
      <div className="app-container">
        <div className="pl-5 flex flex-col justify-center items-center gap-[4.8rem]">
          <picture>
            <img src="/assets/bulb.png" alt="" />
          </picture>
          <h3 className="text-[4.2rem] md:text-[8rem] font-bold  leading-[6rem] md:leading-[10.6rem] text-center mb-[3rem]">
            Every idea needs a starting point. Start yours with Hydreate.
          </h3>
          <Link to="/get-started" className="w-[21.8rem]">
            <Buttons variant="primary" text="GET STARTED" />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default PreFooter;
