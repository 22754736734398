import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { deleteInstitution } from "../../../utils/deleteInvestor";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import AddInstitutionForm from "./AddInstitutionForm";
import { ChromePicker } from "react-color";
import { uploadFile } from "../../../utils/uploadPicture";
import apiClient from "../../../libs/http-common";
import { fetchSettingsById } from "../../../utils/fetchSettings";
import useWindowSize from "../../../hooks/useWindowSize";

function AllInstitutionTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const [showCustomize, setShowCustomize] = useState(false)
  const { removeUser } = useAuthStore();
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const [setting, setSetting] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [logo, setLogo] = useState(null)
  const [color, setColor] = useState("")
  const { mutate, isLoading: deletingUser } = useMutation(() => deleteInstitution(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("LearningInstitutions", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  useEffect(() => {
    if (rowData) {
      fetchSettingsById({ id: rowData.id }).then((res) => {
        // console.log(res.data)
        setSetting(res.data.data)
      })
    }
  }, [rowData])

  return (
    <>
      <Table
        header={[
          { name: "Institution Name", identifier: "name" },
          { name: "First Name", identifier: "firstName" },
          { name: "Last Name", identifier: "lastName" },
          { name: "Contact Email", identifier: "contactEmail" },
          // { name: "Phone Number", identifier: "contactNumber" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "disable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this institution?"
        onConfirm={mutate}
        loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "enable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this institution?"
        onConfirm={activateUser}
        loading={activatingUser}
      />
      {openModal.modalName === "edit" && openModal.showModal && (
        <ModalContainer height={width < 768 ? "70vh" :"auto"} width={width < 768 ? `95vw` : 700}>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="text-[15px] lg:text-[16px]">Edit Institution Form</h3>
              <div className="flex items-center gap-[5px]">
                <p className="underline text-[#008FD2] cursor-pointer text-[15px] lg:text-[16px]" onClick={() => setShowCustomize(!showCustomize)}>{`${showCustomize ? "Hide" : "Edit"}`} Customization</p>
                <LiaTimesSolid
                  onClick={() => {
                    setOpenModal({ modalName: "", showModal: false })
                    setSetting(null)
                    setShowCustomize(false)
                    setLogo(null)
                    setLogoUrl(null)
                    setColor("")
                  }

                  }
                  className="cursor-pointer"
                />
              </div>
            </div>
            {!showCustomize ?
              <AddInstitutionForm formValues={formValues} setFormValues={setFormValues} isLoading={isLoading} rowData={rowData} setOpenModal={() =>
                setOpenModal({ modalName: "", showModal: false })} refetch={refetch} searchQuery={searchQuery} />
              :
              <CustomizationModal setting={setting} rowData={rowData} logo={logo} setLogo={setLogo} logoUrl={logoUrl} setLogoUrl={setLogoUrl} color={color} setColor={setColor} />
            }
          </div>
        </ModalContainer>
      )}
      {openModal.modalName === "view" && openModal.showModal && (
        <ModalContainer height={width < 768 ? "70vh" :"auto"} width={700}>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Institution Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <AddInstitutionForm formValues={formValues} setFormValues={setFormValues} isLoading={isLoading} rowData={rowData} setOpenModal={() =>
              setOpenModal({ modalName: "", showModal: false })} refetch={refetch} mode="view" />
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default AllInstitutionTable;


const CustomizationModal = ({ setting, rowData, logo, setLogo, logoUrl, setLogoUrl, color, setColor }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLogoUrl(setting?.logoUrl)
    setColor(setting?.primaryColour)
  }, [setting])

  const handleSave = async () => {
    const token = window.localStorage.getItem("token");
    let l;
    setLoading(true)
    if (selectedFile) {
      await uploadFile(selectedFile, 2, ["jpg", "jpeg", "png"]).then((res) => {
        l = res?.data?.data?.path
        setLogo(res?.data?.data?.path)
        setSelectedFile(null)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error(`${err?.response?.data?.status}`)
      })
    }
    if (setting?.id) {
      await apiClient.put("/Settings/update", {
        id: setting?.id,
        learningInstitutionId: rowData?.id,
        name: "customization",
        logoUrl: l ? l : logo,
        primaryColour: color,
        secondaryColour: "string",
        description: "string",
        value: 0,
        jsonValue: "string",
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        toast.success("Customization added successfully")
      }).catch((err) => {
        if (err.status === 500) {
          toast.error(`${err?.data}`);
        }
      }).finally(() => {
        setLoading(false)
      })
    } else {
      await apiClient.post("/Settings/add", {
        name: "customization",
        learningInstitutionId: rowData?.id,
        logoUrl: l ? l : logo,
        primaryColour: color,
        secondaryColour: "string",
        description: "string",
        value: 0,
        jsonValue: "string",
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        toast.success("Customization added successfully")
      }).catch((err) => {
        if (err.status === 500) {
          toast.error(`${err?.data}`);
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }
  const handleColorChange = (color) => {
    // console.log(color)
    setColor(color.hex)
  }
  const handleFileChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    setSelectedFile(file)
    const fUrl = URL.createObjectURL(file)
    setLogoUrl(fUrl)
  }
  return (
    <div className='space-y-8 mt-[30px]'>
      <div>
        <p className='text-[16px] mb-2'>For better experience, we encourage you use an image with this dimension width - 158px, height -32px</p>
        <div className='flex items-center gap-[20px]'>
          <label htmlFor="file" className='w-[200px] rounded-md flex justify-center items-center p-4 border border-[#008FD2] text-[#008FD2] placeholder:text-[#008FD2]'>
            <input type="file" name="" id="file" className='hidden' onChange={(e) => handleFileChange(e)} />
            <p className='text-center text-[15px]'>{selectedFile ? "Logo selected" : `${logoUrl ? "Change" : "Upload"} Institution Logo`}</p>
          </label>
          {selectedFile && <p className='cursor-pointer underline text-[14px]' onClick={() => {
            setSelectedFile(null);
            setLogoUrl(null)
          }}>Remove selected Logo</p>}
        </div>
        {logoUrl && <div className='mt-4 text-[16px]'> <p>This is a preview of how your your logo will appear, if it looks okay you can save these changes.</p><img src={logoUrl} alt='Preview' width={158} height={60} className='w-[158px] h-[60px] mt-4 object-cover' /></div>}
      </div>
      <p className='text-[16px]'>Use the color tool below to select the Instituition's Primary Color</p>
      <ChromePicker
        onChange={(color) => handleColorChange(color)}
        color={color}
        disableAlpha={true} />
      <div className='w-full'>
        <Buttons text={loading ? "Saving..." : "Save Customization Changes"} variant={loading ? "disabled" : "primary"} handleClick={handleSave} />
      </div>
    </div>
  )
}