import React from "react";
import SubscriptionHome from "../../Components/AdminDashboard/subscriptions/Home";

function ManageSubscriptions() {
    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
            <SubscriptionHome />
        </div>
    );
}

export default ManageSubscriptions;
