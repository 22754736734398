import React from "react";
import LogoNavbar from "../Components/NavbarComponent/LogoNavbar";
import { Outlet } from "react-router-dom";

function PagesLayout() {
  return (
    <>
      <LogoNavbar />
      <div className=" relative my-[3.225rem] top-32 md:top-0">
        <Outlet />
      </div>
    </>
  );
}

export default PagesLayout;
