import React, { useEffect } from "react";
import NavbarComponent from "../Components/NavbarComponent/Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../store/authStore";

function HomeLayout() {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (userProfile?.token) {
  //     navigate("/dashboard");
  //   } else {
  //     navigate("/");
  //   }
  // }, [userProfile]);
  return (
    <>
      <NavbarComponent />
      <Outlet />
    </>
  );
}

export default HomeLayout;
