import React from "react";
import EmailTemplateHome from "../../Components/AdminDashboard/emailTemplates/EmailTemplateHome";
function EmailTemplates() {
    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
            <EmailTemplateHome />
        </div>
    );
}

export default EmailTemplates;
