import React, { useEffect, useState } from 'react'
import useAuthStore from '../../../store/authStore';
import useIdeaStore from '../../../store/ideaStore';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { fetchCategories } from '../../../utils/fetchCategories';
import { fetchAllIdeas } from '../../../utils/fetchAllIdeas';
import { createIdea } from '../../../utils/createIdea';
import { toast } from 'react-toastify';
import { fetchMyIdeas } from '../../../utils/fetchMyIdeas';
import Table from '../../../CustomUIElements/Table/Table';
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons';
import BorderedTextArea from '../../../CustomUIElements/Inputs/BorderTextArea';
import BorderedInputs from '../../../CustomUIElements/Inputs/BorderInputs';
import { LiaTimesSolid } from 'react-icons/lia';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import ConfirmationModal from '../../../CustomUIElements/Modal/ConfirmationModal';
import { deleteIdea } from '../../../utils/deleteIdea';
import { activateEntityStatus } from '../../../utils/activateEntityStatus';

function MyIdeasTable({ openModal, setOpenModal, searchQuery}) {
    const [formValues, setFormValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [rowData, setRowData] = useState(null);
    const [ideas, setIdeas] = useState([])


    const { userProfile, removeUser } = useAuthStore();
    const { categories, saveCategories } = useIdeaStore();
    const navigate = useNavigate()

    const { isError } = useQuery("fetchCategories", fetchCategories, {
        onSuccess: ({ data }) => {
            saveCategories(data.data);
        },
        onError: ({ response }) => {
            console.log(`${response?.data?.status}`);
        },
    });

    const { isLoading: loadingIdeas, mutate: fetchIdeas, refetch } = useQuery("fetchMyIdeas", fetchMyIdeas, {
        enabled: true,
        onSuccess: ({ data }) => {
            setIdeas(data?.data || []);
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser();
                navigate("/login")
            }
        },
    });

    const { mutate, isLoading: creatingIdea } = useMutation(createIdea, {
        onSuccess: ({ data }) => {
            toast.success(`${data.status}`);
            fetchIdeas()
        },
        onError: ({ response }) => {
            console.log(response)
            if (response.status === 500) {
                toast.error(`${response?.data}`);
            } else {
                toast.error(`${response?.statusText}`);
            }
            if (response.status === 401) {
                removeUser();
                navigate("/login");
            }
        },
    });
    const { mutate: deleteAnIdea, isLoading: deletingIdea } = useMutation(() => deleteIdea(rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Idea deleted successfully")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })
    const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Ideas", rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Operation successful")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            name: formValues?.name,
            description: formValues?.description,
            categoryId: formValues?.categoryId,
            groupId: 0,
        });
    };

    useEffect(() => {
        if (rowData) {
            setFormValues({
                name: rowData.name || "",
                description: rowData.description || "",
                categoryId: rowData.categoryId || "",
            });
        }
    }, [rowData]);

    return (
        <div>
            <Table data={ideas} header={[
                { name: "Title", identifier: "name" },
                { name: "Description", identifier: "description" },
            ]} options={{
                variant: "primary",
                tableStyle: "striped-even",
                allowActions: true,
                allowSorting: true,
                actions: {
                    edit: true,
                    delete: false,
                    view: true,
                },
                showPagination: true,
            }}
                openModal={openModal}
                setOpenModal={setOpenModal}
                rowData={rowData}
                setRowData={setRowData}
                loading={loadingIdeas}
                searchQuery={searchQuery}
            />
            <ConfirmationModal
                showModal={openModal.modalName === "disable" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to delete this idea?"
                onConfirm={() => deleteAnIdea()}
                loading={deletingIdea}
            />
            <ConfirmationModal
                showModal={openModal.modalName === "enable" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this idea?"
                loading={activatingUser}
                onConfirm={() => activateUser()}
            />
            {openModal.modalName === "add" && openModal.showModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Add New Idea</h3>
                            <LiaTimesSolid
                                onClick={() => setOpenModal(false)}
                                className="cursor-pointer"
                            />
                        </div>
                        <form className="space-y-[2rem]" onSubmit={handleSubmit}>
                            <BorderedInputs
                                label="Name of Idea"
                                isRequired
                                value={formValues?.name}
                                handleChange={(e) =>
                                    setFormValues({ ...formValues, name: e.target.value })
                                }
                            />
                            <BorderedTextArea
                                label="Write a short description"
                                value={formValues?.description}
                                handleChange={(e) => {
                                    setFormValues({ ...formValues, description: e.target.value });
                                    setWordCount(e.target.value?.length);
                                }}
                                maxWordCount
                            />
                            <p className="!ml-auto !mt-[-.2rem] text-right text-[#103240B2] font-medium">
                                {wordCount}/150
                            </p>
                            <select className="w-full bg-transparent border-b border-0 outline-0 rounded outline-none border-[#F7F7F7] py-[.5rem] text-[16px] leading-[2.4rem]" value={formValues?.categoryId} onChange={(e) => setFormValues({ ...formValues, categoryId: e.target.value })} >
                                <option value="" className="">Select a category</option>
                                {categories?.map((c) => (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                ))}
                            </select>
                            <div className="!mt-[3rem]">
                                <Buttons
                                    variant={
                                        formValues?.name &&
                                            formValues?.description &&
                                            formValues?.categoryId &&
                                            !creatingIdea
                                            ? "primary"
                                            : "disabled"
                                    }
                                    type="submit"
                                    text={creatingIdea ? "Submitting..." : "Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            )}
            {openModal.modalName === "edit" && openModal.showModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Edit My Idea</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({ modalName: "", showModal: false })
                                }
                            />
                        </div>
                        <form className="space-y-[2rem]" onSubmit={handleSubmit}>
                            <BorderedInputs
                                label="Name of Idea"
                                isRequired
                                value={formValues?.name}
                                handleChange={(e) =>
                                    setFormValues({ ...formValues, name: e.target.value })
                                }
                            />
                            <BorderedTextArea
                                label="Write a short description"
                                value={formValues?.description}
                                handleChange={(e) => {
                                    setFormValues({ ...formValues, description: e.target.value });
                                    setWordCount(e.target.value?.length);
                                }}
                                maxWordCount
                            />
                            <p className="!ml-auto !mt-[-.2rem] text-right text-[#103240B2] font-medium">
                                {wordCount}/150
                            </p>
                            <select className="w-full bg-transparent border-b border-0 outline-0 rounded outline-none border-[#F7F7F7] py-[.5rem] text-[16px] leading-[2.4rem]" value={formValues?.categoryId} onChange={(e) => setFormValues({ ...formValues, categoryId: e.target.value })} >
                                <option value="" className="">Select a category</option>
                                {categories?.map((c) => (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                ))}
                            </select>
                            <div className="!mt-[3rem]">
                                <Buttons
                                    variant={
                                        formValues?.name &&
                                            formValues?.description &&
                                            formValues?.categoryId &&
                                            !creatingIdea
                                            ? "primary"
                                            : "disabled"
                                    }
                                    type="submit"
                                    text={creatingIdea ? "Submitting..." : "Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            )}
            {openModal.modalName === "view" && openModal.showModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">View Idea</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({ modalName: "", showModal: false })
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="space-y-[1.6rem]">
                            <div className="flex flex-col gap-2">
                                <label className="text-[1.5rem] font-medium">Idea Name</label>
                                <input type="text" readOnly value={rowData?.name} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label className="text-[1.5rem] font-medium">Idea Description</label>
                                <input type="text" readOnly value={rowData?.description} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>
    )
}

export default MyIdeasTable