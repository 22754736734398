import apiClient from "../libs/http-common";
export const removeStudentFromGroupp = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/removeStudentFromGroupp/delete",
    method: "post",
    data: { data },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};