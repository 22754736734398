import React from "react";
import { FaNotesMedical } from "react-icons/fa";

function AddNote({ setOpenNoteModal }) {
  return (
    <div
      className="my-[1.4rem] w-[18.1rem] h-[27.6rem] bg-[#008FD212] flex justify-center items-center cursor-pointer rounded-[1.2rem] border border-[#E4E4E4]"
      onClick={() => setOpenNoteModal(true)}
    >
      <span className="flex flex-col justify-center items-center text-[#008FD2]">
        <div className="bg-[#008FD2] rounded-full w-[6rem] h-[6rem] flex flex-col justify-center items-center mb-[1.6rem]">
          <FaNotesMedical size={32} color="white" />
        </div>
        <p className="text-[1.6rem] font-semibold">Create new note</p>
      </span>
    </div>
  );
}

export default AddNote;
