import { BiCategory, BiHome } from "react-icons/bi";
import { BsQuestionSquare } from "react-icons/bs";
import { HiMiniBriefcase, HiOutlineLightBulb } from "react-icons/hi2";
import { LuUsers } from "react-icons/lu";
import {
  IoFunnelOutline,
  IoSettingsOutline,
  IoPricetagOutline,
} from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { PiStudentBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { FcInvite } from "react-icons/fc";
import { LiaBookSolid } from "react-icons/lia";
import { RiSchoolLine } from "react-icons/ri";

export const AdminLinks = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "Manage Users",
    url: "/manage-users",
    icon: <LuUsers size={20} />,
  },
  {
    name: "Manage Investors",
    url: "/manage-investors",
    icon: <HiMiniBriefcase size={20} />,
  },
  {
    name: "Manage Institutions",
    url: "/manage-institutions",
    icon: <RiSchoolLine size={20} />,
  },
  {
    name: "My Ideas",
    url: "/manage-my-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Ideas",
    url: "/manage-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Categories",
    url: "/manage-categories",
    icon: <BiCategory size={20} />,
  },
  {
    name: "Manage Questions",
    url: "/manage-questions",
    icon: <BsQuestionSquare size={20} />,
  },
  {
    name: "Manage Funnels",
    url: "/manage-funnels",
    icon: <IoFunnelOutline size={20} />,
  },
  {
    name: "Manage Subscriptions",
    url: "/manage-subscriptions",
    icon: <IoPricetagOutline size={20} />,
  },
  {
    name: "Manage Settings",
    url: "/manage-settings",
    icon: <IoSettingsOutline size={20} />,
  },
  {
    name: "Email Templates",
    url: "/email-templates",
    icon: <CiMail size={20} />,
  },
];

export const InvestorLink = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "My Ideas",
    url: "/manage-my-ideas",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "Manage Interests",
    url: "/manage-interests",
    icon: <HiOutlineLightBulb size={20} />,
  },
  {
    name: "My Categories",
    url: "/manage-my-categories",
    icon: <HiOutlineLightBulb size={20} />,
  },
];

export const LiLink = [
  {
    name: "My Institution",
    url: "/li/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "My Invites",
    url: "/li/dashboard/my-invites",
    icon: <FcInvite size={20} />,
  },
  {
    name: "My Courses",
    url: "/li/dashboard/my-courses",
    icon: <LiaBookSolid size={20} />,
  },
  {
    name: "My Lecturers",
    url: "/li/dashboard/my-lecturers",
    icon: <GiTeacher size={20} />,
  },
  {
    name: "My Students",
    url: "/li/dashboard/my-students",
    icon: <PiStudentBold size={20} />,
  },
  {
    name: "Settings",
    url: "/li/dashboard/settings",
    icon: <IoSettingsOutline size={20} />,
  },
];

export const StuLink = [
  {
    name: "My Profile",
    url: "/student/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "My Courses",
    url: "/student/dashboard/my-courses",
    icon: <LiaBookSolid size={20} />,
  },
  {
    name: "Groups",
    url: "/student/dashboard/groups",
    icon: <GiTeacher size={20} />,
  },
  // {
  //   name: "Group Ideas",
  //   url: "/student/dashboard/group-ideas",
  //   icon: <HiOutlineLightBulb size={20} />,
  // },
];

export const LectLink = [
  {
    name: "Dashboard",
    url: "/lecturer/dashboard",
    icon: <BiHome size={20} />,
  },
  {
    name: "My Invites",
    url: "/lecturer/dashboard/my-invites",
    icon: <FcInvite size={20} />,
  },
  {
    name: "My Courses",
    url: "/lecturer/dashboard/my-courses",
    icon: <LiaBookSolid size={20} />,
  },
  {
    name: "My Students",
    url: "/lecturer/dashboard/my-students",
    icon: <PiStudentBold size={20} />,
  },
  {
    name: "Groups",
    url: "/lecturer/dashboard/groups",
    icon: <GiTeacher size={20} />,
  },
];
