import React from "react";
import { GrClose } from "react-icons/gr";
import { VscCopy } from "react-icons/vsc";
import { TbMail } from "react-icons/tb";

function ShareModal({ setOpenShareModal }) {
  return (
    <div>
      <div className="p-[2.4rem] pb-[2rem] flex justify-between items-center ">
        <h3 className="text-[2.4rem] text-[#103240] font-semibold">
          Share this idea
        </h3>
        <span>
          <GrClose
            size={15}
            className="font-semibold cursor-pointer"
            onClick={() => setOpenShareModal(false)}
          />
        </span>
      </div>
      <p className="mb-[2.4rem] text-[1.6rem] text-[#008FD2]">This feature is coming soon</p>
      <div className="px-[2.4rem] grid grid-cols-2 gap-[1.6rem]">
        {shareOptions.map((option) => (
          <div className="flex items-center gap-[1.6rem] border border-[#E4E4E4] p-[1.6rem] rounded-[1.2rem] cursor-not-allowed bg-black bg-opacity-10">
            <img src={option.icon} alt="" />
            <p className="font-semibold text-[1.6rem] text-[#103240]">
              {option.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShareModal;

const shareOptions = [
  {
    name: "Copy Link",
    icon: "/assets/Copy.svg",
  },
  {
    name: "Email",
    icon: "/assets/Mail.svg",
  },
  {
    name: "Messages",
    icon: "/assets/messages.svg",
  },
  {
    name: "Whatsapp",
    icon: "/assets/whatsapp.svg",
  },
  {
    name: "Messenger",
    icon: "/assets/Messanger.svg",
  },
  {
    name: "Facebook",
    icon: "/assets/facebook.svg",
  },
  {
    name: "Twitter",
    icon: "/assets/twitter.svg",
  },
];
