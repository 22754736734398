import React from "react";

function ModalContainer({ children, height, width }) {
  return (
    <div className="fixed bg-[#2A2E4398] inset-0 h-screen flex justify-center items-center z-50">
      <div className="relative">
        <div className=" bg-white w-[95vw] lg:w-[41.2rem] h-[90vh] lg:h-[560px] rounded-xl  relative overflow-y-auto scrollbar-hide mx-auto" style={{ height, width: width && width }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalContainer;
