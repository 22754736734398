import React from "react";
import InvestorsAdministration from "../../Components/AdminDashboard/Investors/InvestorsAdministration";

function ManageInvestors() {
  return (
    <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
      <InvestorsAdministration />
    </div>
  );
}

export default ManageInvestors;
