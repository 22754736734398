import React from "react";
import { GrClose } from "react-icons/gr";
import { TfiSearch } from "react-icons/tfi";
import useIdeaStore from "../../store/ideaStore";
import { BsCheck2 } from "react-icons/bs";
import { Buttons } from "../Buttons/Buttons";

function CategoryModal({ setOpenModal, setAddIdea, addIdea }) {
  const { categories } = useIdeaStore();
  return (
    <>
      <>
        <div className="flex justify-between items-center relative p-[1.6rem]">
          <h3 className="text-[#103240] font-bold text-[2.4rem]">
            Choose Category
          </h3>
          <span className="cursor-pointer" onClick={() => setOpenModal(false)}>
            <GrClose size={15} className="font-semibold" />
          </span>
        </div>
        <div className="mt-[2rem] relative px-[1.6rem]">
          <input
            type="search"
            name=""
            id=""
            className="bg-[#F7F7F7] px-[2rem] py-[1.4rem] w-full outline-none rounded-lg relative pl-[3.5rem] text-[1.4rem]"
            placeholder="Search Category"
          />
          <span className="absolute left-10 top-7">
            <TfiSearch size={15} />
          </span>
        </div>
        <div className="mt-[1.6rem] h-[70%] overflow-y-auto relative">
          <ul className="space-y-[1rem]">
            {categories &&
              categories.map((category) => (
                <div
                  className="flex justify-between items-center hover:bg-[#F7F7F7] px-[1.6rem] cursor-pointer "
                  onClick={() => {
                    // setSelectedCategory(category?.name);
                    setAddIdea({
                      ...addIdea,
                      categoryId: category?.id,
                      categoryName: category?.name,
                    });
                    setOpenModal(false)
                  }}
                >
                  <li
                    key={category?.id}
                    className="text-[#103240] text-[1.8rem] font-medium  py-[1rem] hover:bg-[#F7F7F7]"
                  >
                    {category?.name}
                  </li>
                  {addIdea?.categoryName === category?.name && (
                    <BsCheck2 size={20} color="#008FD2" />
                  )}
                </div>
              ))}
          </ul>
        </div>
      </>
      <div className="absolute bottom-0 bg-[#FAFBFB] w-full h-[5.9rem] rounded-b-xl flex items-center justify-end">
        <div className="mr-[1.6rem] " onClick={() => setOpenModal(false)}>
          <Buttons text="Done" variant="black" />
        </div>
      </div>
    </>
  );
}

export default CategoryModal;
