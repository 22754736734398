import React from 'react'
import UsersAdministration from '../../Components/AdminDashboard/Users/UsersAdministration'

function ManageUsers() {
  return (
    <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
        <UsersAdministration/>
    </div>
  )
}

export default ManageUsers