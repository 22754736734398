import React from "react";

function Contact() {
  return (
    <section
      className="py-[11.6rem] rounded-[5rem] flex flex-col justify-center items-center bg-white"
      id="contact"
    >
      <p className="max-w-[76.8rem] text-[1.7rem] font-medium mx-auto ">Process R Us<a href="https://www.processrus.com" className="underline"> (https://www.processrus.com/)</a> is part of:</p>
      <div className="flex flex-col lg:flex-row items-center gap-[32px]">
        <picture className="mb-[2rem] block">
          <img src="/assets/microsoft.jpeg" alt="" width={300} />
        </picture>
        <picture className="mb-[2rem] block">
          <img src="/assets/aws-logo.webp" alt="" width={300} />
        </picture>
      </div>
      <div className="flex flex-col justify-center items-center w-[40rem]">
        <h3 className="text-[5rem] font-bold mb-[2rem]">Contact Us</h3>
        <p className="text-[2.4rem] leading-[3.36rem] font-medium text-center">
          Reach out to us at{" "}
          <a href="mailto:hello@processrus.com" className="underline">
            {" "}
            hello@processrus.com
          </a>
        </p>
      </div>
    </section>
  );
}

export default Contact;
