// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const updateSetting = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.put("/Settings/update", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSetting = async () => {
  const token = window.localStorage.getItem("token");
  return await apiClient.get("/Settings/get", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
