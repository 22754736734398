function PrivacyPolicy() {
  return (
    <section className="app-container">
      <div className=" mt-[4rem] pl-5 flex flex-col gap-[1.6rem]">
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">Privacy Policy</h3>
          <p className="text-[1.6rem]">
            This privacy policy sets out how we use and protect any information
            that you give us when you use this website. We are committed to
            ensuring that your privacy is protected. Should we ask you to
            provide certain information by which you can be identified when
            using this website, then you can be assured that it will only be
            used in accordance with this privacy statement. We may change this
            policy from time to time by updating this page. You should check
            this page from time to time to ensure that you are happy with any
            changes. This policy is effective from 1st April 2024.
          </p>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">What we collect</h3>
          <p className="text-[1.6rem]">We may collect the following information:</p>
          <ul className="list-disc">
            <li className="ml-8 mb-[1rem] text-[1.6rem]">Name</li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">Email address</li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">Website URL for Investors</li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">
              Phone Number
            </li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">
              Other information relevant to customer surveys and/or offers
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">
            What we do with the information we gather
          </h3>
          <p className="text-[1.6rem]">
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p>
          <ul className="list-disc">
            <li className="ml-8 mb-[1rem] text-[1.6rem]">Internal record keeping.</li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">
              We may use the information to improve our products and services.
            </li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">
              We may periodically send promotional emails about new products,
              special offers or other information which we think you may find
              interesting using the email address which you have provided.
            </li>
            <li className="ml-8 mb-[1rem] text-[1.6rem]">
              From time to time, we may also use your information to contact you
              for market research purposes. We may contact you by email, phone,
              fax or mail. We may use the information to customise the website
              according to your interests.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">Security</h3>
          <p className="text-[1.6rem]">
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">How we use cookies</h3>
          <p className="text-[1.6rem]">
            A cookie is a small file which asks permission to be placed on your
            computer's hard drive. Once you agree, the file is added and the
            cookie helps analyse web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>
          <p className="text-[1.6rem]">
            We may use traffic log cookies to identify which pages are being
            used. This helps us analyse data about webpage traffic and improve
            our website in order to tailor it to customer needs. We only use
            this information for statistical analysis purposes and then the data
            is removed from the system.
          </p>
          <p className="text-[1.6rem]">
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">
            Links to other websites
          </h3>
          <p className="text-[1.6rem]">
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>
        </div>
        <div className="flex flex-col gap-[1.6rem]">
          <h3 className="text-[3rem] text-extrabold">
            Controlling your personal information
          </h3>
          <p className="text-[1.6rem]">
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law.
            We may use your personal information to send you promotional
            information about third parties which we think you may find
            interesting if you tell us that you wish this to happen.
          </p>
          <p className="text-[1.6rem]">
            You may request details of personal information which we hold about
            you under the Data Protection Act 1998. A small fee will be payable.
            If you would like a copy of the information held on you please fill
            our contact us form.
          </p>
          <p className="text-[1.6rem]">
            If you believe that any information we are holding on you is
            incorrect or incomplete, please email us as soon as possible, at the
            above address. We will promptly correct any information found to be
            incorrect.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
