import React, { useState } from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
import ModalContainer from "../../../../CustomUIElements/Modal/ModalContainer";
import ShareModal from "../../../../CustomUIElements/Modal/ShareModal";

function EmptyShare() {
  const [openShareModal, setOpenShareModal] = useState(false);
  return (
    <div className="w-[14.2rem] mx-auto text-center h-[27.6rem] flex flex-col justify-center items-center gap-[2.4rem]">
      <p className="text-[#455065B4] text-[1.4rem]">
        You haven’t shared this idea with anyone
      </p>
      <div className="flex items-center gap-[.8rem] text-[#008FD2] text-[1.6rem] font-semibold cursor-pointer" onClick={()=>setOpenShareModal(true)}>
        <AiOutlineShareAlt size={20} />
        <p className="">Share Idea</p>
      </div>
      {openShareModal && (
        <ModalContainer>
          <ShareModal setOpenShareModal={setOpenShareModal}/>
        </ModalContainer>
      )}
    </div>
  );
}

export default EmptyShare;
