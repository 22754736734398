import React, { useEffect } from "react";
import DashBoard from "../../Components/Dashboard/DashBoard";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";

function DashboardHome() {
  const { userProfile } = useAuthStore();
  console.log('', userProfile);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userProfile?.token) return navigate("/login");
  }, [userProfile]);
  return (
    <>
      <DashBoard />
    </>
  );
}

export default DashboardHome;
