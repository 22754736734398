import React from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { useNavigate } from "react-router-dom";

function DashboardSuccess() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center h-screen items-center relative z-[70] bg-white">
      <div className="w-[30.2rem] mx-auto">
        <img src="/assets/idea-success.svg" alt="" srcset="" />
        <div className="flex flex-col justify-center items-center mt-[4.8rem]">
          <h3 className="text-[#103240] text-[3.2rem] mb-[.8rem]">
            Entry Successful
          </h3>
          <p className="text-[#103240] text-[1.3rem] text-center">
            Your idea has been successfully entered. You can proceed back
            home to continue
          </p>
          <div className="mt-[3.5rem] w-full">
            <Buttons
              text="Go Home"
              variant="primary"
              handleClick={() => (window.location.pathname = "/dashboard")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSuccess;
