import React from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "../Components/NavbarComponent/DashboardNavbar";

function DashboardLayout() {
  const paths = ["/dashboard/success"];
  return (
    <>
      {!paths.includes(window.location.pathname) && <DashboardNavbar />}
      <Outlet />
    </>
  );
}

export default DashboardLayout;
