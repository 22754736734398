import React, { useEffect } from "react";
import IdeasPage from "../../Components/Ideas/IdeasPage/IdeasPage";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";

function AllIdeasPage() {
  const { userProfile } = useAuthStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userProfile?.token) return navigate("/login");
  }, [userProfile]);
  return (
    <>
      <IdeasPage />
    </>
  );
}

export default AllIdeasPage;
