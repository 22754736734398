import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
// import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { deleteInvestor } from "../../../utils/deleteInvestor";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { deleteFunnel } from "../../../utils/deleteFunnel";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { updateFunnel } from "../../../utils/updateFunnel";
import { updateSetting } from "../../../utils/updateSetting";
import { deleteSetting } from "../../../utils/deleteSetting";

function SettingsTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [rowData, setRowData] = useState(null);
  const [formValues, setFormValues] = useState({
    settingName: "",
    settingDesc: "",
    settingCode: "",
    settingVal: "",
    jsonValue: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const { removeUser } = useAuthStore();
  const navigate = useNavigate()

  useEffect(() => {
    if (rowData) {
      setFormValues({
        settingName: rowData.name || "",
        settingDesc: rowData.description || "",
        settingCode: rowData.code || "",
        settingVal: rowData.value || "",
        jsonValue: rowData.jsonValue || "",
      });
    }
  }, [rowData]);

  const { mutate, isLoading: deletingFunnel } = useMutation(() => deleteSetting(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  const { mutate: updateFunnelFn, isLoading: isUpdating } = useMutation(updateSetting, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal()
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });

  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Settings", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    updateFunnelFn({
      name: formValues?.settingName,
      description: formValues?.settingDesc,
      id: rowData?.id,
      code: formValues?.settingCode,
      value: parseInt(formValues?.settingVal),
      jsonValue: formValues?.jsonValue

    });
  };

  return (
    <>
      <Table
        header={[
          { name: "Name of Setting", identifier: "name" },
          { name: "Description of Setting", identifier: "description" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "disable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this setting?"
        onConfirm={mutate}
        loading={deletingFunnel}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "enable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this setting?"
        onConfirm={activateUser}
        loading={activatingUser}
      />
      {openModal?.modalName === "edit" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit Setting</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"settingName"}
                type={"text"}
                label={"Name of Setting"}
                name={"settingName"}
                isRequired={true}
                value={formValues["settingName"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <Inputs
                key={"settingDesc"}
                type={"text"}
                label={"Description of Setting"}
                name={"settingDesc"}
                isRequired={true}
                value={formValues["settingDesc"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <Inputs
                key={"settingCode"}
                type={"text"}
                label={"Code"}
                name={"settingCode"}
                isRequired={true}
                value={formValues["settingCode"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <Inputs
                key={"settingVal"}
                type={"text"}
                label={"Value of Setting"}
                name={"settingVal"}
                isRequired={true}
                value={formValues["settingVal"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <textarea className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem] resize-none" placeholder='JSON Value' value={formValues?.jsonValue} name='jsonValue' onChange={(e) => {
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                })
              }} />
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.settingName &&
                      formValues?.settingDesc &&
                      formValues?.settingVal &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isUpdating ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}

      {openModal?.modalName === "view" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Setting Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"settingName"}
                type={"text"}
                label={"Name of Setting"}
                name={"settingName"}
                isRequired={true}
                value={formValues["settingName"]}
                readOnly
              />
              <Inputs
                key={"settingDesc"}
                type={"text"}
                label={"Description of Setting"}
                name={"settingDesc"}
                isRequired={true}
                value={formValues["settingDesc"]}
                readOnly
              />
              <Inputs
                key={"settingCode"}
                type={"text"}
                label={"Code"}
                name={"settingCode"}
                isRequired={true}
                value={formValues["settingCode"]}
                readOnly
              />
              <Inputs
                key={"settingVal"}
                type={"text"}
                label={"Value of Setting"}
                name={"settingVal"}
                isRequired={true}
                value={formValues["settingVal"]}
                readOnly
              />
              <textarea className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem] resize-none" placeholder='JSON Value' value={formValues?.jsonValue} name='jsonValue' readOnly />
            </form>
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default SettingsTable;
