import React from "react";
import EmptyShare from "./EmptyShare";

function ShareSection({ src }) {
  return (
    <div className="w-full md:w-[32%]">
      <div className="flex justify-between items-center w-full">
        <p className="text-[#103240] text-[1.6rem] font-semibold">
          Shared With
        </p>
        <p className="text-[#103240B2] text-[1.4rem] cursor-pointer">See all</p>
      </div>
      <div>
        {src !== "lecturer" &&
          <EmptyShare />
        }
      </div>
    </div>
  );
}

export default ShareSection;
