import React from 'react'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons';
import Inputs from '../../CustomUIElements/Inputs/Inputs';
import { FormInputFields } from './FormInputFields';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillCamera } from 'react-icons/ai';
import useAuthStore from '../../store/authStore';
import { useMutation } from 'react-query';
import { createUserAccount } from '../../utils/userAccountFn';
import { toast } from 'react-toastify';

function LiType({ formValues,
    setFormValues,
    investorTypeStep,
    setInvestorTypeStep }) {
    const navigate = useNavigate();
    const { saveUserProfile } = useAuthStore();
    const { mutate, isLoading } = useMutation(createUserAccount, {
        onSuccess: ({ data }) => {
            saveUserProfile(data.data);
            navigate("/login");
            toast.success(`${data.status}`);
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            organisationName: formValues?.institutionName,
            firstName: formValues?.firstName,
            lastName: formValues?.lastName,
            emailAddress: formValues?.contactEmail,
            mobileNumber: formValues?.contactNumber,
            password: formValues?.password,
            confirmPassword: formValues?.confirmPassword,
            userRoleEnum: "LearningInstitution",
            address: formValues?.institutionAddress
        });
    };
    return (
        <div>
            {investorTypeStep === 1 && (
                <>
                    <p className="mb-[3.2rem] mt-[1.8rem] text-center text-[2.4rem] text-[#103240]">
                        Name of Institution
                    </p>
                    <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] max-w-[36.2rem] mx-auto">
                        Let's Know Your Institution Name
                    </h3>
                    <form className="space-y-[1.6rem] relative" //onSubmit={(e) => handleSubmit(e)}
                    >
                        <div>
                            <Inputs
                                name="institutionName"
                                label="Institution Name"
                                isRequired={true}
                                value={formValues?.institutionName}
                                type="text"
                                handleChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Check if the input value matches the pattern or if it's an empty string
                                    if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                        setFormValues({
                                            ...formValues,
                                            institutionName: inputValue,
                                        });
                                    }
                                }}
                                minLength={1}
                            />
                        </div>
                        <div className="!mt-20">
                            <Buttons
                                variant={formValues?.institutionName ? "primary" : "disabled"}
                                type="submit"
                                text="Next"
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setInvestorTypeStep(2);
                                }}
                            />
                        </div>
                    </form>
                </>
            )}
            {investorTypeStep === 2 && (
                <>
                    <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[31.2rem] mx-auto">
                        Tell us about You
                    </h3>
                    <form className="space-y-[1.6rem]">
                        <Inputs
                            name="firstName"
                            label="Institution Contact First Name"
                            isRequired={true}
                            value={formValues?.firstName}
                            type="text"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        firstName: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <Inputs
                            name="lastName"
                            label="Institution Contact Last Name"
                            isRequired={true}
                            value={formValues?.lastName}
                            type="text"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        lastName: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <Inputs
                            name="institutionAddress"
                            label="Institution Address"
                            isRequired={true}
                            value={formValues?.institutionAddress}
                            type="text"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        institutionAddress: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <Inputs
                            name="contactNumber"
                            label="Institution Contact Number"
                            isRequired={true}
                            value={formValues?.contactNumber}
                            type="text"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        contactNumber: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <Inputs
                            name="contactEmail"
                            label="Institution Contact Email"
                            isRequired={true}
                            value={formValues?.contactEmail}
                            type="email"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        contactEmail: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <div className="!mt-[5rem]">
                            <Buttons
                                variant={
                                    formValues?.institutionAddress &&
                                        formValues?.firstName &&
                                        formValues?.lastName &&
                                        formValues?.contactNumber &&
                                        formValues?.contactEmail
                                        ? "primary"
                                        : "disabled"
                                }
                                type="submit"
                                text="Next"
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setInvestorTypeStep(3);
                                }}
                            />
                        </div>
                    </form>
                </>
            )}
            {investorTypeStep === 3 && (
                <>
                    <h3 className="mt-[1.8rem] text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[26.2rem] mx-auto">
                        Let's Secure your account
                    </h3>
                    <form className="space-y-[1.6rem]">
                        <Inputs
                            name="password"
                            label="Password"
                            isRequired={true}
                            value={formValues?.password}
                            type="password"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        password: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <Inputs
                            name="confirmPassword"
                            label="Confirm Password"
                            isRequired={true}
                            value={formValues?.confirmPassword}
                            type="password"
                            handleChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input value matches the pattern or if it's an empty string
                                if (/^[^\s].*$/.test(inputValue) || inputValue === '') {
                                    setFormValues({
                                        ...formValues,
                                        confirmPassword: inputValue,
                                    });
                                }
                            }}
                            minLength={1}
                        />
                        <div className="flex gap-[1.2rem] text-[1.3rem] text-[#455065]">
                            <input
                                type="checkbox"
                                className="w-[1.8rem] h-[1.8rem]"
                                checked={formValues?.acceptTnC}
                                onChange={() =>
                                    setFormValues({
                                        ...formValues,
                                        acceptTnC: formValues?.acceptTnC
                                            ? !formValues.acceptTnC
                                            : true,
                                    })
                                }
                            />
                            <p className=" font-medium">
                                By creating an account, I agree to Hydreate{" "}
                                <Link to="/privacy-policy" className="text-[#008FD2]">
                                    Privacy Policy
                                </Link>{" "}
                                and{" "}
                                <Link to="/terms-and-conditions" className="text-[#008FD2]">
                                    Terms
                                </Link>
                            </p>
                        </div>
                        <div className="!mt-[5rem]">
                            <Buttons
                                variant={
                                    formValues?.password &&
                                        formValues?.confirmPassword &&
                                        formValues?.acceptTnC && !isLoading
                                        ? "primary"
                                        : "disabled"
                                }
                                type="submit"
                                text={isLoading ? "Submitting..." : "Register"}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}
                            />
                        </div>

                    </form>
                </>
            )}
            {investorTypeStep === 4 && (
                <>
                    <h3 className="mt-[1.8rem] text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[3.2rem] w-[36.2rem] mx-auto">
                        Pick an institution picture
                    </h3>
                    <p className="text-[#103240] text-[1.4rem] text-center mb-[3.2rem] font-medium">
                        Have a favorite picture? Upload it now
                    </p>
                    <div className="flex justify-center relative">
                        <div className="border-dashed rounded-xl border-4 border-[#008FD2] flex flex-col justify-center items-center outline-none mt-10 w-[20.2rem] h-[20.2rem] p-10 cursor-pointer hover:border-[#008FD2] hover:bg-gray-100">
                            <label className="cursor-pointer">
                                <div className="flex flex-col items-center justify-center h-full">
                                    <div className="flex flex-col items-center justify-center gap-[2.1rem]">
                                        <p className="font-bold text-xl">
                                            <AiFillCamera className="text-[#008FD2]" size={72} />
                                        </p>
                                        <p className="text-[#008FD2] text-[2.4rem] font-medium">
                                            Upload
                                        </p>
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    name="upload-video"
                                    className="w-0 h-0"
                                // onChange={uploadVideo}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="!mt-[5rem] w-[30.2rem] mx-auto">
                        <Buttons
                            variant="primary"
                            type="submit"
                            text="Next"
                            handleClick={(e) => {
                                e.preventDefault();
                                setInvestorTypeStep(4);
                            }}
                        />
                        <p className="text-[1.6rem] font-bold text-[#008FD2] text-center cursor-pointer mt-[2.7rem]">
                            Skip for now
                        </p>
                    </div>
                </>
            )}
        </div>
    )
}

export default LiType