// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const generateBusinessPlan = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Ideas/generateBusinessPlan", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
