import { useTheme } from "../../context/ThemeContext";

export function Buttons({ type, text, variant, handleClick }) {
  const { theme } = useTheme()
  return (
    <button
      type={type}
      disabled={variant === "disabled"}
      className={`${variant === "primary"
        ? "bg-[#008FD2] !text-white"
        : variant === "black"
          ? "!bg-black !text-white"
          : variant === "cancel"
            ? "!bg-[#fff] !text-[#103240] !border !border-[#E4E4E4]"
            : variant === "disabled" && "!bg-[#E4E4E4] !text-white"
        } py-[1rem] px-[1.4rem] rounded-[12px] font-medium lg:text-[1.6rem] block w-full text-[1.6rem]`}
      onClick={handleClick}
      style={{ backgroundColor: theme.backgroundColor && variant === "primary" && theme?.backgroundColor, color: theme.color }}
    >
      {text}
    </button>
  );
}
