import React, { useState } from "react";
import useAuthStore from "../../store/authStore";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import ModalContainer from "../../CustomUIElements/Modal/ModalContainer";
import { GrClose } from "react-icons/gr";
import Inputs from "../../CustomUIElements/Inputs/Inputs";
import apiClient from "../../libs/http-common"
import { toast } from "react-toastify";

function SecurityAndPrivacy({ setOpenSettings }) {
  const { userProfile } = useAuthStore();
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    if (!password || !confirmPassword) return toast.error("One field is empty")
    const token = window.localStorage.getItem("token");
    await apiClient.post("/Accounts/userResetPassword", { password, confirmPassword }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    }).then((res) => {
      toast.success(`${res?.data?.status}`)
      setPassword("")
      setConfirmPassword("")
      setOpenModal(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className="w-full">
      <h4 className="text-[1.8rem] font-semibold mb-[1.6rem] text-[#103240]">
        Security and Privacy
      </h4>
      <div className="flex flex-col w-full mb-[1.6rem]">
        <label htmlFor="" className="text-[#103240] text-[1.6rem] mb-[.8rem]">
          Email
        </label>
        <input
          type="text"
          className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
          value={userProfile?.user?.email}
        />
      </div>
      <div className="flex flex-col w-full mb-[1.6rem]">
        <label htmlFor="" className="text-[#103240] text-[1.6rem] mb-[.8rem]">
          Password
        </label>
        <div className="w-[17rem]" onClick={() => setOpenModal(true)}>
          <Buttons text="Change password" variant="cancel" />
        </div>
        {/* <input
          type="text"
          className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
          value={userProfile?.user?.email}
        /> */}
      </div>
      <div className="flex justify-end items-center w-[29.2rem] ml-auto gap-[1.6rem] mt-[4rem]">
        <Buttons variant="cancel" text="Cancel" handleClick={() => setOpenSettings(false)} />
        <Buttons variant="primary" text="Save" />
      </div>
      {openModal && (
        <ModalContainer height="320px">
          <div className="p-[1.9rem] py-[1.6rem] flex justify-between items-center mb-[1.5rem]">
            <h2 className="text-[#103240] text-[2.4rem] font-medium">
              Change Password
            </h2>
            <GrClose
              size={15}
              className="font-semibold cursor-pointer"
              onClick={() => {
                setOpenModal(false)
                setConfirmPassword("")
                setPassword("")
              }}
            />
          </div>
          <form onSubmit={handlePasswordReset} >
            <div className="p-[1.9rem] py-[1.6rem] space-y-[1.6rem]">
              <Inputs label="New Password" value={password} handleChange={(e) => setPassword(e.target.value)} type="password" isRequired={true} />
              <Inputs label="Confirm Password" value={confirmPassword} handleChange={(e) => setConfirmPassword(e.target.value)} type="password" isRequired={true} />
            </div>
            <div className="flex justify-end items-center w-[32rem] ml-auto gap-[1.6rem] mt-[2rem] px-[1.9rem]">
              <Buttons variant="cancel" text="Cancel" handleClick={() => {
                setOpenModal(false)
                setConfirmPassword("")
                setPassword("")
              }} type="button" />
              <Buttons variant="primary" text="Save changes" type="submit" />
            </div>
          </form>
        </ModalContainer>
      )}
    </div>
  );
}

export default SecurityAndPrivacy;
