import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, useTheme } from "./context/ThemeContext";

// Import pages
import Homepage from "./pages/Homepage";
import Privacypage from "./pages/Privacypage";
import TnCpage from "./pages/TnCpage";
import GetStartedPage from "./pages/GetStartedPage";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import DashboardSuccess from "./pages/Dashboard/DashboardSuccess";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import AllIdeasPage from "./pages/Ideas/AllIdeasPage";
import SingleIdeaPage from "./pages/Ideas/SingleIdeaPage";
import ManageUsers from "./pages/Dashboard/ManageUsers";
import ManageInvestors from "./pages/Dashboard/ManageInvestors";
import ManageCategories from "./pages/Dashboard/ManageCategories";
import ManageIdea from "./pages/Dashboard/ManageIdeas";
import QuestionsHome from "./Components/AdminDashboard/questions/questionsHome";
import ManageMyIdea from "./pages/Dashboard/ManageMyIdea";
import ManageFunnels from "./pages/Dashboard/ManageFunnels";
import PlansPage from "./pages/PlansPage";
import ManageSubscriptions from "./pages/Dashboard/ManageSubscriptions";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import EmailTemplates from "./pages/Dashboard/EmailTemplates";
import ManageInterests from "./pages/Dashboard/ManageInterests";
import ManageMyCategories from "./pages/Dashboard/ManageMyCategories";
import PaymentHistoryPage from "./pages/PaymentHistory";
import ManageSettings from "./pages/Dashboard/ManageSettings";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

// Import layouts
import HomeLayout from "./layout/HomeLayout";
import PagesLayout from "./layout/PagesLayout";
import LayoutWrapper from "./layout/LayoutWrapper";
import DashboardLayout from "./layout/DashboardLayout";
import Home from "./pages/LIDashboard/Home";
import MyInvites from "./pages/LIDashboard/MyInvites";
import Invitation from "./pages/Invitation";
import Settings from "./pages/LIDashboard/Settings";
import UserDetails from "./Components/LiDashboard/UserDetails";
import useAuthStore from "./store/authStore";
import AdminDashboardHome from "./pages/Dashboard/AdminDashboardHome";
import Notifications from "./pages/Notifications";
import Profile from "./Components/Dashboard/student/profile";
import LecturerHome from "./Components/LecturersDashboard/Home";
import MyStudents from "./Components/LecturersDashboard/MyStudents";
import Groups from "./Components/LecturersDashboard/Groups";
import GroupStudents from "./Components/LecturersDashboard/GroupStudents";
import GroupIdeaPage from "./pages/GroupIdeaPage";
import MyLecturers from "./pages/LIDashboard/myLecturers";
import MyStudent from "./pages/LIDashboard/myStudent";
import MyCourses from "./Components/LiDashboard/MyCourses";
import StudentCourses from "./pages/StudentCourses";
import SingleCourse from "./pages/LIDashboard/SingleCourse";
import PWAPopup from "./CustomUIElements/Modal/PWAPopup";
import ManageInstitutions from "./pages/Dashboard/ManageInstitutions";

const USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
  LI: "learning_institution",
  STU: "student",
  LECT: "lecturer",
};

function App() {
  const { userProfile } = useAuthStore();
  const { links } = useTheme();
  if (!links && !window.localStorage.getItem("dev_api_link")) {
    return (
      <center className="h-screen flex items-center justify-center">
        <img
          className="animate-bounce h-[300px] w-[300px] object-cover"
          src="/assets/hero-img.svg"
          alt="logo"
        />
      </center>
    );
  }
  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}>
      <ToastContainer className="text-[1.6rem]" />
      {(/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)) && (
        <PWAPopup />
      )}
      <Router>
        <Routes>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="privacy-policy" element={<Privacypage />} />
            <Route path="terms-and-conditions" element={<TnCpage />} />
            <Route path="plans" element={<PlansPage />} />
            <Route path="payments/success" element={<SuccessPage />} />
            <Route path="payments/cancel" element={<CancelPage />} />
            <Route path="payment-history" element={<PaymentHistoryPage />} />
          </Route>

          <Route element={<PagesLayout />}>
            <Route path="get-started" element={<GetStartedPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route
              path="invitation/complete-registration"
              element={<Invitation />}
            />
          </Route>

          <Route
            element={
              <LayoutWrapper
                role={
                  userProfile?.user?.userRole !== "IdeaOwner"
                    ? USER_ROLES.ADMIN
                    : USER_ROLES.USER
                }
              />
            }
            path="/dashboard"
          >
            <Route
              index
              element={
                userProfile?.user?.userRole !== "IdeaOwner" ? (
                  <AdminDashboardHome />
                ) : (
                  <DashboardHome />
                )
              }
            />
            <Route path="welcome" element={<DashboardPage />} />
            <Route path="success" element={<DashboardSuccess />} />
          </Route>

          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-users"
          >
            <Route index element={<ManageUsers />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-investors"
          >
            <Route index element={<ManageInvestors />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-institutions"
          >
            <Route index element={<ManageInstitutions />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-categories"
          >
            <Route index element={<ManageCategories />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-my-ideas"
          >
            <Route index element={<ManageMyIdea />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-ideas"
          >
            <Route index element={<ManageIdea />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-questions"
          >
            <Route index element={<QuestionsHome />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-funnels"
          >
            <Route index element={<ManageFunnels />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-subscriptions"
          >
            <Route index element={<ManageSubscriptions />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-settings"
          >
            <Route index element={<ManageSettings />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/email-templates"
          >
            <Route index element={<EmailTemplates />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-interests"
          >
            <Route index element={<ManageInterests />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.ADMIN} />}
            path="/manage-my-categories"
          >
            <Route index element={<ManageMyCategories />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.LI} />}
            path="/li/dashboard"
          >
            <Route index element={<Home />} />
            <Route path="my-invites" element={<MyInvites />} />
            <Route path="my-courses" element={<MyCourses />} />
            <Route path="my-courses/:id" element={<SingleCourse />} />
            <Route path="my-lecturers" element={<MyLecturers />} />
            <Route path="my-students" element={<MyStudent />} />
            <Route path="my-invites/:id" element={<UserDetails />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.STU} />}
            path="/student/dashboard"
          >
            <Route index element={<Profile />} />
            <Route path="group-ideas">
              <Route
                path="course/:courseId/group/:groupId"
                element={<GroupIdeaPage src="group" />}
              />
              <Route
                path="course/:courseId/group/:groupId/idea/:id"
                element={<SingleIdeaPage src="group" />}
              />
            </Route>
            <Route path="my-courses" element={<StudentCourses src="group" />} />
            <Route path="groups" element={<Groups src="student" />} />
            <Route
              path="notifications"
              element={<Notifications src="group" />}
            />
          </Route>
          <Route
            element={<LayoutWrapper role={USER_ROLES.LECT} />}
            path="/lecturer/dashboard"
          >
            <Route index element={<LecturerHome />} />
            <Route path="my-invites" element={<MyInvites />} />
            <Route path="my-students" element={<MyStudents />} />
            <Route path="groups" element={<Groups />} />
            <Route path="my-courses" element={<MyCourses />} />
            <Route path="my-courses/:id" element={<SingleCourse />} />
            <Route
              path="courses/:courseId/groups/:id"
              element={<GroupStudents />}
            />
            <Route path="group-ideas">
              <Route
                path="course/:courseId/group/:groupId"
                element={<GroupIdeaPage src="lecturer" />}
              />
              <Route
                path="course/:courseId/group/:groupId/idea/:id"
                element={<SingleIdeaPage src="lecturer" />}
              />
            </Route>
            {/* <Route
                path="group-ideas"
                element={<GroupIdeaPage src="lecturer" />}
              /> */}
          </Route>

          <Route element={<DashboardLayout />} path="/my-ideas">
            <Route index path="/my-ideas" element={<AllIdeasPage />} />
            <Route path=":id" element={<SingleIdeaPage />} />
          </Route>
          <Route element={<LayoutWrapper role={USER_ROLES.USER} />}>
            <Route path="/notifications" element={<Notifications />} />
          </Route>
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
