// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const makePayment = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post(
    "/Payments/makePayment",
    { id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
