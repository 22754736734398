import React from "react";
import useIdeaStore from "../../store/ideaStore";

function IdeaBanner() {
  const { ideas } = useIdeaStore();
  return (
    <div className="bg-[#96E296] rounded-2xl pr-6 mt-[4rem] max-h-[14rem] relative flex justify-between items-center">
      <img
        src="/assets/idea-banner.svg"
        alt=""
        className="relative scale-[0.7] top-[-0.2rem]"
      />
      <div className="flex gap-[2rem] items-center">
        <h2 className="text-[#103240B2] text-[3.6rem]">MY IDEAS</h2>
        <p className="text-[#103240] text-[6.4rem] bg-[#FFFFFF80] rounded-2xl p-1 leading-[6.5rem]">
          {ideas?.length | 0}
        </p>
      </div>
    </div>
  );
}

export default IdeaBanner;
