// ThemeContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import apiClient from "../libs/http-common";
import { getSetting } from "../utils/updateSetting";
import useAuthStore from "../store/authStore";
import axios from "axios";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [color, setColor] = useState({
    backgroundColor: "#008FD2",
    color: "#fff",
  });
  const [theme, setTheme] = useState(color); // Default theme
  const [logo, setLogo] = useState("");
  const [reload, setReload] = useState(false);
  const { userProfile } = useAuthStore();
  const [links, setLinks] = useState(null);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      // Toggle between themes (light, dark, blue)
      if (prevTheme === "light") return "dark";
      else if (prevTheme === "dark") return "blue";
      else return "light";
    });
  };

  const getBaseURL = async () => {
    await axios
      .get("https://api.hydreate.com/api/v1/Accounts/getAPIBaseUrl")
      .then((res) => {
        window.localStorage.setItem("dev_api_link", res?.data?.devAPIUrl);
        window.localStorage.setItem("prod_api_link", res?.data?.prodAPIUrl);
        setLinks(res?.data);
      })
      .catch(() => {
        console.log("An error occured while getting links");
      });
  };

  useEffect(() => {
        getBaseURL();
  }, []);

  useEffect(() => {
    if (
      userProfile?.user &&
      userProfile?.user?.userRole !== "IdeaOwner" &&
      userProfile?.user?.userRole !== "Lecturer"
    ) {
      getSetting().then((res) => {
        const d = res?.data?.data;
        console.log(d);
        setLogo(d?.logoUrl);
        setColor({
          backgroundColor: d?.primaryColour ? d?.primaryColour : "#008FD2",
          color: "#FFF",
        });
      });
    }
  }, [reload, userProfile]);

  useEffect(() => {
    setTheme(color);
  }, [color]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        color,
        logo,
        setLogo,
        setColor,
        setReload,
        links,
        getBaseURL
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
