import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
// import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { deleteInvestor } from "../../../utils/deleteInvestor";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { deleteFunnel } from "../../../utils/deleteFunnel";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { updateFunnel } from "../../../utils/updateFunnel";

function FunnelsTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [rowData, setRowData] = useState(null);
  const [formValues, setFormValues] = useState({
    funnelName: "",
    funnelDesc: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const { removeUser } = useAuthStore();
  const navigate = useNavigate()

  useEffect(() => {
    if (rowData) {
      setFormValues({
        funnelName: rowData.name || "",
        funnelDesc: rowData.description || ""
      });
    }
  }, [rowData]);

  const { mutate, isLoading: deletingFunnel } = useMutation(() => deleteFunnel(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  const { mutate: updateFunnelFn, isLoading: isUpdating } = useMutation(updateFunnel, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal()
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });

  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Funnels", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    updateFunnelFn({
      name: formValues?.funnelName,
      description: formValues?.funnelDesc,
      id: rowData?.id
    });
  };

  return (
    <>
      <Table
        header={[
          { name: "Name of Funnel", identifier: "name" },
          { name: "Description of Funnel", identifier: "description" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "disable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this funnel?"
        onConfirm={mutate}
        loading={deletingFunnel}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "enable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this funnel?"
        onConfirm={activateUser}
        loading={activatingUser}
      />
      {openModal?.modalName === "edit" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit Funnel</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"funnelName"}
                type={"text"}
                label={"Name of Funnel"}
                name={"funnelName"}
                isRequired={true}
                value={formValues["funnelName"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <Inputs
                key={"funnelDesc"}
                type={"text"}
                label={"Description of Funnel"}
                name={"funnelDesc"}
                isRequired={true}
                value={formValues["funnelDesc"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.funnelName &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isUpdating ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}

      {openModal?.modalName === "view" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Funnel Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"funnelName"}
                type={"text"}
                label={"Name of Funnel"}
                name={"funnelName"}
                isRequired={true}
                value={formValues["funnelName"] || rowData["name"]}
                readOnly
              />
              <Inputs
                key={"funnelDesc"}
                type={"text"}
                label={"Description of Funnel"}
                name={"funnelDesc"}
                isRequired={true}
                value={formValues["funnelDesc"] || rowData["description"]}
                readOnly
              />
            </form>
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default FunnelsTable;
