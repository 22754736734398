import React, { useState } from "react";
import { BsCaretDown, BsCaretDownFill } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { Buttons } from "../Buttons/Buttons";
import apiClient from "../../libs/http-common";

function FeedbackModal({ setOpenFeedback }) {
  const [info, setInfo] = useState({
    subject: "",
    comment: ""
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const token = window.localStorage.getItem("token");
    await apiClient.post("/UserFeedbacks/add", info, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res);
      setOpenFeedback(false)
      setLoading(false)
    }).catch((err) => {
      console.log(err);
      setLoading(false)
    })
  }
  return (
    <div className="p-[1.9rem] relative">
      <div className="flex justify-between items-center">
        <h3 className="text-[#103240] text-[2rem] font-semibold">
          Send Feeback
        </h3>
        <GrClose
          size={15}
          className="font-semibold cursor-pointer"
          onClick={() => setOpenFeedback(false)}
        />
      </div>
      <p className="my-[1.5rem] text-[1.4rem] text-[#103240]">
        Have a question or suggestion for us? We're all ears
      </p>
      <div className="relative">
        <select className="w-full relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] text-[16px] leading-[2.4rem] appearance-none text-[#103240B2]" value={info?.subject} onChange={(e) => setInfo({ ...info, subject: e.target.value })}>
          <option value="" disabled selected hidden>
            Subject
          </option>
          <option value="General Enquiries">General Enquiries</option>
          <option value="Compliant">Compliant</option>
          <option value="Error using site">Error using site</option>
          <option value="Others">Others</option>
        </select>
        <span className="absolute top-[2rem] right-[1.5rem] font-medium text-[1.2rem] text-[#000] cursor-pointer">
          <BsCaretDownFill color="black" />
        </span>
      </div>
      <textarea
        className="mt-[1.6rem] relative rounded-2xl outline-none bg-[#F7F7F7] w-full text-[16px] leading-[2.4rem] resize-none h-[16rem] py-[1.5rem] px-[1.2rem] text-[#103240B2]"
        placeholder="How can we improve? We would be grateful for any suggestions!"
        value={info?.comment}
        onChange={(e) => setInfo({ ...info, comment: e.target.value })}
      ></textarea>
      <div className="flex justify-end items-center w-[29.2rem] ml-auto gap-[1.6rem] mt-[1rem]">
        <Buttons
          variant="cancel"
          text="Cancel"
          handleClick={() => setOpenFeedback(false)}
        />
        <Buttons variant={loading ? "disabled" : "primary"} text="Save" handleClick={handleSubmit} />
      </div>
    </div>
  );
}

export default FeedbackModal;
