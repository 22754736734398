import React, { useState } from 'react'
import { useQuery } from 'react-query';
import moment from 'moment';
import { fetchPaymentHistory } from '../../utils/fetchPaymentHistory';
import useAuthStore from '../../store/authStore';

function PaymentHistory() {
    const [paymentHistory, setPaymentHistory] = useState([])
    const { removeUser, userProfile } = useAuthStore()
    const { isError, isLoading: loadingCategories, refetch } = useQuery(
        "fetchPaymentHistory",
        fetchPaymentHistory,
        {
            enabled: true,
            onSuccess: ({ data }) => {
                setPaymentHistory(data.data);
            },
            onError: ({ response }) => {
                console.log(`${response?.data?.status}`);
                if (response?.data?.status === 401) {
                    removeUser();
                    window.location.href = "/login"
                }
            },
        }
    );
    return (
        <div className='overflow-y-scroll h-[50rem] pb-[8rem] scrollbar-hide w-full'>
            <div className=''>
                <h3 className='text-[3rem]'>Billing Details</h3>
                <div className='my-8'>
                    <p className='text-[1.4rem] uppercase mb-2'>Current Plan</p>
                    <div className='bg-slate-100 border w-full p-4'>
                        <div>
                            <p className='text-[1.4rem] font-semibold'>Plan</p>
                            <p className='text-[1.6rem] mt-2'>{paymentHistory[paymentHistory?.length - 1]?.naration || userProfile?.user?.subscription}</p>
                            <p className='text-[1.6rem] mt-2 font-thin'>{paymentHistory[paymentHistory?.length - 1]?.amount || 0} GBP/Month</p>
                        </div>
                        <div className='mt-8'>
                            <p className='text-[1.4rem] font-semibold'>Next Billing Date</p>
                            <p className='text-[1.6rem] mt-2'>{moment(paymentHistory[paymentHistory?.length - 1]?.dateAdded).add(30, 'days').format('D MMM YYYY')}</p>
                        </div>
                    </div>
                    <div className='mt-8'>
                        <p className='text-[1.4rem] uppercase mb-2'>Payment History</p>
                        {/* TODO - Create table for payment history */}
                        <table className='w-full'>
                            <thead className="border-y border-[#CAD1F6] py-[20px]  bg-[#FFF] overflow-x-scroll">
                                <tr>
                                    <th className="text-left font-medium text-[14px] text-[#55596E] w-[fit] py-[20px]" style={{ width: 'auto', minWidth: '120px' }}>
                                        Date
                                    </th>
                                    <th className="text-left font-medium text-[14px] text-[#55596E] w-fit lg:w-[363px] py-[20px]" style={{ width: 'auto', minWidth: '120px' }}>
                                        Narration
                                    </th>
                                    <th className="text-left font-medium text-[14px] text-[#55596E] w-[fit] py-[20px] px-[0]" style={{ width: 'auto', minWidth: '120px' }}>
                                        Transaction Reference
                                    </th>
                                    <th className="text-left font-medium text-[14px] text-[#55596E] py-[20px]" style={{ width: 'auto', minWidth: '120px' }}>
                                        Amount
                                    </th>
                                    <th className="text-left font-medium text-[14px] text-[#55596E] py-[20px]" style={{ width: 'auto', minWidth: '120px' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentHistory?.length > 0 && paymentHistory?.map((data, index) => (
                                    <tr key={index} className="border-b border-[#CAD1F6]" >
                                        <td className="py-[20px] px-[0px] w-[fit] text-[14px] text-[#131419]">
                                            {moment.utc(data?.dateAdded).format("D, MMM YYYY")}
                                        </td>
                                        <td className="py-[20px] px-[0px] w-[fit] text-[14px] text-[#131419]">
                                            {data?.naration}
                                        </td>
                                        <td className="py-[20px] px-[0px] w-[fit] text-[14px] text-[#131419]">
                                            {data?.transationRef}
                                        </td>
                                        <td className="py-[20px] px-[0px] w-[fit] text-[14px] text-[#131419]">
                                            {data?.amount}
                                        </td>
                                        <td className="py-[20px] px-[0px] w-[fit] text-[14px] text-[#131419]">
                                            {data?.paymentResponseAsJson}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentHistory