// // import { header } from "../libs/authHeader";
// import apiClient from "../libs/http-common";
// export const fetchMyIdeas = async () => {
//   const token = window.localStorage.getItem("token");
//   return await apiClient.get("/Ideas/getMyIdeas", {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };


import apiClient from "../libs/http-common";
export const fetchMyIdeas = async () => {
  const token = window.localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await apiClient.get("/Ideas/getMyIdeas", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch ideas:", error);
    throw error;
  }
};