import React, { useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import Table from '../../CustomUIElements/Table/Table';
import { useQuery } from 'react-query';
import useAuthStore from '../../store/authStore';
import { fetchStudents } from '../../utils/students';
import ModalContainer from '../../CustomUIElements/Modal/ModalContainer';
import ConfirmationModal from '../../CustomUIElements/Modal/ConfirmationModal';
import { useMutation } from 'react-query';
import { toast } from "react-toastify";
import { deleteUser } from '../../utils/deleteUser';

function MyStudent() {
    const [students, setStudents] = useState([])
    const [rowData, setRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    const { removeUser } = useAuthStore()
    const [searchQuery, setSearchQuery] = useState("");
    const { isLoading, refetch } = useQuery("fetchStudents", fetchStudents, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            console.log(data.data)
            setStudents(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })
    const [openModal, setOpenModal] = useState({
        modalName: "",
        showModal: false,
    });

    console.log(rowData?.Stud)

    //   const { mutate: removeStudent, isLoading: deleting } = useMutation(() => removeStudentFromGroupp(studentData), {
    //     onSuccess: ({ data }) => {
    //         refetch()
    //         toast.success("students Deleted")
    //         setOpenModal({ modalName: "", showModal: false })
    //     },
    //     onError: ({ response }) => {
    //         toast.error(`${response?.data?.status}`);
    //         if (response?.data?.status === 401) {
    //             removeUser()
    //             // navigate("/ogin")
    //         }
    //     },
    // });

    const { mutate: deleteStudent, isLoading: deletingUser } = useMutation(() => deleteUser(rowData?.id), {
        onSuccess: ({ data }) => {
            refetch()
            toast.success("Student deleted successfully")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        },
    })


    return (
        <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold my-[3rem]">My Students</h1>
            <div className='flex justify-between items-center mb-8'>
                <div className="relative">
                    <input
                        className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="absolute top-[14px] left-[15px] text-[20px]">
                        <HiOutlineSearch />
                    </span>
                </div>
            </div>
            <Table
                header={
                    [
                        { name: "First Name", identifier: "firstName" },
                        { name: "Last Name", identifier: "lastName" },
                        { name: "Email", identifier: "emailAddress" },
                    ]}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    showPagination: true,
                    actions: {
                        delete: true,
                        hideActivate: true
                    }
                }}
                data={[...students]}
                openModal={openModal}
                setOpenModal={setOpenModal}
                searchQuery={searchQuery}
                rowData={rowData}
                setRowData={setRowData}
                loading={isLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}

            />

            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this Student?"

            />


            {openModal.showModal && openModal.modalName === "delete" && (
                <ModalContainer height="auto">
                    <ConfirmationModal
                        showModal={openModal.modalName === "delete" && openModal.showModal}
                        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                        message="Are you sure you want to delete this Student?"
                        onConfirm={() => deleteStudent()}
                        loading={deletingUser}

                    />
                </ModalContainer>
            )}
        </div>
    )
}

export default MyStudent