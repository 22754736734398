import React, { useEffect, useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { LiaTimesSolid } from "react-icons/lia";
// import AddInvestorForm from "./AddInvestorForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { deleteSubscription } from "../../../utils/deleteSubscription";
import { updateSubscription } from "../../../utils/updateSubscription";
import ReactQuill from "react-quill";
import parse from "html-react-parser"
import { updateEmailTemplate } from "../../../utils/updateEmailTemplate";
import { deleteTemplate } from "../../../utils/deleteTemplate";

function TemplateTable({ data, loading, refetch, selectedRows, setSelectedRows, searchQuery }) {
  const [rowData, setRowData] = useState(null);
  const [formValues, setFormValues] = useState({
    code: "",
    subject: "",
  });
  const [desc, setDesc] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const { removeUser } = useAuthStore();
  const navigate = useNavigate()

  const { mutate, isLoading: deleting } = useMutation(() => deleteTemplate(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  const { mutate: updateFunnelFn, isLoading: isUpdating } = useMutation(updateEmailTemplate, {
    onSuccess: ({ data }) => {
      // saveUserProfile(data.data);
      setOpenModal()
      toast.success(`${data.status}`)
      setFormValues({})
      refetch()
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  });

  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("EmailTemplates", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    updateFunnelFn({
      code: formValues?.code,
      message: desc,
      id: rowData?.id,
      subject: formValues?.subject,
    });
  };

  useEffect(() => {
    if (rowData) {
      setFormValues({
        code: rowData.code || "",
        subject: rowData.subject || "",
      });
      setDesc(rowData?.message || "");
    }
  }, [rowData]);

  return (
    <>
      <Table
        header={[
          { name: "Code Type", identifier: "code" },
          // { name: "Description", identifier: "description" },
          { name: "Subject", identifier: "subject" },
        ]}
        data={data}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: true,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={loading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={searchQuery}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "disable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this template?"
        onConfirm={mutate}
        loading={deleting}
      />
      <ConfirmationModal
        showModal={openModal?.modalName === "enable" && openModal?.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this template?"
        onConfirm={activateUser}
        loading={activatingUser}
      />
      {openModal?.modalName === "edit" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Edit Template</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"code"}
                type={"text"}
                label={"Code"}
                name={"code"}
                isRequired={true}
                value={formValues["code"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value?.replaceAll(" ", "_"),
                  })
                }
              />
              <Inputs
                key={"subject"}
                type={"text"}
                label={"Subject"}
                name={"subject"}
                isRequired={true}
                value={formValues["subject"]}
                handleChange={(e) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <ReactQuill
                theme="snow"
                value={desc}
                onChange={setDesc}
                className="peer !text-[1.6rem] h-[15rem] !relative !mb-[1.6rem]"
                placeholder="Message"
              />
              <div className="!mt-[10rem] lg:!mt-[5rem]">
                <Buttons
                  variant={
                    formValues?.code &&
                      formValues?.subject &&
                      desc &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isUpdating ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}

      {openModal?.modalName === "view" && openModal?.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Template Information</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[1.6rem]"
              onSubmit={handleSubmit}
            >
              <Inputs
                key={"tocodepic"}
                type={"text"}
                label={"Code"}
                name={"code"}
                isRequired={true}
                value={formValues["code"] || rowData["code"]}
                readOnly
              />
              <ul className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem] list-disc">
                {parse(desc)}
              </ul>
              <Inputs
                key={"subject"}
                type={"text"}
                label={"Subject"}
                name={"subject"}
                isRequired={true}
                value={formValues["subject"] || rowData["subject"]}
                readOnly
              />
            </form>
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default TemplateTable;
