import React, { useEffect } from "react";
import Footer from "../Components/HeroComponent/Footer";
import TnC from "../Components/TnC/TnC";

function TnCpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TnC />
      <Footer />
    </>
  );
}

export default TnCpage;
