import apiClient from "../libs/http-common";
export const deleteUser = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/Users/delete",
    method: "delete",
    data: { id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};