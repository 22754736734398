import React from "react";
import useAuthStore from "../../store/authStore";
import Scale from "../../CustomUIElements/Loaders/ScaleLoader";
import { Link, useNavigate } from "react-router-dom";

function NotificationPopUp({ isLoading, isRefetching, ref }) {
  const { userNotifications } = useAuthStore();
  const navigate = useNavigate()
  return (
    <>
      <div className=" p-4 flex gap-[1.2rem] items-center">
        <p className="text-[#103240] text-[2rem] font-bold">
          Notifications{" "}
          {!isLoading &&
            !isRefetching &&
            userNotifications &&
            userNotifications.length > 0 &&
            `(${userNotifications.length})`}
        </p>
      </div>
      <hr />
      <div className="max-h-[19rem] overflow-auto">
        {isLoading || isRefetching ? (
          <center>
            <Scale />
          </center>
        ) : userNotifications && userNotifications.length > 0 ? (
          userNotifications?.map((notification) => (
            <div onClick={() => navigate(`/ideas/:id/nlp-results`)} className="block px-4 py-2 mb-2 text-[1.4rem] hover:bg-[#008FD2] hover:text-white text-[#103240] cursor-pointer">
              <p className="">{notification?.subject}</p>
            </div>
          ))
        ) : (
          <div className="px-4 py-2 h-[6rem] flex justify-center items-center">
            <p className="text-[#103240B2] text-[1.4rem]">
              You don’t have any notifications.
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default NotificationPopUp;
