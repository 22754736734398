import React from "react";
import { GrClose } from "react-icons/gr";

function PointsModal({ setOpenPointsModal }) {
  return (
    <div>
      <span className="p-[2.4rem] flex justify-end">
        <GrClose
          size={15}
          className="font-semibold cursor-pointer"
          onClick={() => setOpenPointsModal(false)}
        />
      </span>
      <div className="w-[30rem] mx-auto flex flex-col justify-center items-center">
        <img src="/assets/points.svg" alt="" />
        <h1 className="text-[6.8rem] text-[#008FD2] leading-[10.4rem] font-semibold">
          0
        </h1>
        <p className="text-[1.8rem] text-[#103240] mt-[-2rem]">Points</p>
        <hr />
      </div>
      <div className="flex justify-center flex-col items-center mt-5">
        <img src="/assets/point-star.svg" alt="" className="mb-[1.6rem]" />
        <h4 className="mb-[0.8rem] text-[#103240] text-[2.4rem] font-semibold">
          Earn Points
        </h4>
        <p className="max-w-[43.2rem] text-[1.4rem] text-[#103240CB] text-center">
          For every step in your idea creation you earn points. This points will be saved and notifications will be sent when rewards are active.
        </p>
      </div>
    </div>
  );
}

export default PointsModal;
