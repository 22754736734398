function Vision() {
  return (
    <section className="relative top-[5.6rem] my-[11.6rem]" id="vision">
      <div className="app-container">
        <div className="flex flex-col items-center justify-center text-center mb-[4.8rem]">
          <div>
            <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[3.6rem]">
              Our Vision is to be the CATALYST
            </p>
            <h3 className="text-[#103240] text-[4.7rem] md:text-[9.6rem] max-w-[102rem] font-bold leading-[7rem] md:leading-[10.6rem] mb-[2.4rem]">
              Every Idea <br /> Matters
            </h3>
            <p className="text-[#103240] font-medium text-[2rem] max-w-[76.8rem] mx-auto mb-[6.4rem] leading-[3rem]">
            Our vision is to be the catalyst for a world where every idea matters and every voice is heard. We envision a future where innovation thrives in every corner of society, driving positive change and shaping a better tomorrow for generations to come. With Hydreate, we strive to create a vibrant ecosystem where ideas flourish, collaborations thrive, and dreams are transformed into tangible outcomes that benefit individuals, communities, and the world at large.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Vision;
