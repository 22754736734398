import React from 'react'

function UserDetails() {
  return (
    <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
      <h1 className="text-[20px] font-semibold mb-[3rem]">User Profile Page</h1>
      <div className=''>
        <p className='mb-8 text-[16px] font-semibold'>Personal Information</p>
        <div className='grid grid-cols-2 gap-8 mb-[40px]'>
          <div>
            <p className='text-[15px] mb-2'>First Name</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              John
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Last Name</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              Doe
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Email Address</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              johndoe@gmail.com
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Phone Number</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              +1 (234) - 4567
            </div>
          </div>
        </div>
        <p className='mb-8 text-[16px] font-semibold'>Institution Information</p>
        <div className='grid grid-cols-2 gap-8 mb-8'>
          <div>
            <p className='text-[15px] mb-2'>Institution Name</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              Lancaster University
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Student&apos;s Course of Study</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              Biochemistry
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Student&apos;s Current Year</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              Year 4
            </div>
          </div>
          <div>
            <p className='text-[15px] mb-2'>Student&apos;s Group Name</p>
            <div type='text' className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 p-[1.2rem] w-full text-[15px] leading-[2.4rem]">
              Group 1
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDetails