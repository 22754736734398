import React, { useEffect, useState } from "react";
import Inputs from "../../CustomUIElements/Inputs/Inputs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { useMutation } from "react-query";
import { forgotPassword, resetPassword } from "../../utils/userAccountFn";
import useAuthStore from "../../store/authStore";
import { toast } from "react-toastify";

function ResetPassword() {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({});
    const { userProfile } = useAuthStore();
    const [searchParams] = useSearchParams();

    const email = searchParams.get("email");
    const code = searchParams.get("code");

    console.log( email?.replaceAll(" ", "+"))

    const { mutate, isLoading } = useMutation(resetPassword, {
        onSuccess: ({ data }) => {
            toast.success(`${data.status}`);
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            email: email?.replaceAll(" ", "+"),
            code: encodeURIComponent(code),
            password: formValues?.password
        });
    };
    useEffect(() => {
        if (userProfile) {
            return navigate("/dashboard");
        }
    }, []);
    return (
        <div className="app-container  md:w-[36rem] mx-auto flex flex-col mb-[5rem] ">
            <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[25.2rem] mx-auto mt-[4rem]">
                Reset your account
            </h3>
            <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
                <Inputs
                    type="password"
                    label="Password"
                    isRequired={true}
                    name="password"
                    value={formValues?.password}
                    handleChange={(e) =>
                        setFormValues({ ...formValues, password: e.target.value })
                    }
                />
                <div>
                    <Buttons
                        type="submit"
                        variant={
                            formValues?.password && !isLoading
                                ? "primary"
                                : "disabled"
                        }
                        text={isLoading ? "Please wait..." : "Submit"}
                    />
                    <div className="mt-[3.4rem] text-center">
                        <p className="text-[#2A2E43] text-[1.4rem]">
                            Remember your password?{" "}
                            <Link to="/login" className="text-[#008FD2]">
                                Login
                            </Link>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ResetPassword;
