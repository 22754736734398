import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/authStore";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import apiClient from "../../libs/http-common"
import { fetchUserProfile } from "../../utils/fetchUserProfile";
import { toast } from "react-toastify";
import { uploadFile } from "../../utils/uploadPicture";
import { useQuery } from "react-query";
import { fetchSubscriptions } from "../../utils/fetchSubscriptions";

function PersonalDetails({ setOpenSettings }) {
  const { userProfile, removeUser } = useAuthStore();
  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [sub, setSub] = useState([])

  let profilePic = JSON.parse(window.localStorage.getItem("profilePic"))

  const [pics, setPics] = useState(profilePic || null)

  const { } = useQuery("fetchSubscriptions", fetchSubscriptions, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      setSub(data?.data)
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })

  const fetchUserProfileFn = async () => {
    fetchUserProfile(userProfile?.user?.id).then((res) => {
      setUserDetails(res?.data?.data)
    })
  }

  const findSubID = () => {
    if (sub) {
      const id = sub?.find((s) => s?.topic === userProfile?.user?.subscription)?.id
      return id
    }
  }
  const handleProfileUpdate = async (e) => {
    e.preventDefault()
    setLoading(true)
    await apiClient.put("/Accounts/updateUser", {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      mobileNumber: userDetails.phoneNumber,
      description: userDetails.description,
      isInvestor: userProfile?.user?.isInvestor,
      userId: userDetails?.id,
      investorType: userDetails?.investorType,
      websiteUrl: userDetails?.website,
      subscriptionId: findSubID() || 0,
      emailAddress: userDetails?.email,
      organisationName: userDetails?.organisationName
    }).then((res) => {
      setLoading(false)
      toast.success("Profile updated successfully")
      fetchUserProfileFn()
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      toast.error("Error updating profile")
    })
  }

  const handleUploadFunction = async (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    console.log("files", files);
    setLoading(true);
    toast
      .promise(
        uploadFile(
          files,
          5,
          ["jpg", "jpeg", "png"]
        ),
        {
          pending: "Uploading picture, Please wait...",
          success: "File Uploaded Successfully",
          error: "Failed to Upload File",
        }
      )
      .then((res) => {
        setLoading(false);
        fetchUserProfileFn()
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUserProfileFn()
  }, [])


  return (
    <div className="w-full overflow-y-scroll h-[50rem] pb-[8rem] scrollbar-hide">
      <h4 className="text-[1.8rem] font-semibold mb-[1.6rem] text-[#103240]">
        Personal Details
      </h4>
      <label>
        <p className="text-[#103240B2] text-[1.4rem] mb-2">Picture</p>
        {userDetails?.documents?.length > 0 ?
          <div className="w-[15.2rem] h-[15.2rem] text-[7.6rem] text-white  p-2 rounded-full flex justify-center items-center pt-3 pl-[.6rem] cursor-pointer">
            <img src={userDetails?.documents[0]?.path} alt="" className="w-full h-full rounded-full object-cover" />
          </div>
          :
          <div className="w-[15.2rem] h-[15.2rem] text-[7.6rem] text-white bg-[#008FD2] p-2 rounded-full flex justify-center items-center pt-3 pl-[.6rem] cursor-pointer">
            {userDetails?.firstName && userDetails?.firstName[0]}
            {userDetails?.lastName && userDetails?.lastName[0]}
          </div>
        }
        <input type="file" className="hidden" accept="image/png, image/jpg, image/jpeg" onChange={handleUploadFunction} />
      </label>
      <form className="mt-8">
        <div className="grid grid-cols-2 gap-[1.6rem] mb-[1.6rem]">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#103240] text-[1.6rem] mb-[.8rem]"
            >
              First Name
            </label>
            <input
              type="text"
              className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
              value={userDetails?.firstName}
              onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="text-[#103240] text-[1.6rem] mb-[.8rem]"
            >
              Last Name
            </label>
            <input
              type="text"
              className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
              value={userDetails?.lastName}
              onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })}
            />
          </div>
        </div>
        <div className="flex flex-col mb-[1.6rem]">
          <label htmlFor="" className="text-[#103240] text-[1.6rem] mb-[.8rem]">
            Phone
          </label>
          <input
            type="text"
            className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
            value={userDetails?.phoneNumber}
            onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })}
          />
        </div>
        <div className="flex flex-col mb-[1.6rem]">
          <label htmlFor="" className="text-[#103240] text-[1.6rem] mb-[.8rem]">
            Description
          </label>
          <textarea
            type="text"
            className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none h-[16.8rem] resize-none"
            placeholder="Enter a short description about you"
            value={userDetails?.description}
            onChange={(e) => setUserDetails({ ...userDetails, description: e.target.value })}
          />
        </div>
        <div className="flex flex-col mb-[1.6rem]">
          <label htmlFor="" className="text-[#103240] text-[1.6rem] mb-[.8rem]">
            Profile URL
          </label>
          <div
            type="text"
            className="border rounded-[1.5rem] border-[#E4E4E4] px-[1.2rem] py-[1.8rem] w-full text-[1.6rem] text-[#103240] outline-none"
          >
            <span className="text-[#2A2E4380]">https://hydreate.io/u/</span>
            <span>{`${userDetails?.firstName}_${userDetails?.lastName}`}</span>
          </div>
        </div>
        <div className="flex justify-end items-center w-[29.2rem] ml-auto gap-[1.6rem] mt-[4rem]">
          <Buttons
            variant="cancel"
            text="Cancel"
            handleClick={() => setOpenSettings(false)}
          />
          <Buttons variant={loading ? "disabled" : "primary"} text="Save" handleClick={handleProfileUpdate} />
        </div>
      </form>
    </div>
  );
}

export default PersonalDetails;
