import React, { useState } from "react";
import Table from "../../../CustomUIElements/Table/Table";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { LiaTimesSolid } from "react-icons/lia";
import { useMutation } from "react-query";
import { deleteIdea } from "../../../utils/deleteIdea";
import useIdeaStore from "../../../store/ideaStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
// import ViewModal from "../../../CustomUIElements/Modal/viewModal";

function AllIdeasTable({ Idea, loading: ideasLoading, selectedRows,
  setSelectedRows, refetch, searchQuery}) {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const { ideas, saveIdeas } = useIdeaStore()
  const { removeUser } = useAuthStore()
  // console.log(Idea)

  const navigate = useNavigate()

  const { mutate: deleteAnIdea, isLoading: deletingIdea } = useMutation(() => deleteIdea(rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      setOpenModal({ modalName: "", showModal: false })
      toast.success("Idea deleted successfully")
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  const { mutate: activateUser, isLoading: activatingUser } = useMutation(() => activateEntityStatus("Ideas", rowData?.id), {
    onSuccess: ({ data }) => {
      refetch()
      toast.success("Operation successful")
      setOpenModal({ modalName: "", showModal: false })
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      if (response?.data?.status === 401) {
        removeUser()
        navigate("/ogin")
      }
    },
  })

  return (
    <>
      <Table
        header={[
          { name: "Owner", identifier: "ideaOwner" },
          { name: "Title", identifier: "name" },
          { name: "Description", identifier: "description" },
        ]}
        data={Idea}
        options={{
          variant: "primary",
          tableStyle: "striped-even",
          allowActions: true,
          allowSorting: true,
          actions: {
            edit: false,
            delete: false,
            view: true,
          },
          showPagination: true,
        }}
        searchQuery={searchQuery}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        loading={ideasLoading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "disable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to de-activate this idea?"
        loading={deletingIdea}
        onConfirm={() => deleteAnIdea()}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "enable" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate this idea?"
        loading={activatingUser}
        onConfirm={() => activateUser()}
      />

      {openModal.modalName === "edit" && openModal.showModal && (
        <ModalContainer>
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Add User Form</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
              />
            </div>
            <form className="space-y-[1.6rem]">
              {FormInputFields.map(({ name, label, type, isRequired }) => (
                <Inputs
                  key={name}
                  type={type}
                  label={label}
                  name={name}
                  isRequired={isRequired}
                  value={formValues[name]}
                  handleChange={(e) =>
                    setFormValues({
                      ...formValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              ))}
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.firstName &&
                      formValues?.lastName &&
                      formValues?.email &&
                      formValues?.phoneNumber &&
                      formValues?.password &&
                      formValues?.confirmPassword &&
                      formValues?.password === formValues?.confirmPassword &&
                      formValues?.acceptTnC &&
                      !isLoading
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={isLoading ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}

      {openModal.modalName === "view" && openModal.showModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">View Idea</h3>
              <LiaTimesSolid
                onClick={() =>
                  setOpenModal({ modalName: "", showModal: false })
                }
                className="cursor-pointer"
              />
            </div>
            <div className="space-y-[1.6rem]">
              <div className="flex flex-col gap-2">
                <label className="text-[1.5rem] font-medium">Idea Name</label>
                <input type="text" readOnly value={rowData?.name} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[1.5rem] font-medium">Idea Description</label>
                <input type="text" readOnly value={rowData?.description} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[1.5rem] font-medium">Idea Category</label>
                <input type="text" readOnly value={rowData?.categoryName} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[1.5rem] font-medium">Idea Owner</label>
                <input type="text" readOnly value={rowData?.ideaOwner} className="relative rounded-2xl bg-[#F7F7F7] outline-none border-0 py-[1.5rem] px-[1.2rem] w-full text-[16px] leading-[2.4rem]" />
              </div>
            </div>
          </div>
        </ModalContainer>
      )}

    </>
  );
}

export default AllIdeasTable;
