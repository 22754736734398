import React from "react";

function BorderedInputs({
  type,
  label,
  isRequired,
  handleChange,
  name,
  value,
}) {
  // const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="relative">
      <input
        type={type}
        name={name}
        placeholder={label}
        required={isRequired}
        className="relative rounded outline-none border-b border-[#F7F7F7] py-[.5rem] w-full text-[16px] leading-[2.4rem]"
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}

export default BorderedInputs;
