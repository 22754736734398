import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../CustomUIElements/Modal/ConfirmationModal'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../store/authStore';
import { HiOutlineSearch } from 'react-icons/hi';
import Table from '../../CustomUIElements/Table/Table';
import ModalContainer from '../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../CustomUIElements/Inputs/Inputs';
import ReactQuill from 'react-quill';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { addCourse, deleteCourse, fetchCourses, updateCourse } from '../../utils/courseFunctions';
import { useNavigate } from 'react-router-dom';
import parse from "html-react-parser"
import { getMyCourses } from '../../utils/students';
import { ChromePicker } from 'react-color';
import useWindowSize from '../../hooks/useWindowSize';

function MyCourses() {
    const [searchQuery, setSearchQuery] = useState("");
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const { userProfile, removeUser } = useAuthStore()
    const [rowData, setRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    // const [loading, setLoading] = useState([)
    const [formValues, setFormValues] = useState({
        name: "",
        color: "",
        courseCode: ""
    })
    const [courseOutline, setCourseOutline] = useState("")
    const [courses, setCourses] = useState([])
    const [updatingCourse, setIsUpdatingCourse] = useState(false)
    const [openModal, setOpenModal] = useState({
        modalName: "",
        showModal: false,
    });

    const navigate = useNavigate()
    const queryClient = useQueryClient();

    const {width} = useWindowSize()

    const refetchCourses = async () => {
        await queryClient.invalidateQueries(['fetchCourses', userProfile?.user?.learningInstitutionId]);
        refetch();
    }

    const { isLoading: loadingLCourses } = useQuery("getMyCourses", getMyCourses, {
        enabled: userProfile?.user?.userRole === "Lecturer",
        refetchOnWindowFocus: true,
        retry: false,
        refetchInterval: false,
        onSuccess: ({ data }) => {
            console.log(data.data)
            setCourses(data?.data?.courses)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    const { isLoading: loadingCourses, refetch } = useQuery(
        ['fetchCourses', userProfile?.user?.learningInstitutionId], // Query key
        () => fetchCourses(userProfile?.user?.learningInstitutionId), // Fetch function
        {
            enabled: !!userProfile?.user?.learningInstitutionId && userProfile?.user?.userRole === "LearningInstitution",
            refetchOnWindowFocus: false,
            retry: false,
            refetchInterval: false,
            onSuccess: ({ data }) => {
                let res = data?.data
                res = res?.map((r) => {
                    return (
                        { ...r, description: parse(r?.description) }
                    )
                })
                console.log(res);
                setCourses(data?.data);
            },
            onError: (error) => {
                if (error?.response?.status === 401) {
                    removeUser();
                    window.location.href = "/login";
                }
            }
        }
    );


    const { isLoading: deleting } = useMutation(() => deleteCourse(rowData?.id), {
        onSuccess: ({ data }) => {
            refetchCourses()
            toast.success("Course deleted")
            setOpenModal({ modalName: "", showModal: false })
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
            if (response?.data?.status === 401) {
                removeUser()
                navigate("/ogin")
            }
        },
    });
    // console.log(rowData)
    const { mutate, isLoading: addingCourse } = useMutation(addCourse, {
        onSuccess: ({ data }) => {
            setOpenInviteModal(false)
            toast.success(`Course added successfully`)
            setFormValues({})
            setCourseOutline("")
            refetchCourses()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });
    // const { mutate: mutateCourse, isLoading: updatingCourse } = useMutation(updateCourse, {
    //     onSuccess: () => {
    //         setOpenModal({ modalName: "", showModal: false })
    //         refetchCourses()
    //         setFormValues({})
    //         toast.success(`Course updated successfully`)
    //     },
    //     onError: ({ response }) => {
    //         toast.error(`${response?.data?.status}`);
    //     },
    // });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            name: formValues.name,
            description: courseOutline,
            colour: formValues.color,
            learningInstitutionId: userProfile.user.learningInstitutionId,
            courseCode: formValues.courseCode
        });
    };

    const handleUpdateCourse = async (e) => {
        e.preventDefault()
        setIsUpdatingCourse(true)
        await updateCourse({
            id: rowData?.id,
            name: formValues.name,
            description: courseOutline,
            colour: formValues.color,
            learningInstitutionId: userProfile.user.learningInstitutionId,
            courseCode: formValues.courseCode
        }).then(() => {
            toast.success("Course updated successfully")
            setOpenModal({ modalName: "", showModal: false })
            refetchCourses()
            setFormValues({})
            setCourseOutline("")
            setIsUpdatingCourse(false)
        }).catch((err) => {
            setIsUpdatingCourse(false)
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
        })
    }

    // console.log(rowData)
    useEffect(() => {
        if (rowData) {
            setFormValues({
                name: rowData?.name,
                color: rowData?.colour,
                courseCode: rowData?.courseCode
            })
            setCourseOutline(rowData?.description)
        }
    }, [rowData])

    const handleColorChange = (color) => {
        setFormValues({ ...formValues, color: color.hex })
    }

    return (
        <div className="relative top-[8%] px-8 md:px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold my-[3rem]">Courses</h1>
            <div className='flex flex-col md:flex-row justify-between items-center mb-8 gap-4'>
                <div className="relative">
                    <input
                        className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="absolute top-[14px] left-[15px] text-[20px]">
                        <HiOutlineSearch />
                    </span>
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-[150px]'>
                        <Buttons text="Add course" variant="primary" handleClick={() => setOpenInviteModal(true)} />
                    </div>
                </div>
            </div>
            <Table
                header={
                    [
                        { name: "Course Name", identifier: "name" },
                        { name: "Course Code", identifier: "courseCode" },
                        // { name: "Lecturer", identifier: "emailAddress" },
                    ]}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    showPagination: true,
                    actions: {
                        delete: true,
                        edit: true,
                        hideActivate: true
                    }
                }}
                data={courses}
                openModal={openModal}
                setOpenModal={setOpenModal}
                searchQuery={searchQuery}
                rowData={rowData}
                setRowData={setRowData}
                loading={loadingCourses || loadingLCourses}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                path={userProfile?.user?.userRole === "Lecturer" ? `/lecturer/dashboard/my-courses` : `/li/dashboard/my-courses`}
            />

            <ConfirmationModal
                showModal={openModal?.modalName === "disable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to de-activate this course?"

            />
            <ConfirmationModal
                showModal={openModal?.modalName === "enable" && openModal?.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate this course?"

            />

            {openInviteModal && (
                <ModalContainer width={width < 768 ? "95%" :"800px"} height={width < 768 ? "70vh" :"600px"}>
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Add Course</h3>
                            <LiaTimesSolid
                                onClick={() => {
                                    setOpenInviteModal(false);
                                    setFormValues({})
                                    setCourseOutline("")
                                }
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form action="" className='' id='course-form' onSubmit={handleSubmit}>
                            <Inputs type="text" label="Course Name" minLength={1} value={formValues?.name} handleChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} isRequired={true} />
                            <ReactQuill
                                theme="snow"
                                value={courseOutline}
                                onChange={setCourseOutline}
                                className="course-quill !text-[18px] min-h-[14rem] !relative my-4 !rounded-2xl bg-[#F7F7F7] !border-none !h-[30rem] !py-4 !overflow-y-scroll"
                                placeholder="Course Description"
                            />
                            <Inputs type="text" label="Color" minLength={1} value={formValues?.color} handleChange={(e) => setFormValues((prev) => ({ ...prev, color: e.target.value }))} readOnly={true} />
                            <center>
                                <ChromePicker color={formValues?.color} onChange={(color) => handleColorChange(color)} disableAlpha={true} />
                            </center>
                            <div className='my-4'>
                                <Inputs type="text" label="Course Code" minLength={1} value={formValues?.courseCode} handleChange={(e) => setFormValues((prev) => ({ ...prev, courseCode: e.target.value }))} isRequired={true} />
                            </div>
                            <div className='mb-4' />
                            <Buttons text={addingCourse ? "Loading" : "Add Course"} variant={addingCourse ? "disabled" : "primary"} />
                        </form>
                    </div>
                </ModalContainer>
            )}
            {openModal.showModal && openModal.modalName === "edit" && (
                <ModalContainer width={width < 768 ? "95%" :"800px"} height={width < 768 ? "80vh" :"600px"}>
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Edit Course</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenModal({ modalName: "", showModal: false })
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form onSubmit={handleUpdateCourse}>
                            <Inputs type="text" label="Course Name" minLength={1} value={formValues?.name} handleChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} isRequired={true} />
                            <ReactQuill
                                theme="snow"
                                value={courseOutline}
                                onChange={setCourseOutline}
                                className="course-quill !text-[18px] h-[14rem] !relative my-4 !rounded-2xl bg-[#F7F7F7] !border-none"
                                placeholder="Course Description"
                            />
                            <Inputs type="text" label="Color" minLength={1} value={formValues?.color} handleChange={(e) => setFormValues((prev) => ({ ...prev, color: e.target.value }))} isRequired={true} />
                            <center>
                                <ChromePicker color={formValues?.color} onChange={(color) => handleColorChange(color)} disableAlpha={true} />
                            </center>
                            <div className='my-4'>
                                <Inputs type="text" label="Course Code" minLength={1} value={formValues?.courseCode} handleChange={(e) => setFormValues((prev) => ({ ...prev, courseCode: e.target.value }))} isRequired={true} />
                            </div>
                            <div className='mb-4' />
                            <Buttons text={updatingCourse ? "Loading" : "Update Course"} variant={updatingCourse ? "disabled" : "primary"} />
                        </form>
                    </div>
                </ModalContainer>
            )}
            {openModal.showModal && openModal.modalName === "delete" && (
                <ModalContainer width={width < 768 ? "95%" :"800px"} height={width < 768 ? "90vh" :"600px"}>
                    <ConfirmationModal
                        showModal={openModal.modalName === "delete" && openModal.showModal}
                        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                        message="Are you sure you want to delete this course?"
                        onConfirm={() => deleteCourse(rowData?.id)}
                        loading={deleting}
                    />
                </ModalContainer>
            )}


        </div>
    )
}

export default MyCourses