import React from "react";

const ConfirmationModal = ({ message, onConfirm, onCancel, showModal, loading }) => {

  return (
    <div
      className={` inset-0 w-full h-screen flex items-center z-20 justify-center bg-black bg-opacity-50 transition-opacity ${
        showModal ? "opacity-100 fixed" : "opacity-0 hidden"
      } duration-300`}
    >
      <div
        className={`bg-white p-4 rounded-md shadow w-[95%] md:w-[40%] transform transition-transform ${
          showModal ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <p className="text-[1.6rem]">{message}</p>
        <div className="flex justify-end mt-4">
          <button
            className="mr-2 px-4 py-2 border rounded text-[1.4rem]"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#008FD2] text-white rounded text-[1.4rem]"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? "Please wait..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
