import React from "react";
import useIdeaStore from "../../store/ideaStore";
import moment from "moment";
import findCategory from "../../utils/findCategory";
import { Link } from "react-router-dom";

function IdeasCard({ category, name, dateAdded, id, progressScore, src }) {
  const { categories } = useIdeaStore();
  const cate = findCategory(categories, category);
  return (
    <Link to={src ? `idea/${id}` : `/my-ideas/${id}`}>
      <div className="p-[1.6rem] flex justify-between items-center mb-[1.2rem] bg-[#FBFBFB] rounded-xl">
        <div>
          <div
            style={{ background: `${cate?.colour}` }}
            className={`w-max py-[.6rem] px-[1.2rem] rounded-[1.5rem] text-[1.2rem] text-[#ffffff] font-medium mb-[.5rem]`}
          >
            {cate?.name}
          </div>
          <h3 className="text-[#103240] text-[1.7rem] font-medium">{name}</h3>
          <p className="text-[1.2rem] text-[#10324080]">
            {moment(dateAdded).format("D MMM [at] h:mma")}
          </p>
        </div>
        <div className="flex flex-col items-center text-[#59C159]">
          <h3 className="text-[3.2rem] font-semibold">
            {Math.round(progressScore)}<sup>%</sup>
          </h3>
          <p className="text-[1.4rem] font-medium">Progress</p>
        </div>
      </div>
    </Link>
  );
}

export default IdeasCard;
