import React from "react";
import { useNavigate } from "react-router-dom";
import { FormInputFields } from "./FormInputFields";
import Inputs from "../../CustomUIElements/Inputs/Inputs";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { Link } from "react-router-dom";
// import { FcGoogle } from "react-icons/fc";
// import { GoogleLogin, googleLogout } from "@react-oauth/google";
// import { useGoogleLogin } from "@react-oauth/google";
import { useMutation } from "react-query";
import { createUserAccount } from "../../utils/userAccountFn";
import { toast } from "react-toastify";
import useAuthStore from "../../store/authStore";

function GetStarted({ formValues, setFormValues }) {
  const navigate = useNavigate();
  const { saveUserProfile } = useAuthStore();
  const { mutate, isLoading } = useMutation(createUserAccount, {
    onSuccess: ({ data }) => {
      saveUserProfile(data.data);
      navigate("/login");
      toast.success(`${data.status}`);
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
    },
  });

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    const { organisationName, firstName, lastName, email, phoneNumber, password, confirmPassword } = formValues;
    if (!emailPattern.test(email)) {
      toast.warn('Please enter a valid email address');
      return;
    }
    mutate({
      organisationName,
      firstName,
      lastName,
      emailAddress: email,
      mobileNumber: phoneNumber,
      password,
      confirmPassword,
      userRoleEnum: formValues.accountType.replace(" ", "")
    });
  };

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => console.log(tokenResponse),
  // });

  return (
    <>
      <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[21.2rem] mx-auto">
        Create your account
      </h3>
      <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
        {FormInputFields.map(({ name, label, type, isRequired }) => (
          <Inputs
            key={name}
            type={type}
            label={label}
            name={name}
            isRequired={isRequired}
            value={formValues[name]}
            handleChange={(e) =>
              setFormValues({
                ...formValues,
                [e.target.name]:
                  name === "firstName" || name === "lastName"
                    ? e.target.value.replace(/[^A-Za-z]/g, "").replace(" ", "")
                    : name === "phoneNumber"
                      ? e.target.value.replace(/\D/g, "")
                      : name === "email"
                        ? e.target.value.replace(/\s/g, "") // Remove spaces
                        : e.target.value,
              })
            }
            minLength={name === "phoneNumber" ? 11 : 3}
            maxLength={name === "phoneNumber" && 12}
          />
        ))}
        <div className="flex gap-[1.2rem] text-[1.3rem] text-[#455065]">
          <input
            type="checkbox"
            className="w-[1.8rem] h-[1.8rem]"
            checked={formValues?.acceptTnC}
            onChange={() =>
              setFormValues({
                ...formValues,
                acceptTnC: formValues?.acceptTnC ? !formValues.acceptTnC : true,
              })
            }
          />
          <p className=" font-medium">
            By creating an account, I agree to Hydreate{" "}
            <Link to="/privacy-policy" className="text-[#008FD2]">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/terms-and-conditions" className="text-[#008FD2]">
              Terms
            </Link>
          </p>
        </div>
        <div className="!mt-[5rem]">
          <Buttons
            variant={
              formValues?.firstName &&
                formValues?.lastName &&
                formValues?.email &&
                formValues?.phoneNumber &&
                formValues?.password &&
                formValues?.confirmPassword &&
                formValues?.password === formValues?.confirmPassword &&
                formValues?.acceptTnC &&
                !isLoading
                ? "primary"
                : "disabled"
            }
            type="submit"
            text={isLoading ? "Submitting..." : "Get Started"}
          />
        </div>
      </form>
      <div>
        {/* <div className="!my-[3.2rem] flex justify-center items-center gap-[1.9rem] px-20">
          <div className="w-full h-[1px] bg-[#E4E4E4]" />
          <p className="text-[1.6rem] text-[#26323899]">or</p>
          <div className="w-full h-[1px] bg-[#E4E4E4]" />
        </div>
        <div className="w-full">
          <div
            onClick={() => login()}
            className="flex border border-[#E4E4E4] py-[1.6rem] pl-[1.2rem] items-center justify-start gap-[6rem] md:gap-[12rem] cursor-pointer rounded-[12px]"
          >
            <span>
              <FcGoogle size={24} />
            </span>
            <span className="text-[1.6rem] font-semibold text-[#2A2E43]">
              Continue with Google
            </span>
          </div>
        </div> */}
        <div className="mt-[3.4rem] text-center">
          <p className="text-[#2A2E43] text-[1.4rem]">
            Already have an account?{" "}
            <Link to="/login" className="text-[#008FD2]">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default GetStarted;
