import React, { useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { GrClose } from "react-icons/gr";

function CreateIdeaHeader({ setOpenCreateModal, progressBar, action, showBanner, setShowBanner }) {
  
  return (
    <>
      {showBanner &&
        <div className="bg-red-500 text-white shadow-md p-4 rounded-medium z-[10000] max-w-fit mx-auto text-[1.6rem] fixed top-0 right-0 left-0 flex items-center gap-[1.6rem]">
          <p>You've reacheed the maximum number of ideas you can add on your subscription. You have more ideas?. <a href="/plans" className="underline">Upgrade Now</a></p>
          <span className="cursor-pointer" onClick={() => setShowBanner(false)} >
            <FaRegTimesCircle color="white" size={18} />
          </span>
        </div >
      }
      <div className="h-[7rem] bg-white shadow-md">
        <span className="flex justify-between items-center h-full px-[3.6rem] font-thin">
          <div className="hidden md:flex max-h-auto  md:max-h-[8rem]  justify-between items-center uppercase py-[1.4rem] md:py-[2.4rem] w-fit">
            <a href="/dashboard">
              <img
                src="/assets/logo.svg"
                alt="HYDREATE"
                className="md:ml-6 lg:ml-0"
              />
            </a>
          </div>
          <p className="text-[2rem] font-semibold">{action}</p>
          <GrClose
            size={20}
            className="cursor-pointer"
            onClick={() => setOpenCreateModal(false)}
          />
        </span>
      </div>
      {
        progressBar && (
          <div
            className={`h-[0.6rem] bg-[#008FD2] rounded-[10rem]`}
            style={{ width: `${progressBar + "%"}` }}
          />
        )
      }
    </>
  );
}

export default CreateIdeaHeader;
