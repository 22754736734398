import React, { useState } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../../CustomUIElements/Inputs/Inputs';
import { toast } from 'react-toastify';
import { addSubscription } from '../../../utils/addSubscription';
import { HiOutlineSearch } from 'react-icons/hi';
import MyCategoriesTable from './MyCategoriesTable';
import { fetchMyCategories } from '../../../utils/fetchMyCategories';
import AddCategoryForm from '../categories/AddCategoryForm';
import ConfirmationModal from '../../../CustomUIElements/Modal/ConfirmationModal';
import { activateEntityStatus } from '../../../utils/activateEntityStatus';

function MyCategoriesHome() {
    const [openModal, setOpenModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    const { removeUser } = useAuthStore()
    // const [isLoading, setIsLoading] = useState(false);
    const [myCategories, setMyCategories] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [desc, setDesc] = useState("")
    const [searchQuery, setSearchQuery] = useState("");

    const { isLoading: loadingFunnels, isRefetching, refetch } = useQuery("fetchMyCategories", fetchMyCategories, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setMyCategories(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    const { mutate, isLoading } = useMutation(addSubscription, {
        onSuccess: ({ data }) => {
            setOpenModal(false)
            toast.success(`${data.status}`)
            setFormValues({})
            refetch()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            topic: formValues?.topic,
            description: desc,
            colourCode: formValues?.colourCode,
            price: parseInt(formValues?.price),
            expiryInMonths: formValues?.expiryInMonths,
            noOfIdeas: formValues?.noOfIdeas
        });
    };

    // const mutation = useMutation(deleteSubscription);

    // const handleBulkDelete = async () => {
    //     try {
    //         await Promise.all(selectedRows?.map(async (row) => {
    //             // Call the mutation function with the ID
    //             await mutation.mutateAsync(row?.id);
    //         }));
    //         toast.success("Operation completed successfully");
    //         refetch();
    //         setOpenModal({ modalName: "", showModal: false });
    //     } catch (error) {
    //         console.error("Bulk delete error:", error);
    //         toast.error("An error occurred during bulk delete operation");
    //     }
    // };
    const activateMutation = useMutation({
        mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
    })
    const handleBulkActivate = async () => {
        try {
            await Promise.all(selectedRows?.map(async (row) => {
                // Call the mutation function with the ID
                console.log(row?.id)
                await activateMutation.mutateAsync({ entityName: "Subscriptions", id: row?.id });
            }));
            toast.success("Operation completed successfully");
            refetch();
            setOpenModal({ modalName: "", showModal: false });
        } catch (error) {
            console.error("Bulk delete error:", error);
            toast.error("An error occurred during bulk activate operation");
        }
    };

    return (
        <div className='min-h-[86vh]'>
            <div className="flex  flex-col lg:flex-rowjustify-between items-center  mb-[2rem] gap-y-[20px] lg:gap-y-0">
                <div className="flex flex-col lg:flex-row items-center gap-4">
                    <h1 className="text-[20px] font-semibold">Manage My Categories</h1>
                    <div className="relative">
                        <input
                            className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <span className="absolute top-[14px] left-[15px] text-[20px]">
                            <HiOutlineSearch />
                        </span>
                    </div>
                </div>
                <div className="w-fit">
                    <Buttons
                        type="button"
                        text="Add New Category"
                        variant="primary"
                        handleClick={() => setOpenModal({ modalName: "add", showModal: true })}
                    />
                </div>
            </div>
            {selectedRows?.length > 0 &&
                <div className="flex gap-2 items-center">
                    <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
                    <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
                </div>
            }
            <div className="mt-4 mb-8">
                <MyCategoriesTable data={myCategories} loading={loadingFunnels || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
            </div>
            {openModal.modalName === "add" && openModal.showModal && (
                <ModalContainer height="auto">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Add New Category</h3>
                            <LiaTimesSolid
                                onClick={() => setOpenModal(false)}
                                className="cursor-pointer"
                            />
                        </div>
                        <AddCategoryForm fetchCategories={refetch} setOpenModal={setOpenModal} />
                    </div>
                </ModalContainer>
            )}
            {/* <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these users?"
        onConfirm={handleBulkDelete}
      /> */}
            <ConfirmationModal
                showModal={openModal.modalName === "activate" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate these users?"
                onConfirm={handleBulkActivate}
            // loading={deletingUser}
            />
        </div>

    )
}

export default MyCategoriesHome