import React from "react";
import { Link } from "react-router-dom";

function SettingsLayout({ children, settingsTab, setSettingsTab }) {
  return (
    <div className="app-container">
      <h3 className="text-[4rem] text-[#103240] font-bold py-[3.8rem]">
        Settings
      </h3>
      <div className="flex gap-[4rem]">
        <div className="space-y-[1.6rem] w-[20rem]">
          {sideNavbar?.map(({ name }) => (
            <p
              key={name}
              className={`${settingsTab === name
                  ? "text-[#103240] font-semibold"
                  : "text-[#103240B2] font-medium"
                } text-[1.6rem] cursor-pointer w-full`}
              onClick={() => setSettingsTab(name)}
            >
              {name}
            </p>
          ))}
        </div>
        {children}
      </div>
    </div>
  );
}

export default SettingsLayout;

const sideNavbar = [
  {
    name: "Personal Details",
  },
  {
    name: "Security and Privacy",
  },
  {
    name: "Payments and Plans",
  },
  {
    name: "Payment History",
  },
  {
    name: "Subscription Plans",
  },
];
