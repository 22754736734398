import React, { useEffect, useState } from "react";
import Inputs from "../../CustomUIElements/Inputs/Inputs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { useMutation } from "react-query";
import { loginUser } from "../../utils/userAccountFn";
import useAuthStore from "../../store/authStore";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import {useTheme} from "../../context/ThemeContext"
import {getSetting} from "../../utils/updateSetting"

function Login() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const { saveUserProfile, userProfile } = useAuthStore();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false)
  const returnUrl = searchParams.get('return_to')
  const {setLogo, setColor, getBaseURL} = useTheme()
  const { mutate } = useMutation(loginUser, {
    onSuccess: ({ data }) => {
      saveUserProfile(data.data);
      if (returnUrl) {
        navigate(returnUrl)
      } else {
        if (data?.data?.user?.userRole === "LearningInstitution") {
          navigate("/li/dashboard");
        } else if (data?.data?.user?.userRole === "Student") {
          navigate("/student/dashboard");
        } else if (data?.data?.user?.userRole === "Lecturer") {
          navigate("/lecturer/dashboard");
        } else {
          navigate("/dashboard");
        }
      }
      signInWithEmailAndPassword(auth, process.env.REACT_APP_FIREBASE_EMAIL, process.env.REACT_APP_FIREBASE_PASSWORD).then((userCred) => {
      }).catch((error) => {
        console.log(error)
      }).finally(()=>{
        setIsLoading(false)
      })
      toast.success(`${data.status}`);
      window.localStorage.setItem("token", data.data.token);
      getSetting().then((res) => {
        const d = res?.data?.data;
        console.log(d);
        setLogo(d?.logoUrl);
        setColor({
          backgroundColor: d?.primaryColour ? d?.primaryColour : "#008FD2",
          color: "#FFF",
        });
      });
    },
    onError: ({ response }) => {
      toast.error(`${response?.data?.status}`);
      setIsLoading(false)
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    mutate({
      email: formValues?.email,
      password: formValues?.password,
    });
  };
  useEffect(() => {
    if (userProfile?.token && userProfile?.user?.userRole === "Student") {
      navigate("/student/dashboard")
    } else if (userProfile?.token && userProfile?.user?.userRole === "LearningInstitution") {
      navigate("/li/dashboard");
    }
    else if (userProfile?.token && userProfile?.user?.userRole === "Lecturer") {
      navigate("/lecturer/dashboard");
    }
    else if (userProfile?.token) {
      navigate("/dashboard");
    }

  }, []);

  useEffect(() => {
    getBaseURL();
}, []);

  return (
    <div className="app-container  md:w-[36rem] mx-auto flex flex-col mb-[5rem] ">
      <h3 className="text-[3.8rem] text-center leading-[4.8rem] font-bold text-[#103240] mb-[4.8rem] w-[25.2rem] mx-auto mt-[4rem]">
        Log into your account
      </h3>
      <form className="space-y-[1.6rem]" onSubmit={handleSubmit}>
        <Inputs
          type="email"
          label="Email Address"
          isRequired={true}
          name="email"
          value={formValues?.email}
          handleChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
        />
        <Inputs
          type="password"
          label="Password"
          isRequired={true}
          name="password"
          value={formValues?.password}
          handleChange={(e) =>
            setFormValues({ ...formValues, password: e.target.value })
          }
        />
        <Link
          to="/forgot-password"
          className="font-medium text-[1.4rem] text-[#008FD2] flex justify-end"
        >
          <p>Forgot Password?</p>
        </Link>
        <div>
          <Buttons
            type="submit"
            variant={
              formValues?.email && formValues?.password && !isLoading
                ? "primary"
                : "disabled"
            }
            text={isLoading ? "Please wait..." : "Log In"}
          />
        </div>
        <div>
          {/* <div className="!my-[3.2rem] flex justify-center items-center gap-[1.9rem] px-20">
          <div className="w-full h-[1px] bg-[#E4E4E4]" />
          <p className="text-[1.6rem] text-[#26323899]">or</p>
          <div className="w-full h-[1px] bg-[#E4E4E4]" />
        </div>
        <div className="w-full">
          <div
            onClick={() => login()}
            className="flex border border-[#E4E4E4] py-[1.6rem] pl-[1.2rem] items-center justify-start gap-[6rem] md:gap-[12rem] cursor-pointer rounded-[12px]"
          >
            <span>
              <FcGoogle size={24} />
            </span>
            <span className="text-[1.6rem] font-semibold text-[#2A2E43]">
              Continue with Google
            </span>
          </div>
        </div> */}
          <div className="mt-[3.4rem] text-center">
            <p className="text-[#2A2E43] text-[1.4rem]">
              Don't have an account?{" "}
              <Link to="/get-started" className="text-[#008FD2]">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
