// import { header } from "../libs/authHeader";
import apiClient from "../libs/http-common";
export const addCategory = async (info) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Categories/add", info, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
