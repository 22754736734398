import React from "react";

function Badge({ color, count }) {
  return (
    <p
      style={{ backgroundColor: color || "blue" }}
      className={`animate-bounce w-[13px] h-[13px] rounded-full text-white absolute -top-2 -right-1 flex justify-center items-center`}
    >
      {count}
    </p>
  );
}

export default Badge;
