import { useEffect, useState } from "react";
import { Buttons } from "../../CustomUIElements/Buttons/Buttons";
import { Link } from "react-router-dom";

function Hero() {
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  useEffect(() => {
    const changeImgIndex = () => {
      setTimeout(() => {
        if (currentImgIndex === HeroImgData.length - 1) {
          setCurrentImgIndex(0);
        } else {
          setCurrentImgIndex(currentImgIndex + 1);
        }
      }, 5000);
    };
    changeImgIndex();
  }, [currentImgIndex]);
  const goToSlide = (index) => {
    setCurrentImgIndex(index);
  };
  return (
    <section className="app-container" id="home">
      <article className=" my-[4.8rem] lg:pl-[5rem] text-[#103240]">
        <div className="flex-col lg:flex-row flex gap-[4rem] items-center">
          <div className="flex flex-col gap-[3.2rem] basis-1/2">
            <h2 className="text-[5.6rem] md:text-[8rem] font-extrabold  leading-[7rem] md:leading-[9.8rem]">
              Dream. Plan. Achieve.
            </h2>
            <p className="text-[2.4rem] leading-[3.36rem] font-medium mb-[2.4rem]">
              Hydreate inspires you to dream higher, plan to reach beyond
              potential, bring team members together and achieve success.
            </p>
            <Link to="/get-started" className="w-[21.8rem]">
              <Buttons variant="primary" text="GET STARTED" />
            </Link>
          </div>
          <div className="basis-1/2 flex overflow-hidden">
            <picture className="min-w-full max-h-[480px]">
              <img
                src={HeroImgData[currentImgIndex].imgUrl}
                alt=""
                className="w-full"
              />
            </picture>
            <div className="flex absolute bottom-0 gap-[0.8rem]">
              <span
                className={`w-2 h-2 ${
                  currentImgIndex === 0 ? "bg-[#008FD2]" : "bg-[#E4E4E4]"
                } rounded-full cursor-pointer`}
                onClick={() => goToSlide(0)}
              ></span>
              <span
                className={`w-2 h-2 ${
                  currentImgIndex === 1 ? "bg-[#008FD2]" : "bg-[#E4E4E4]"
                } rounded-full cursor-pointer`}
                onClick={() => goToSlide(1)}
              ></span>
              <span
                className={`w-2 h-2 ${
                  currentImgIndex === 2 ? "bg-[#008FD2]" : "bg-[#E4E4E4]"
                } rounded-full cursor-pointer`}
                onClick={() => goToSlide(2)}
              ></span>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
export default Hero;

const HeroImgData = [
  {
    id: 0,
    imgUrl: "/assets/hero-img.svg",
  },
  {
    id: 0,
    imgUrl: "/assets/hero-img-2.svg",
  },
  {
    id: 0,
    imgUrl: "/assets/hero-img-3.svg",
  },
];
