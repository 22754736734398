import React from "react";
import CreateIdeaHeader from "../Ideas/CreateIdea/CreateIdeaHeader";
import SettingsLayout from "../../layout/SettingsLayout";
import PersonalDetails from "./PersonalDetails";
import SecurityAndPrivacy from "./SecurityAndPrivacy";
import PaymentsAndPlans from "./PaymentsAndPlans";
import PaymentHistory from "./PaymentHistory";
import SubscriptionPlans from "./SubscriptionPlans";

function AccountSettings({ setOpenSettings, settingsTab, setSettingsTab }) {
  return (
    <div className="fixed inset-0 z-50 h-screen bg-white">
      <CreateIdeaHeader
        setOpenCreateModal={setOpenSettings}
        action="Account Settings"
      />
      <SettingsLayout settingsTab={settingsTab} setSettingsTab={setSettingsTab}>
        {settingsTab === "Personal Details" && (
          <PersonalDetails setOpenSettings={setOpenSettings} />
        )}
        {settingsTab === "Security and Privacy" && (
          <SecurityAndPrivacy setOpenSettings={setOpenSettings} />
        )}
        {settingsTab === "Payments and Plans" && (
          <PaymentsAndPlans setSettingsTab={setSettingsTab} />
        )}
        {settingsTab === "Payment History" && (
          <PaymentHistory setSettingsTab={setSettingsTab} />
        )}
        {settingsTab === "Subscription Plans" && (
          <SubscriptionPlans setSettingsTab={setSettingsTab} />
        )}
      </SettingsLayout>
    </div>
  );
}

export default AccountSettings;
