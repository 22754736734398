import React, { useState } from "react";
import CreateIdeaHeader from "./CreateIdeaHeader";
import CreateIdeaBody from "./CreateIdeaBody";

function CreateIdea({ setOpenCreateModal, openCreateModal, refetch }) {
  const [progressBar, setProgressBar] = useState(33);
  const [showBanner, setShowBanner] = useState(false)
  return (
    <div
      className={`fixed inset-0 z-[100] h-screen bg-white ${openCreateModal ? "visible" : "invisible"
        }`}
    >
      <CreateIdeaHeader
        setOpenCreateModal={setOpenCreateModal}
        progressBar={progressBar}
        showBanner={showBanner}
        setShowBanner={setShowBanner}
      />
      <CreateIdeaBody setProgressBar={setProgressBar} setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} setShowBanner={setShowBanner} refetch={refetch} />
    </div>
  );
}

export default CreateIdea;
