function Teams() {
  return (
    <section className="app-container" id="teams">
      <div className="flex flex-col gap-[8rem] mt-[11.6rem] justify-center items-center">
        <div className="flex flex-col gap-[2.4rem] text-center">
          <h3 className="max-w-[59rem] mx-auto text-[5.7rem] md:text-[8rem] leading-[7rem] md:leading-[10.6rem] font-bold text-[#103240]">
            Our Awesome{" "}
            <span className="bg-[#EDF4FC] text-[#008FD2] px-[1.5rem] py-[0.4rem] rounded-full">
              Team
            </span>
          </h3>
          <p className="max-w-[76.8rem] text-[1.7rem] font-medium mx-auto ">
            We’re a diverse and determined team of innovators, movers and
            shakers, passionate about enabling our customers to do their best
            work.
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-[3.2rem]">
          <div className="border border-[#D5F1FF] rounded-[2.5rem] w-[25rem] h-[40rem] max-h-[40rem] flex flex-col justify-center items-center gap-[6.3rem]">
            <picture>
              <img src="/assets/Avatar-12.png" alt="" />
            </picture>
            <div className="text-center">
              <span className="text-[#110F42] text-[2.4rem] font-bold leading-[3rem]">
                Adefolarin Adeniji
              </span>
              <div className="flex justify-between items-center mt-2 text-[#103240B2] text-left">
                <span className="text-[1.7rem] leading-[2.8rem] font-medium">
                  CEO
                </span>
                <a
                  href="https://www.linkedin.com/in/adefolarinadeniji/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/linkedin.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="border border-[#D5F1FF] rounded-[2.5rem] w-[25rem] h-[40rem] max-h-[40rem] flex flex-col justify-center items-center gap-[6.3rem]">
            <picture>
              <img src="/assets/Avatar.png" alt="" />
            </picture>
            <div className="text-center">
              <span className="text-[#110F42] text-[2.4rem] font-bold leading-[3rem]">
                Oluwaseun Afolabi
              </span>
              <div className="flex justify-between items-center mt-2 text-[#103240B2]  text-left">
                <span className="text-[1.7rem] leading-[2.8rem] font-medium">
                  CTO
                </span>
                <a
                  href="https://www.linkedin.com/in/afolabi-seun/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/linkedin.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="border border-[#D5F1FF] rounded-[2.5rem] w-[25rem] h-[40rem] max-h-[40rem] flex flex-col justify-center items-center gap-[6.3rem]">
            <picture>
              <img src="/assets/Avatar2.png" alt="" />
            </picture>
            <div className="text-center">
              <span className="text-[#110F42] text-[2.4rem] font-bold leading-[3rem]">
                Ayokumi Olufisayo
              </span>
              <div className="flex justify-between items-center mt-2 text-[#103240B2]  text-left">
                <span className="text-[1.7rem] leading-[2.8rem] font-medium">
                  Legal
                </span>
                <a
                  href="https://www.linkedin.com/in/ayokunmi-olufisayo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/linkedin.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="border border-[#D5F1FF] rounded-[2.5rem] w-[25rem] h-[40rem] max-h-[40rem] flex flex-col justify-center items-center gap-[6.3rem]">
            <picture>
              <img src="/assets/Avatar-M.png" alt="" />
            </picture>
            <div className="text-center">
              <span className="text-[#110F42] text-[2.4rem] font-bold leading-[3rem]">
                Mishael Dada
              </span>
              <div className="flex justify-between items-center mt-2 text-[#103240B2] text-left">
                <span className="text-[1.7rem] leading-[2.8rem] font-medium">
                  Frontend
                </span>
                <a
                  href="https://www.linkedin.com/in/mishael-dada/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/linkedin.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Teams;
