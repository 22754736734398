import React, { useState } from "react";
import useAuthStore from "../../store/authStore";
import { AiFillBulb } from "react-icons/ai";
import CreateIdea from "../Ideas/CreateIdea/CreateIdea";

function DashBoardHomeOnRegister() {
  const { userProfile } = useAuthStore();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  return (
    <div className="app-container mt-[3.2rem]">
      <div className="w-[82rem] mx-auto pb-[1.7rem] border-b mb-[8rem]">
        <p className="text-[3.2rem] font-medium text-[#103240]">
          Hi{" "}
          <span className="font-semibold">{userProfile?.firstName}</span>
        </p>
      </div>
      <div className="w-[36rem] mx-auto flex flex-col items-center justify-center">
        <div className="mb-[3.2rem]">
          <img src="/assets/welcome.svg" alt="" />
        </div>
        <h3 className="text-2xl font-semibold text-[#103240] mb-[1.2rem]">
          Create Your First Idea
        </h3>
        <p className="text-center text-[1.4rem] text-[#103240B2] mb-[3.2rem]">
          Let’s get you started real quick. Click on the “Create button” to kick
          start your dream
        </p>
        <div className="w-full">
          <div
            className="bg-[#008FD2] flex py-[1.6rem] pl-[1.2rem] items-center justify-start gap-[6rem] md:gap-[10rem] cursor-pointer rounded-[1.5rem]"
            onClick={() => setOpenCreateModal(true)}
          >
            <span>
              <AiFillBulb size={24} color="white" />
            </span>
            <span className="text-[1.6rem] font-semibold text-white">
              Create Idea
            </span>
          </div>
        </div>
      </div>
      {openCreateModal && <CreateIdea setOpenCreateModal={setOpenCreateModal}  openCreateModal={openCreateModal} />}
    </div>
  );
}

export default DashBoardHomeOnRegister;
