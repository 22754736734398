import { useEffect } from "react";
import Contact from "../Components/HeroComponent/Contact";
import Footer from "../Components/HeroComponent/Footer";
import Goals from "../Components/HeroComponent/Goals";
import Hero from "../Components/HeroComponent/Hero";
import PreFooter from "../Components/HeroComponent/PreFooter";
import SponsorsBanner from "../Components/HeroComponent/SponsorsBanner";
import Teams from "../Components/HeroComponent/Teams";
import Works from "../Components/HeroComponent/Works";
import Mission from "../Components/HeroComponent/Mission";
import Vision from "../Components/HeroComponent/Vision";
import About from "../Components/HeroComponent/About";

function Homepage({envVar}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="relative z-30 rounded-[5rem] top-[5rem] md:bottom-[-5rem]">
        <Hero />
        <SponsorsBanner />
        <About />
        <Vision />
        <Mission />
        <Goals />
        <Teams />
        <Works />
        <PreFooter />
        <Contact envVar={envVar} />
      </main>
      <Footer />
    </>
  );
}
export default Homepage;
