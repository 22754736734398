import React from "react";

function Heading({questions}) {
  return (
    <div>
      <span className="text-[2rem] text-[#103240] mr-[1.2rem] font-semibold">
        Questions
      </span>
      <span className="text-[#103240B2] text-[1.4rem]">{questions?.filter((q)=>q.isAnswered).length} of {questions?.length} completed</span>
      <p className="w-full md:w-[41rem] text-[1.2rem] text-[#103240E6] mb-[1.6rem]">
        Answer every question as possible as you can, as this questions are very
        crucial for the system to help you create a good business plan for your
        idea
      </p>
    </div>
  );
}

export default Heading;
