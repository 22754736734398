import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { resetPasswordByUser } from "../../../utils/userAccountFn";
import { toast } from "react-toastify";
import { fetchUserProfile } from "../../../utils/fetchUserProfile";
import useAuthStore from "../../../store/authStore";
import Settings from "../../../pages/LIDashboard/Settings";
import apiClient from "../../../libs/http-common"
import { useQuery } from "react-query";
import { fetchSubscriptions } from "../../../utils/fetchSubscriptions";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/ThemeContext";

const Section1 = ({ user }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);




  return (
    <div>
      <div className="flex justify-between border items-center w-full border-slate-300 rounded-lg px-5 py-3">
        <div className="flex items-center gap-2">
          <div>
            {isEditing ? (
              <input
                type="text"
                value=""
                placeholder="sample name"
                className="border-b  outline-none w-fit"
              />
            ) : (
              <h1 className="font-bold">First Name {user?.firstName}</h1>
            )}
            <h2 className="font-medium text-slate-500">{user?.firstName}</h2>
          </div>
        </div>

        {isEditing ? (
          <div className="md:flex items-center gap-1">
            <button
              className="flex items-center gap-1 mb-1 md:mb-0 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>

            <button
              className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <h1>loading...</h1>
              ) : (
                "Save"
              )}
            </button>
          </div>
        ) : (
          <button
            className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
            onClick={() => setIsEditing(true)}
          >
            <h1 className=" hidden md:block">Edit</h1>
            <FiEdit />
          </button>
        )}
      </div>
    </div>
  );
};

const Section2 = ({ user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData,setRowData] = useState()
  const [userDetails, setUserDetails] = useState([]);
  const {userProfile, removeUser} = useAuthStore();
  const [sub, setSub] = useState([])
  const navigate = useNavigate();
  const {theme, themeStyles} = useTheme()

  // const fetchUserProfileFn = async () => {
  //   fetchUserProfile(userProfile?.user?.id)
  //     .then((res) => {
  //       if (res.status === 401) {
  //         navigate("/login");
  //         removeUser()
  //       } else {
  //         setUserDetails(res?.data?.data);
  //         console.log(res)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user profile:", error);
  //     });
  // };

  const { refetch } = useQuery(
    " fetchUserProfile",
    () =>
      fetchUserProfile(userProfile?.user?.id),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      retry: false,
      retryOnMount: true,
      onSuccess: (data) => {
        setUserDetails(data.data?.data);
        
      },
      onError: (error) => {
        const { response } = error;
        if (response?.status === 401) {
          removeUser();
          navigate("/login")
        } else {
          console.error("An error occurred:", error);
        }
      },
    }
  );
  



  // useEffect(() => {
    
  //   if (userProfile?.user?.id) {
      
  //     fetchUserProfileFn()
  //     }
  
  // }, []);
    


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    courseName:"",
    yearOfStudy:"",
    email:"",
  });


  const { } = useQuery("fetchSubscriptions", fetchSubscriptions, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      setSub(data?.data)
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser()
        window.location.href = "/login"
      }
    }
  })

  const findSubID = () => {
    if (sub) {
      const id = sub?.find((s) => s?.topic === userProfile?.subscription)?.id
      return id
    }
  }
  useLayoutEffect(() => {
    setFormData({
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      courseName: user?.studentView?.courseName || "",
      // yearOfStudy: user?.yearOfStudy || "",
      mobileNumber: userDetails?.phoneNumber || "",
      emailAddress: userDetails?.email,
      userId: userDetails?.id,
      subscriptionId: user?.subscriptionId || "",
      isInvestor: userDetails?.isInvestor,
      // subscriptionId: userDetails?.subscriptionId || "",
      description: userDetails?.description,
      investorType: userDetails?.investorType,
      websiteUrl: userDetails?.website,
      subscriptionId: findSubID() || 0,
      organisationName: userProfile?.user?.organisationName

    });
  }, [userDetails]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // console.log(user);

  const handleProfileUpdate = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await apiClient.put("/Accounts/updateUser", formData
    ).then((res) => {
      setIsLoading(false)
      toast.success("Profile updated successfully")
      setIsEditing(false)
      // fetchUserProfileFn()
      refetch()
    }).catch((err) => {
      console.log(err)
      setIsEditing(false)
      Settings(false)
      toast.error("Error updating profile")
      setIsLoading(false)
    })
  }

  return (
    <div>
      <div className="bg-white w-full rounded-xl mt-5 p-10">
        <div className="flex items-center justify-between">
          <h1 className="font-bold mb-3 text-[20px]">Personal Information</h1>
          {isEditing ? (
            <div className="flex items-center gap-1">
              <button
                className="flex items-center gap-1 border md:text-[16px] px-5 border-slate-300 rounded-lg p-2"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>

              <button
                className="flex  text-white md:text-[16px] px-5 items-center gap-1 border border-slate-300 rounded-lg p-2"
                  onClick={handleProfileUpdate}
                disabled={isLoading}
                style={{ backgroundColor: theme.backgroundColor }}
              >
                {isLoading ? (
                  <h1>Saving...</h1>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          ) : (
            <button
              className="flex items-center gap-1 md:text-[16px] px-5 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(true)}
            >
              <h1 className="hidden  md:block">Edit</h1>
              <FiEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 text-[14px] md:text-[16px]">
          <div className="w-[200px]">
            <h1 className="font-bold">First Name</h1>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={formData?.firstName}
                  name="firstName"
                  onChange={handleChange}
                  className="border-b w-fit outline-none"
                />
              </div>
            ) : (
              <h2 className="font-medium text-slate-500">
                {formData?.firstName}
              </h2>
            )}
          </div>
          <div className="w-[200px]">
            <h1 className="font-bold">Last Name</h1>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={formData?.lastName}
                  name="lastName"
                  onChange={handleChange}
                  className="border-b w-fit outline-none"
                />
              </div>
            ) : (
              <h2 className="font-bold text-slate-500">
                {formData.lastName}
              </h2>
            )}
          </div>


          <div>
            <h1 className="font-bold">Email Address</h1>

            <p className="font-medium text-slate-500">
            {formData.emailAddress}
            </p>

          </div>

          <div className="w-[200px]">
            <h1 className="font-bold">Course of Study</h1>
            {isEditing ? (
              <input
                type="text"
                value={formData?.courseName}
                name="courseName"
                onChange={handleChange}
                className="border-b w-fit outline-none"
              />
            ) : (
              <h2 className="font-medium text-slate-500">
              {formData?.courseName}
              </h2>
            )}
          </div>
          <div>
            <h1 className="font-bold">Phone Number</h1>
            {isEditing ? (
              <input
                type="tel"
                value={formData?.mobileNumber}
                name="phoneNumber"
                onChange={handleChange}
                className="border-b w-fit outline-none"
              />
            ) : (
              <p className="font-medium text-slate-500">
              {formData?.mobileNumber}
              </p>
            )}
          </div>

          {/*
          <div>
            <h1 className="font-bold">Year of Study</h1>
            {isEditing ? (
              <select name="" className="border-b w-fit outline-none" id="">
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
                <option value="">5</option>
              </select>
            ) : (
              <p className="font-medium text-slate-500">
                2
              </p>
            )}
          </div>
        */}

        </div>
      </div>
    </div >
  );
};




const ResetPassword = ({ State1, State2, det }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {theme, themeStyles} = useTheme()
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validatePassword = () => {
    const { password, confirmPassword } = formData;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (!passwordRegex.test(password)) {
      toast.error('Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.');
      return false;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }

    return true;
  };

  const handleSave = () => {
    const isValid = validatePassword();
    if (!isValid) {
      return;
    }

    setIsLoading(true);

    resetPasswordByUser(formData)
      .then(() => {
        toast.success("Password updated successfully");
        setFormData({
          password: "",
          confirmPassword: "",
        }
        )
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="bg-white rounded-xl mt-10 p-10">
        <div className="flex items-center justify-between">
          <h1 className="font-bold mb-3 text-[20px]">Account Security</h1>
          {isEditing ? (
            <div className="flex items-center gap-1">
              <button
                className="flex md:text-[16px] px-5 items-center gap-1 border border-slate-300 rounded-lg p-2"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>

              <button
                className="flex  md:text-[16px] px-5 text-white items-center gap-1 border border-slate-300 rounded-lg p-2"
                disabled={isLoading}
                onClick={handleSave}
                style={{ backgroundColor: theme.backgroundColor }}
              >
                {isLoading ? (
                  <h1>Saving...</h1>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          ) : (
            <button
              className="flex md:text-[16px] px-5 items-center gap-1 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(true)}
            >
              <h1 className="hidden md:block">Edit</h1>
              <FiEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5 text-[14px] md:text-[16px]">
          <div className="w-[200px]">
            <h1 className="font-bold">New Password</h1>
            {isEditing ? (
              <div className=" flex items-center">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  name="password"
                  onChange={handleChange}
                  className="border-b w-fit outline-none"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('password')}
                  className="ml-2"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            ) : (
              <h2 className="font-medium text-slate-500">*********</h2>
            )}
          </div>
          <div>
            <h1 className="font-bold">Confirm Password</h1>
            {isEditing ? (
              <div className=" flex items-center">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  onChange={handleChange}
                  value={formData.confirmPassword}
                  className="border-b w-fit outline-none"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility('confirmPassword')}
                  className="ml-2"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            ) : (
              <p className="font-medium text-slate-500">*********</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export { Section1, Section2, ResetPassword };
