import apiClient from "../libs/http-common";

export const fetchStudents = async () => {
  const token = window.localStorage.getItem("token");
  return await apiClient.get("/Lecturers/getStudents", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Lecturers/addGroup", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const fecthStudentsInGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Lecturers/getStudentsInGroup", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const addStudentToGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Lecturers/addStudentToGroup", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
// export const getGroupIdeas = async (data) => {
//   const token = window.localStorage.getItem("token");
//   return await apiClient.post("/Lecturers/GetIdeasByGroupID", data, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });

// };
export const getGroupIdeas = async (data) => {
  const token = window.localStorage.getItem("token");
  try {
    const response = await apiClient.post(
      "/Lecturers/GetIdeasByGroupID",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Make sure to return the actual data
  } catch (error) {
    throw error; // Pass the error up to be handled by onError in useQuery
  }
};
// export const getGroupIdeas = async ({ groupID, learningInstitutionID }) => {
//   try {
//     const response = await apiClient.post('/Lecturers/GetIdeasByGroupID', {
//       params: {
//         groupID,
//         learningInstitutionID,
//       },
//     });
//     return response.data; // Ensure that this is returning a promise
//   } catch (error) {
//     throw error;
//   }
// };
export const getSingleGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Lecturers/getSingleGroup", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getGroups = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.get("/Lecturers/getGroups", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.put("/Lecturers/updateGroup", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteGroup = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/Lecturers/deleteGroup",
    method: "delete",
    data: { id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const removeFromGroup = async (data) => {
  const token = window.localStorage.getItem("token");
  return await apiClient({
    url: "/Lecturers/removeStudentFromGroup",
    method: "delete",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyCourses = async () => {
  const token = window.localStorage.getItem("token");
  return await apiClient.get("/Courses/myCourses", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGroupsByCourseId = async (id) => {
  const token = window.localStorage.getItem("token");
  return await apiClient.post("/Lecturers/getGroupsByCourseId", id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
