import React, { useState } from 'react'
import useAuthStore from '../../store/authStore';
import { useQuery } from 'react-query';
import { fetchPaymentHistory } from '../../utils/fetchPaymentHistory';
import moment from 'moment';

function PaymentsAndPlans({ setSettingsTab }) {
  const [paymentHistory, setPaymentHistory] = useState([])
  const { removeUser } = useAuthStore()
  const { isError, isLoading: loadingCategories, refetch } = useQuery(
    "fetchPaymentHistory",
    fetchPaymentHistory,
    {
      enabled: true,
      onSuccess: ({ data }) => {
        setPaymentHistory(data.data);
      },
      onError: ({ response }) => {
        console.log(`${response?.data?.status}`);
        if (response?.data?.status === 401) {
          removeUser();
          window.location.href = "/login"
        }
      },
    }
  );
  return (
    <div className='w-full'>
      <p className='text-[1.4rem] uppercase mb-2'>Plan Details</p>
      <div className='border rounded-md w-full p-8'>
        <div>
          <p className='text-[1.4rem] font-semibold'>Plan</p>
          <p className='text-[1.6rem] mt-2'>{paymentHistory[paymentHistory?.length - 1]?.naration}</p>
          <p className='text-[1.6rem] mt-2 font-thin'>{paymentHistory[paymentHistory?.length - 1]?.amount} GBP/Month</p>
        </div>
        <div className='mt-8'>
          <p className='text-[1.4rem] font-semibold'>Next Billing Date</p>
          <p className='text-[1.6rem] mt-2'>{moment(paymentHistory[paymentHistory?.length - 1]?.dateAdded).add(30, 'days').format('D MMM YYYY')}</p>
        </div>
      </div>
      <div className='mt-8 flex items-center gap-4'>
        <button onClick={() => setSettingsTab("Payment History")} className='p-2 border border-black rounded-md text-[1.4rem]'>View Payment History</button>
        <button className='p-2 bg-red-500 text-white rounded-md text-[1.4rem]'>Cancel Plan</button>
        <button onClick={() => setSettingsTab("Subscription Plans")} className='p-2 bg-[#008FD2] text-white rounded-md text-[1.4rem]'>Upgrade Plan</button>
      </div>
    </div>
  )
}

export default PaymentsAndPlans