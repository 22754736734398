function About() {
  return (
    <section className="relative top-[11.6rem]" id="about">
      <div className="app-container">
        <div className="flex flex-col items-center justify-center text-center mb-[4.8rem]">
          <div>
            <p className="bg-[#EDF4FC] text-[#008FD2] px-[1.2rem] py-[0.4rem] rounded-[1.5rem] font-bold text-center text-[1.8rem] mx-auto w-max mb-[3.6rem]">
              Our Goal is SIMPLE
            </p>
            <h3 className="text-[#103240] text-[4.7rem] md:text-[9.6rem] max-w-[102rem] font-bold leading-[7rem] md:leading-[10.6rem] mb-[2.4rem]">
              Helping Idealist Create More Ideas
            </h3>
            <p className="text-[#103240] font-medium text-[2rem] max-w-[76.8rem] mx-auto mb-[6.4rem] leading-[3rem]">
              Spend less time figuring out how things work. <br /> Hydreate’s
              powerful features and intuitive design allow for idea management
              harmony.
            </p>
          </div>
          <div className=" grid grid-cols-2 lg:flex justify-between w-full md:pl-[5rem]">
            <div className="flex flex-col gap-[2.4rem] justify-center items-center">
              <picture>
                <img src="/assets/moon.svg" alt="" />
              </picture>
              <div>
                <h3 className="text-[#103240] text-[3rem] lg:text-[5rem]  font-bold">
                  Ideation
                </h3>
                <p className="text-[#103240] text-[2rem] leading-[3rem]">
                  for any case
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[2.4rem] justify-center items-center">
              <picture>
                <img src="/assets/launch3.svg" alt="" />
              </picture>
              <div>
                <h3 className="text-[#103240] text-[3rem] lg:text-[5rem]  font-bold">
                  Creation
                </h3>
                <p className="text-[#103240] text-[2rem] leading-[3rem]">
                  for any content
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[2.4rem] justify-center items-center">
              <picture>
                <img src="/assets/Random5.svg" alt="" />
              </picture>
              <div>
                <h3 className="text-[#103240] text-[3rem] lg:text-[5rem]  font-bold">
                  Improvement
                </h3>
                <p className="text-[#103240] text-[2rem] leading-[3rem]">
                  for existing content
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-container flex gap-[2rem] md:gap-[3.2rem] overflow-auto md:overflow-hidden">
        <h2 className="uppercase text-stroke">grow</h2>
        <h2 className="uppercase text-stroke">your</h2>
        <h2 className="uppercase text-stroke">idea</h2>
        <h2 className="uppercase text-stroke">grow</h2>
      </div>
    </section>
  );
}
export default About;
