import React from "react";
import CategoriesHome from "../../Components/AdminDashboard/categories/home";
function ManageCategories() {
  return (
    <div className="relative top-[8%] px-8 lg:px-16 py-8 bg-[#f1f1f1]">
      <CategoriesHome />
    </div>
  );
}

export default ManageCategories;
