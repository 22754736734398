function SponsorsBanner() {
  return (
    <section className="app-container bg-[#EDF4FC] py-[1rem] px-[1.9rem] md:px-[2rem] lg:px-[2rem] !mt-[2rem] lg:mt-0">
      <div className="flex gap-[1rem] md:gap-[1.2rem] items-center">
        <picture>
          <img
            src="/assets/p1.png"
            alt=""
            height={46}
            className=""
          />
        </picture>
        <picture>
          <img
            src="/assets/p2.png"
            alt=""
            height={46}
            className="scale-[0.8] w-[43.7rem]"
          />
        </picture>
        <picture>
          <img
            src="/assets/p3a.png"
            alt=""
            height={46}
            className="h-[4.6rem] object-contain"
          />
        </picture>
        <picture>
          <img
            src="/assets/p4.png"
            alt=""
            height={46}
            className="h-[4.6rem] md:h-[14.2rem] w-[20rem] object-contain"
          />
        </picture>
        <picture>
          <img
            src="/assets/p5.png"
            alt=""
            height={46}
            className="h-[14.6rem] object-contain"
          />
        </picture>
        <picture>
          <img
            src="/assets/p6a.png"
            alt=""
            height={46}
            className="h-[4.6rem] object-contain"
          />
        </picture>
        <picture>
          <img
            src="/assets/p7a.png"
            alt=""
            height={46}
            className="h-[8.6rem] object-contain"
          />
        </picture>
      </div>
    </section>
  );
}

export default SponsorsBanner;
