import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { GrClose } from "react-icons/gr";

function SearhBar({ setOpenSearchBar }) {
  return (
    <div className="relative">
      <input
        type="text"
        className="border w-[36rem] py-2 rounded-xl relative pl-[2.5rem] outline-none pr-2"
      />
      <AiOutlineSearch
        size={14}
        className="absolute top-[0.6rem] left-[1rem]"
      />
      <GrClose
        size={10}
        className="absolute top-[0.8rem] right-[1rem] cursor-pointer"
        onClick={() => setOpenSearchBar(false)}
      />
    </div>
  );
}

export default SearhBar;
